import styled from 'styled-components';

interface Props {
  sloganTextColor: string;
  langMenuTextColor: string;
  burgerMenuColor: string;
  backgroundColor: string;
}

export const HeaderStyles = styled.header<Props>`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  
      .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    
      .menuMobileButton {
        position: fixed;
        right: 10px;
        bottom: 20px;
        z-index: 99999;
        display: none;
        justify-content: center;
        align-items: center;
        background: none;
        border: none;
        padding: 0;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background: #EE2752;
        
        &.active {
          div {
            height: 0;

            &::before {
              position: absolute;
              content: '';
              width: 20px;
              height: 2px;
              background: #FFF;
              top: -8px;
              left: 37%;
              transform: rotate(-45deg) translateX(-50%);
            }

            &::after {
              position: absolute;
              content: '';
              width: 20px;
              height: 2px;
              background: #FFF;
              bottom: -8px;
              left: 37%;
              transform: rotate(45deg) translateX(-50%);
            }
          }
        }
        
        div {
          position: relative;
          width: 20px;
          height: 2px;
          background: #FFF;
          
          &::before {
            position: absolute;
            content: '';
            width: 20px;
            height: 2px;
            background: #FFF;
            top: -5px;
            left: 50%;
            transform: translateX(-50%);
            transition: 0.3s ease all;
          }          
          
          &::after {
            position: absolute;
            content: '';
            width: 20px;
            height: 2px;
            background: #FFF;
            bottom: -5px;
            left: 50%;
            transform: translateX(-50%);
            transition: 0.3s ease all;
          }
        }
        
        @media (max-width: 768px) {
          display: flex;
        }
      }
    
      .headerContent {
        position: relative;
        z-index: 999;
        
        margin: auto;
  
        padding: 18px 0 20px;
        box-sizing: border-box;
        background: ${(props) => props.backgroundColor || '#FFF'};
        transition: 0.6s ease all;
  
        .logoContainer {
          display: grid;
          align-items: center;
          grid-template-columns: auto auto;
          
          @media (max-width: 768px) {
            svg {
              width: 88px;
            }
          }
          
          span {
            @media (max-width: 768px) {
              display: none;
            }
          }
        }
        
        .sloganMobile, .sloganDesktop {
          transform: translateY(2px);
  
          font-family: 'Montserrat';
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          background: ${(props) => props.sloganTextColor || '#000000'};
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-fill-color: transparent;
  
          @media (max-width: 768px) {
            transform: translateY(3px);
          }
        }
        
        .sloganMobile {
          display: none;
          transform: translateY(1px);
          
          @media (max-width: 768px) {
            display: block;
            
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            z-index: 100;
          }
        }
        
        .buttonsWrapper {
          display: flex;
          align-items: center;
          
          .menuMainButton {
            display: flex;
            align-items: center;
            gap: 12px;
            width: auto;

            .iconContainer {
              display: flex;
              align-items: center;
              justify-content-center;
              width: 21px;
              height: 21px;
            }

            img {
              object-fit: cover;
            }
            
            &.active {
              background: linear-gradient(211.88deg, #ED1C2A -11.36%, #EE2752 46.79%, #444A49 101.73%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }
          }
          
          .langMenuContainer {
            display: flex;
            position: relative;
            margin-right: 65px;
            
            > button {
              margin-left: 16px;
            }
            
            @media (max-width: 768px) {
              display: none;
              
              margin-right: 0;
              margin-left: 20px;
  
              > button {
                margin-left: 0;
              }
            }
            
            button {
              padding: 0;
              color: ${(props) => props.langMenuTextColor || '#000000'};
              background: none;
              border: none;
              font-family: 'Montserrat';
              font-weight: 400;
              font-size: 23px;
              line-height: 28px;
              cursor: pointer;
              
              @media (max-width: 768px) {
                color: #EE2752;
              }
            }
            
  
            .langMenu {
              display: none;
              
              &.langMenuOpen {
                display: flex;
              }
              
              gap: 8px;
  
              animation: 1s opacityFrom0To1;
              
              li {
                list-style: none;
              }
            }
          }
    
          .menu {
            height: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            transition: 0.3s all;
            
            cursor: pointer;
  
            .menuStrip:nth-child(2) {
              transform: translateX(-8px);
            }
            
            &:hover{
              .menuStrip:nth-child(2) {
                transform: translateX(0);
              }
            }
            
            @media (max-width: 768px) {
              display: none;
            }
            
            &.open {
              transform: translateY(2px);
              
              .menuStrip:nth-child(1) {
                transform: rotate(40deg) translateY(11px) translateX(-2px);
              }
              
              .menuStrip:nth-child(2) {
                display: none;
              }
  
              .menuStrip:nth-child(3) {
                transform: rotate(-40deg) translateY(-13px);
              }
            }
            
            .menuStrip {
              height: 3px;
              width: 32px;
              
              border-radius: 5px;
              background: ${(props) => props.burgerMenuColor || '#000000'};
              transition: 0.3s all;
            }
          }
        }
      }
  
    @keyframes opacityFrom0To1 {
      from {
        opacity: 0;
      }
  
      to {
        opacity: 1;
      }
    }
    `;
