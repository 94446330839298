import React from 'react';
import { BannerStyle } from './BannerStyle';
import ButtonWithArrowOnHover from '../buttonWithArrowOnHover/ButtonWithArrowOnHover';
import getTranslationsByLangOrEng from '../../utils/getTranslationsByLangOrLang';
import { useAppSelector } from '../../state/hooks';

interface Props {
  title: string;
  bgColor: string;
  description: string;
  buttonKey?: string;
  image?: string;
}

function Banner({
  title,
  description,
  bgColor,
  buttonKey = 'speak_to_an_expert',
  image,
}: Props) {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <BannerStyle className="banner" bgColor={bgColor} image={image}>
      <div className="container">
        <h1>{title}</h1>
      </div>

      <div className="content">
        <p className="bannerDescription">{description}</p>

        <ButtonWithArrowOnHover
          onClick={() => {
            const elem = document.getElementById('form');
            elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }}
          arrowColor="#000"
          textColor="#FFF"
          borderColor="#FFF"
          borderOnHover="#FFF"
          background="none"
          hoverBackground="#FFF"
          hoverTextColor="#000"
          text={getTranslationsByLangOrEng(interfaceLanguage, buttonKey)}
        />
      </div>
    </BannerStyle>
  );
}

export default Banner;
