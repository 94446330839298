import {
  ReactNode, useCallback, useRef, useState,
} from 'react';
import { SwiperCenteredSlidesPerViewAutoStyles } from './SwiperCenteredSlidesPerViewAutoStyles';
import NavigationButtons from '../navigationButtons/NavigationButtons';
import 'swiper/css';
import { Swiper } from 'swiper/react';
import { Link } from 'react-router-dom';
import { FreeMode, Pagination, SwiperOptions } from 'swiper';
import ArrowRight from '../../assets/icons/ArrowRight';
import ButtonWithArrowOnHover from '../buttonWithArrowOnHover/ButtonWithArrowOnHover';
import { useAppSelector } from '../../state/hooks';
import getTranslationsByLangOrEng from '../../utils/getTranslationsByLangOrLang';

interface Props {
  children: any;
  centeredSlides?: boolean;
  buttonsIconsColor?: string;
  loop?: boolean;
  disabledPrev?: boolean;
  disabledNext?: boolean;
  disabledPrevIfSlideActive?: number;
  disabledNextIfSlidesLeftToEnd?: number;
  link?: string;
  linkButtonText?: string;
  freeMode?: boolean;
  offsetRight?: number;
  offsetLeft?: number;
  initialSlide?: number;
  buttonBorderColor?: string;
  customButton?: (
    onClickButton: () => void,
    disabled: boolean | undefined
  ) => ReactNode;
  breakPoints?:
    | { [p: number]: SwiperOptions; [p: string]: SwiperOptions }
    | undefined;
  formBtn?: boolean
  btnKey?: string
  btnColor?: string
}

function SwiperCenteredSlidesPerViewAuto({
  buttonBorderColor,
  initialSlide = 0,
  offsetLeft,
  children,
  buttonsIconsColor,
  loop = true,
  disabledPrev = false,
  disabledNext = false,
  disabledPrevIfSlideActive = 0,
  disabledNextIfSlidesLeftToEnd = 1,
  linkButtonText,
  link,
  centeredSlides = true,
  freeMode = false,
  offsetRight = 0,
  customButton,
  breakPoints,
  formBtn,
  btnKey,
  btnColor,
}: Props) {
  const sliderRef = useRef(null);

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const [slidesCount, setSlidesCount] = useState<number>(0);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    // @ts-ignore
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    // @ts-ignore
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <SwiperCenteredSlidesPerViewAutoStyles className="sliderWrapper">
      <Swiper
        slidesPerView="auto"
        initialSlide={initialSlide}
        centeredSlides={centeredSlides}
        spaceBetween={15}
        pagination={{
          clickable: true,
        }}
        loop={loop}
        loopFillGroupWithBlank
        className="mySwiper"
        // @ts-ignore
        ref={sliderRef}
        breakpoints={
          breakPoints || {
            450: {
              spaceBetween: 40,
            },
          }
        }
        modules={[FreeMode, Pagination]}
        freeMode={freeMode}
        // @ts-ignore
        onActiveIndexChange={(slider) => {
          setActiveSlideIndex(slider.activeIndex);
        }}
        onInit={(swiper) => {
          setSlidesCount(swiper.slides.length);
        }}
        slidesOffsetAfter={offsetRight}
        slidesOffsetBefore={offsetLeft}
        allowSlideNext={
          !(slidesCount - disabledNextIfSlidesLeftToEnd === activeSlideIndex)
        }
        allowSlidePrev={
          !(disabledPrev && activeSlideIndex === disabledPrevIfSlideActive)
        }
      >
        {children}
      </Swiper>
      <div
        className={
          link || formBtn
            ? 'sliderNavContainer withLink'
            : 'sliderNavContainer'
        }
      >
        {(formBtn && btnKey && btnColor) && (
          <ButtonWithArrowOnHover
            onClick={() => {
              const elem = document.getElementById('form');
              elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }}
            arrowColor="#000"
            textColor="#000"
            borderColor="#000"
            borderOnHover="#000"
            background={btnColor}
            hoverBackground="#fff"
            hoverTextColor="#000"
            text={getTranslationsByLangOrEng(
              interfaceLanguage,
              btnKey,
            )}
          />
        )}
        <NavigationButtons
          onClickToNextButton={handleNext}
          onClickToPrevButton={handlePrev}
          buttonsIconsColor={buttonsIconsColor}
          disabledNext={
            disabledNext
            && slidesCount - disabledNextIfSlidesLeftToEnd === activeSlideIndex
          }
          disabledPrev={
            disabledPrev && activeSlideIndex === disabledPrevIfSlideActive
          }
          borderColor={buttonBorderColor}
          customButton={customButton}
        />

        {link && (
          <Link to={link} className="linkButton">
            <span className="text16">{linkButtonText}</span>
            <ArrowRight />
          </Link>
        )}
      </div>
    </SwiperCenteredSlidesPerViewAutoStyles>
  );
}

export default SwiperCenteredSlidesPerViewAuto;
