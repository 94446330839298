export const fr: { [key: string]: string } = {
  subscribe_with_click: 'Abonnez-vous en un clic',
  minimum_number_characters: 'nombre minimum de 5 caractères',
  request_details: 'Détails de la demande',
  menu_21: 'Analyse des prix automobiles',
  form_title: 'Nous vous contacterons sous peu',
  form_required: "Les champs marqués d'une * sont obligatoires",
  form_name_placeholder: 'Nom',
  form_email_placeholder: 'E-mail',
  form_phone_placeholder: 'Numéro de téléphone',
  form_company_placeholder: 'Entreprise',
  form_submit: 'Soumettre',
  form_1:
    'Je donne mon acceptation au traitement des données personnelles conformément à la',
  form_2: 'Politique de Confidentialité',
  validation_privacy_policy:
    'Veuillez accepter la Politique de Confidentialité',
  validation_phone: "Le numéro de téléphone n'est pas valide",
  validation_email: "Format d'email invalide",
  validation_required: 'Les champs obligatoires ne sont pas remplis',
  submit_error:
    "Une erreur s'est produite lors de l'enregistrement du formulaire",
  footer_1: 'Nos partenaires:',
  footer_2: 'Méthodes',
  footer_3: 'Produits',
  footer_4: 'Contacts:',
  footer_5: 'Imprimer',
  footer_6: 'Politique de Confidentialité',
  about_us_1: "4Service Responsabilité Sociale d'Entreprise",
  about_us_2:
    "Du cœur et de l'âme de chaque membre de notre équipe. Nous faisons de notre mieux pour garder une étincelle de bienveillance chez chacun de nos employés et rendre le monde qui nous entoure un peu meilleur",
  about_us_3: "4Service Responsabilité Sociale d'Entreprise",
  about_us_4:
    "Du cœur et de l'âme de chaque membre de notre équipe. Nous faisons de notre mieux pour garder une étincelle de bienveillance chez chacun de nos employés et rendre le monde qui nous entoure un peu meilleur",
  about_us_5: "Jeu d'entreprise Service Mania",
  about_us_6:
    'Nos initiatives de sensibilisation pour les enfants et les étudiants',
  about_us_7: 'Charité / Bénévolat',
  about_us_8:
    "Orphelinats, maisons de retraite pour personnes âgées et volontariat pour l'armée ukrainienne. La partie la plus émouvante de nos activités",
  about_us_9: 'Bien-être animal',
  about_us_10:
    "N'importe qui peut entrer dans notre bureau avec un animal de compagnie. Nous donnons un coup de main aux refuges, collectons des fonds pour la nourriture et essayons parfois aussi de trouver un foyer pour les animaux sans abri.",
  about_us_11: '4Service Groupe',
  about_us_12:
    "Est une société de recherche internationale qui gère l'expérience client.",
  about_us_13:
    'Nous mettons en œuvre des projets complexes pour augmenter les ventes et développer les affaires des entreprises orientées client.',
  about_us_14:
    "Est un holding de recherche international dont le siège est à Vienne, en Autriche. La société a été créée en 2001 dans le but d'améliorer la qualité de vie de ses clients et les personnes qui interagissent avec le commerce, utilisent ses services et consomment ses produits. Après tout, c'est pour une raison que le nom «Forservice » a été choisi pour une entreprise qui représente le service client et la bonne qualité.",
  about_us_15:
    "4Service a été fondé à Kiev, en Ukraine, et devenue l'un des principaux fournisseurs de services d'achat mystère. Aujourd'hui, l'entreprise opère avec succès dans 52 pays en tant que société de recherche avec de nouvelles capacités à une approche holistique pour analyser et améliorer toutes les étapes de l'expérience client.",
  about_us_16: 'né au nom du service',
  about_us_17: "premier bureau situé hors d'Ukraine",
  about_us_18: 'déménagement du siège à Vienne',
  about_us_19: 'membre élite de la MSPA, Top 10 des agences en Europe',
  about_us_20: 'création de bureaux dans 9 pays',
  about_us_21: 'premiers projets numériques, Voicer',
  about_us_22: 'partenaires de DesignThinkers Academy',
  about_us_23: 'premiers projets CХ mondiaux',
  about_us_24: 'Mission',
  about_us_25:
    "Notre mission est d'améliorer le service client partout dans le monde ! Notre inspiration réside dans les changements qualitatifs que nous constatons chez les personnes et les entreprises avec lesquelles nous travaillons.",
  about_us_26: "Plus de 20 ans d'expérience client",
  about_us_27: '95 pays',
  about_us_28:
    "Nous accumulons et partageons la meilleure expérience de l'industrie du monde entier",
  about_us_29: 'Siège social à Vienne',
  about_us_30:
    "des pays. Nous rassemblons et partageons la meilleure expérience de l'industrie du monde entier",
  about_us_31: 'notes de qualité de service',
  about_us_32: 'nous sommes dans le top 10 des agences CX Européennes',
  about_us_33: 'bureaux de représentation / siège social en Autriche',
  about_us_34: 'projets actifs',
  about_us_35: 'Votre futur lieu de travail',
  about_us_36: 'Nos bureaux',
  about_us_37:
    "4Service™ s'efforce de créer un espace de travail confortable pour ses employés. La liberté de choix de travailler sur site, à distance ou hybride favorise le meilleur confort et la meilleure productivité de chaque employé. Des salles de repos, un soutien psychologique gratuit et un environnement accueillant. Nous acceptons les humains et les animaux domestiques.",
  about_us_38: "L'équipe qui s'illumine",
  about_us_39:
    "Notre équipe multinationale s'engage à écouter compte tenu des commentaires de nos clients et de la croissance mutuelle. La carte du parcours client de nos clients avec nous est aussi importante pour notre entreprise que le lancement de nouveaux produits ou l'atteinte de nos objectifs financiers. Nos activités visent à avoir un impact positif sur les communautés voisines et à fournir à nos clients des solutions innovantes et centrées sur les personnes.",
  about_us_40: 'Tous',
  about_us_41: 'Gestion',
  about_us_42: 'Équipe mondiale',
  about_us_43: 'Propriétaires de produits',
  about_us_44: 'Nos partenaires',
  about_us_45:
    'Nous mettons en œuvre des projets complexes pour augmenter les ventes et développer les affaires des entreprises orientées client',
  about_us_46:
    "est l'un des principaux instituts de formation professionnelle «axés sur le design», représenté dans 25 pays et forme, développe et facilite des équipes et des communautés multidisciplinaires créatives pour conduire un changement positif. Le Design Thinking vous permet de développer et de proposer des idées innovantes, des changements et des solutions à des problèmes complexes.",
  about_us_47:
    "Leur mission est de fournir de l'espace et du temps aux équipes et aux individus pour qu'ils soient inspirés et mis au défi, pour « apprendre par la pratique » et renforcer leur capacité à résoudre les problèmes de manière créative et aider les organisations à créer l'environnement et l'état d'esprit nécessaires pour lancer une innovation audacieuse.",
  about_us_48:
    "est l'association commerciale représentative des entreprises impliquées dans l'industrie des Achats Mystères. L'Association opère sur une base régionale dans le monde entier, avec des sections situées en Amérique, Europe/Afrique et Asie/Pacifique. Les entreprises membres s'unissent en un corps commun dans le but de renforcer l'industrie d'Achats Mystères grâce à des efforts et des actions combinés.",
  about_us_49:
    "est une association à but non lucratif. Les membres forment une communauté convaincue du potentiel de l'analyse des données, de la recherche et des idées pour aider à améliorer les sociétés, les organisations et la vie de chacun.",
  about_us_50:
    'ESOMAR a son siège social à Amsterdam, aux Pays-Bas. ESOMAR est présent dans plus de 130 pays à travers ses membres depuis 1947.',
  about_us_51:
    "(UCCA) est une communauté professionnelle d'experts privés et d'entreprises réunies dans un objectif de développement et d'interactions dans le domaine du centre de contact et du service client. La philosophie de l'UCCA élève la culture de l'industrie des centres de contact, basée sur des technologies modernes et des normes élevées.",
  about_us_52:
    "L'Association Ukrainienne des centres de contact a été fondée en 2010",
  about_us_53:
    "étudie la meilleure expérience mondiale dans le domaine de l'éducation pour l'adapter et l'utiliser sur le marché Ukrainien. C'est pourquoi l'Académie DTEK coopère avec les principales écoles de commerce internationales : INSEAD, IE Business School et l'Université de Stanford.",
  about_us_54:
    "Academy DTEK se concentre sur le développement d'une culture innovante et orientée client dans D.Client School, ID School, Executive Education: Energy of Innovation, Power of Communications, DX School digital program, Effective Leader: Pre-MBA, Change Energy.",
  about_us_55: 'Sergey Pashkovsky',
  about_us_56: 'Viktoria Skorbota',
  about_us_57: 'Artem Fomin',
  about_us_58: 'Max Papka',
  about_us_59: 'Manager des ventes internationales',
  bht_1: 'Santé de la marque',
  bht_2: 'Suivi',
  bht_3: 'Les marques sont incroyablement puissantes',
  bht_4: 'Découvrez les performances globales de votre marque',
  bht_5: 'À propos',
  bht_6: "Qu'est-ce que le suivi de la santé de la marque?",
  bht_7:
    "Il s'agit d'un type d'étude de marché qui est menée avec une certaine régularité (toutes les deux semaines, tous les mois, etc.) afin d'obtenir des informations sur les",
  bht_8:
    "statuts d'une marque sur le marché en fonction d'indicateurs tels que la notoriété, la demande, l'image, les caractéristiques de ses consommateurs, la santé de la marque, etc.",
  bht_9: 'Acheter la marque le plus souvent',
  bht_10: 'Loyal',
  bht_11: 'Acheté la marque pendant 3 mois',
  bht_12: 'Répertoire / clients réguliers',
  bht_13: 'Acheté la marque pendant 6 mois',
  bht_14: 'Clients à court terme',
  bht_15: 'Acheté la marque pendant un an',
  bht_16: 'Clients à long terme',
  bht_17: "Connaissez-vous la marque ? mais je ne l'ai jamais utilisé/acheté",
  bht_18: 'Au courant mais pas clients',
  bht_19: "Ignorant : je n'ai jamais entendu parler de la marque",
  bht_20: 'Ignorant',
  bht_21: 'Profil des acheteurs de la marque',
  bht_22:
    'Évaluer la cohérence des efforts déployés sur le long terme pour construire une marque',
  bht_23: 'Mesurer le niveau de connaissance des clients sur la marque',
  bht_24: "Démontrer l'efficacité des campagnes de publicité et d'information",
  bht_25:
    "Déterminer l'impact d'une période de forte publicité sur les activités de vente/marketing des concurrents",
  bht_26:
    "Fournir un contrôle constant au propriétaire de l'entreprise dans les périodes de changements continus du marché",
  bht_27: 'Quelles tâches peuvent être résolues par BHT:',
  bht_28: 'Demander une recherche',
  bht_29: 'Tâche à résoudre',
  bht_30: 'Par exemple,',
  bht_31:
    "si une entreprise n'a besoin que d'une séance de BHT par trimestre lors de la mesure des parts de marché, une enquête auprès des répondants devrait être menée plus souvent lors de la mesure de la connaissance de la marque client par la publicité.",
  bht_32: 'CATI',
  bht_33: 'Face à face',
  bht_53: 'Les entretiens',
  bht_34: 'СAWI',
  bht_35: 'Tâche à résoudre',
  bht_36: 'En recueillant des informations',
  bht_37:
    "à travers diverses méthodes, le suivi de la marque est généralement divisé en trois étapes selon le public cible et sa difficulté à l'atteindre :",
  bht_38:
    'Nous utilisons à la fois des méthodes traditionnelles et contemporaines pour collecter des données :',
  bht_39:
    "Nous travaillons à l'aide de deux modèles, l'un avec un ensemble d'indicateurs simple et l'autre ciblant des industries plus complexes telles que les industries alimentaires et de services. Un modèle plus complexe implique une étude approfondie qui comprend jusqu'à 10 indices estimés.",
  bht_40: 'Nous offrons',
  bht_41: 'un contrôle des données initiales',
  bht_42: 'Nous couvrons',
  bht_43: "l'ensemble du pays",
  bht_44: 'Nous fournissons une solution',
  bht_45:
    "pour les marchés non dynamiques/marchés avec un nombre limité d'acteurs (Brand Point Analyzer)",
  bht_46: 'Pourquoi nous?',
  bht_47: 'Pourquoi nous choisir?',
  bht_50: 'Résultat médiocre',
  bht_51: 'Bon résultat',
  bht_52: 'Résultat moyen',
  cases_1:
    "Défi : Comment améliorer l'expérience client d'achat d'un véhicule électrique dans les showrooms Hyundai ?",
  cases_2: 'Cartographie du parcours client',
  cases_3: 'Idéation',
  cases_4: 'Recherche qualitative',
  cases_5:
    "Défi : Que faut-il changer dans les magasins (à la fois dans le processus de service, dans l'intérieur, dans la perception de la marque) afin d'intéresser les clients à la marque Oppo?",
  cases_6: 'CJM en ligne',
  cases_7: "Test de l'interface utilisateur",
  cases_8: 'Les entretiens',
  cases_9:
    'Défi : Que faut-il changer dans les magasins pour intéresser les clients à la marque Oppo?',
  cases_10: 'Partie qualitative',
  cases_11: 'Idéation',
  cases_12: 'Partie quantitative',
  cases_13: 'Carte du parcours client',
  cases_14: 'Cas',
  cases_15: 'Tous',
  cases_16: 'Réalisons ensemble votre superbe dossier',
  csi_1: 'Client',
  csi_2: 'indice de satisfaction',
  csi_3: 'Déterminer les raisons pour lesquelles les consommateurs',
  csi_4: 'satisfaction et insatisfaction',
  csi_5: 'Recherche sur la satisfaction client',
  csi_6:
    "est la détermination des facteurs, causes et motivations qui influencent la décision d'achat d'un client ainsi que le comportement du public cible.",
  csi_7: 'Une enquête de satisfaction client',
  csi_8:
    "montre à quel point un client est satisfait ou insatisfait d'un aspect particulier du service ou du produit en général.",
  csi_9: "L'enquête de satisfaction",
  csi_10:
    "est menée auprès des clients existants d'une entreprise et des utilisateurs de certains produits/services.",
  csi_11: "Qu'est-ce que le SCI?",
  csi_12:
    "La satisfaction client mesure dans quelle mesure les attentes d'un client ont été satisfaites concernant l'achat d'un produit ou d'un service fourni par votre entreprise.",
  csi_13: 'Homme heureux',
  csi_14: 'À propos',
  csi_15: 'Identifier les facteurs cachés',
  csi_16: 'qui peuvent affecter la satisfaction du client',
  csi_17: 'Déterminer les raisons',
  csi_18: 'derrière toute hausse/baisse de la demande de biens et services',
  csi_19: 'Optimiser une entreprise',
  csi_20: "processus d'affaires",
  csi_21: 'Les adapter',
  csi_22: 'aux besoins du consommateur moderne',
  csi_23: 'Former un message publicitaire efficace',
  csi_24: 'et positionner un produit sous la demande du client',
  csi_25: 'Tâches à résoudre',
  csi_26: 'Quelles tâches les enquêtes de satisfaction client peuvent résoudre',
  csi_27: 'Homme avec téléphone',
  csi_28: 'Nous construisons un modèle mathématique',
  csi_29:
    'des facteurs pouvant influencer la satisfaction client/fidélisation client (analyse de régression)',
  csi_30: 'Optimiser un',
  csi_31: "les processus d'affaires de l'entreprise",
  csi_32: 'Nous construisons un indice de satisfaction client calculé',
  csi_33: '(SCI) et comparez-le avec un déclaratif',
  csi_34: 'Nous effectuons des recherches',
  csi_35: 'pour les segments B2B et B2C.',
  csi_36: 'Nos questionnaires sont élaborés par une équipe',
  csi_37:
    "d'analystes spécialement pour les projets individuels de nos clients",
  csi_38: 'Pourquoi nous',
  csi_39: 'Pourquoi nous choisir?',
  csi_40: 'Methods of collecting information:',
  main_1:
    'améliorer leur performance financière en apprenant des expériences de leurs clients',
  main_2: 'Conseils et services СX une vision à 360° de votre entreprise',
  main_3:
    "Découvrez notre gamme de solutions et d'outils que nous utilisons pour résoudre vos défis d'affaires",
  main_4: 'Des produits',
  main_5:
    'La recherche qualitative consiste à collecter et à analyser des données non numériques pour comprendre des concepts, des opinions ou des expériences',
  main_6:
    "Les méthodes de recherche quantitative sont une mesure objective et une analyse numérique des données recueillies par le biais de sondages, de questionnaires et d'enquêtes",
  main_7:
    "Le client mystère est une technique d'étude de marché permettant de mesurer et de surveiller les interactions entre un client et une entreprise ou une organisation au cours d'un scénario prédéfini.",
  main_8:
    'Système de gestion des commentaires. Voix de votre client dans un système Système de gestion des commentaires des clients',
  main_9:
    "Une plate-forme d'intelligence de contenu alimentée par l'AI fournissant des informations sur l'audience",
  main_10:
    'Logiciel de gestion des performances. Application gamifiée pour augmenter les ventes',
  main_11:
    "La surveillance des prix est le processus de collecte, de traitement et d'analyse des prix des concurrents",
  main_12:
    'La conception de services consiste à comprendre les besoins et les désirs des personnes qui utiliseront un produit ou un service en passant du temps avec ',
  main_13:
    'Notre équipe analytique applique les approches les plus récentes et les plus fondamentales qui ont fait leurs preuves auprès de nombreux clients',
  main_14: 'Méthodologies',
  main_15: 'Carte du parcours client',
  main_16:
    'La Cartographie du Parcours Client (CPC) consiste à définir un public cible, à comprendre ses besoins et son environnement, puis à créer un plan pour offrir une expérience attrayante',
  main_17: 'CPC',
  main_18: 'Score de Recommandation Net',
  main_19:
    "Score de Recommandation Net (SRN) est l'étalon-or des métriques d'expérience client, basé sur une question : Quelle est la probabilité que vous recommandiez l'organisation/le produit/le service?",
  main_20: 'SRN',
  main_21: 'Suivi de la Santé de la Marque',
  main_22:
    "La recherche sur le Suivi de la Santé de la Marque (SSM) peut mesurer les performances de votre marque en termes de notoriété et d'utilisation, de positionnement et de performance de la marque",
  main_23: 'SSM',
  main_24: 'Recherche sur les ressources humaines',
  main_25:
    'La recherche sur les ressources humaines est utilisée pour évaluer les pratiques et les performances des RH et offre une analyse détaillée du développement et de la gestion actuels',
  main_26: 'Recherche RH',
  main_27: 'Indice de satisfaction de la clientèle',
  main_28:
    'La recherche sur les ressources humaines est utilisée pour évaluer les pratiques et les performances des RH et offre une analyse détaillée du développement et de la gestion actuels',
  main_29: 'ISC',
  main_30: 'Tests UX',
  main_31:
    "Les tests d'utilisabilité (UX) sont la façon dont de vraies personnes interagissent avec un site Web, une application ou un autre produit et observent leur comportement et leurs réactions",
  main_32: 'Pourquoi nous',
  main_33:
    "Notre mission est d'aider les entreprises à offrir une expérience client hautement efficace",
  main_34: 'Priorité à la qualité',
  main_35: 'Nous contrôlons la qualité',
  main_36:
    "lorsqu'il s'agit de données de recherche. Nous mettons tous les ensembles de données à la disposition des clients",
  main_37: 'Rentable',
  main_38: 'Notre expérience, nos ressources et notre rationalisation',
  main_39:
    "les processus commerciaux nous permettent de vous faire gagner du temps et de l'argent",
  main_40: 'Solutions intelligentes',
  main_41: 'Nous utilisons diverses techniques',
  main_42: 'et des boîtes à outils pour résoudre vos problèmes particuliers',
  main_43: 'Priorité à la qualité',
  main_44: 'Nous contrôlons la qualité',
  main_45:
    "lorsqu'il s'agit de données de recherche. Nous mettons tous les ensembles de données à la disposition des clients",
  main_46: 'Rentable',
  main_47: 'Notre expérience, nos ressources et notre rationalisation',
  main_48:
    "les processus commerciaux nous permettent de vous faire gagner du temps et de l'argent",
  main_49: 'Solutions intelligentes',
  main_50: 'Nous utilisons diverses techniques',
  main_51: 'et des boîtes à outils pour résoudre vos problèmes particuliers',
  main_52: 'Payer et emporter',
  main_53: 'Électronique',
  main_54: 'Luxe/Premium',
  main_55: 'Restauration & Hospitalité',
  main_56: 'Vente au détail',
  main_57: 'Automobile',
  main_58: 'Mode',
  main_59: 'Banques',
  main_60: 'Voyage & Loisirs',
  main_61: 'Montres & Bijoux',
  main_62: 'Appareils ménagers',
  main_63: 'Blog',
  main_64: "Allez sur le blog pour lire d'autres articles intéressants",
  main_65: 'Audit de vente au détail',
  main_66:
    'Collectez des données détaillées sur votre magasin ou votre marque, analysez les obstacles à la croissance et créez des stratégies efficaces pour les surmonter',
  cjm_1: 'Client',
  cjm_2: 'Carte du trajet',
  cjm_3: 'Entrez dans le monde des clients et partagez leur expérience',
  cjm_4: "Qu'est-ce qu'une Carte de Parcours Client?",
  cjm_5:
    'CPC fournit un historique visualisé de interaction du consommateur avec un produit, service, entreprise ou marque via divers canaux et pendant une certaine période de temps.',
  cjm_6:
    "Cela permet aux entreprises de objectivement analyser l'expérience client interaction, fixer et éliminer les obstacles qui surviennent et de proposer des recommandations pour améliorer le produit.",
  cjm_7: "Détermination de l'AT, segmentation",
  cjm_8: "Identification des étapes de l'interaction client",
  cjm_9: "Identification des principaux canaux d'interaction clients",
  cjm_10: "Recherche d'expérience client",
  cjm_11: "Analyse des barrières et hypothèse d'optimisation",
  cjm_12: 'Exécution et test des hypothèses',
  cjm_13:
    "La conception d'une carte de parcours client comprend les étapes suivantes :",
  cjm_14: 'La Carte du Parcours Client démontrera :',
  cjm_15:
    "CPC fournit un historique visualisé de l'interaction des consommateurs avec un produit, un service, une entreprise ou une marque à travers différents canaux et pendant une certaine période de temps.",
  cjm_16: "Nous assurons le travail d'équipe",
  cjm_17: 'entre analystes et concepteurs',
  cjm_18: 'Nous proposons à un particulier',
  cjm_19: 'approche de chaque client',
  cjm_20: 'Nous pouvons augmenter globalement',
  cjm_21:
    "la satisfaction du client avec le service/produit et finalement augmenter sa fidélité à l'entreprise",
  cjm_22: 'Nous garantissons la',
  cjm_23:
    'optimisation du parcours du consommateur et prise en compte des processus métiers de l’entreprise',
  cjm_24: 'Nous offrons à nos clients',
  cjm_25:
    "la possibilité d'utiliser des approches combinées : CATI, CAWI, focus group, entretiens approfondis, entretiens d'experts, ethnographie, etc.",
  cjm_26: 'Pourquoi nous choisir?',
  methods_hr_1: 'Recherche en ressources humaines',
  methods_hr_2:
    'Obtenez des informations objectives sur votre équipe et découvrez comment améliorer les performances RH',
  methods_hr_3:
    "Quelques raisons pour lesquelles vous pourriez avoir besoin d'une recherche en RH",
  methods_hr_4: 'du personnel interrogé',
  methods_hr_5: 'projets rh mis en place',
  methods_hr_6: 'des sessions de formation du personnel ont été organisées',
  methods_hr_7:
    "1er holding européen pour l'expérience client et la recherche collaborateurs",
  methods_hr_8: '4service RH recherche en chiffres',
  methods_hr_9:
    'Pour identifier les problèmes et les faiblesses à chaque étape de la vie des collaborateurs de votre entreprise : recherche/recrutement/onboarding/carrière développement/recertifications',
  methods_hr_10:
    'Pour savoir quelles occasions méritent augmentations et bonus',
  methods_hr_11:
    "Vous devez prévoir ou apporter des solutions à l'insatisfaction des employés",
  methods_hr_12:
    "Grâce à la recherche sur les RH des méthodes nouvelles et actuelles d'évaluation des politiques RH, des programmes et des pratiques sont possibles",
  methods_hr_13: "Pour améliorer la santé mentale des employés de l'entreprise",
  methods_hr_14:
    'Si vous souhaitez améliorer votre productivité grâce à une approche axée sur les objectifs',
  methods_hr_15:
    'Si vous avez une équipe internationale et/ou à distance et vous devez la gérer',
  methods_hr_16:
    'Pour comprendre à quel point votre marque employeur est attrayante pour les employés potentiels',
  methods_hr_17: 'La satisfaction des employés',
  methods_hr_18: 'Recherche sur la marque RH',
  methods_hr_19: 'Diversité culturelle',
  methods_hr_20: 'Rémunération et plans salariaux',
  methods_hr_21: "Engagement et fidélité de l'équipe",
  methods_hr_22: 'Employés de la catégorie protégée',
  methods_hr_23: 'Domaines de recherche',
  methods_hr_24: 'Solutions 1',
  methods_hr_25: 'eNPS, Satisfaction des employés, Loyauté des employés',
  methods_hr_26: 'Ce que nous mesurons :',
  methods_hr_27: 'Loyauté:',
  methods_hr_28:
    'la fidélité des employés, prédire le comportement futur, déterminer le poids des facteurs qui affectent la fidélité',
  methods_hr_29: 'Implication:',
  methods_hr_30:
    'implication des employés dans le travail, intérêt pour les résultats et les réalisations',
  methods_hr_31: 'Engagement:',
  methods_hr_32:
    "identification des valeurs des employés et des valeurs de l'entreprise",
  methods_hr_33: 'Faites confiance 360:',
  methods_hr_34:
    "Faites confiance à la direction, les collègues, les subordonnés, confiance dans les stratégies et les politiques de l'employeur",
  methods_hr_35: 'Satisfaction:',
  methods_hr_36:
    'définition des facteurs propres à chaque entreprise, évaluation de la satisfaction sur chaque facteur clé influant sur la fidélisation',
  methods_hr_37: 'Solutions 2',
  methods_hr_38: 'Marque employeur',
  methods_hr_39: 'Employeur interne',
  methods_hr_40:
    'analyse de la marque (enquête auprès du personnel, des parties prenantes, des recruteurs internes et des spécialistes RH)',
  methods_hr_41: 'Analyse des employeurs externes',
  methods_hr_42:
    "(analyse de la concurrence EVP, enquête auprès des recruteurs externes, enquête auprès des demandeurs d'emploi)",
  methods_hr_43: 'Notoriété et réputation de la marque',
  methods_hr_44: 'analyse',
  methods_hr_45: 'Présence employeur',
  methods_hr_47: 'Référence employeur',
  methods_hr_48: 'collection',
  methods_hr_49: 'Benchmark concurrentiel',
  methods_hr_50: 'sur des facteurs clés tels que',
  methods_hr_51: 'Salaire,',
  methods_hr_52: 'environnement de travail, ambiance, nature du travail',
  methods_hr_53: 'Analyse des besoins et des attentes',
  methods_hr_54: "des demandeurs d'emploi",
  methods_hr_55: 'Entretiens approfondis,',
  methods_hr_56:
    'la recherche documentaire et la recherche quantitative sont utilisées',
  methods_hr_57: 'Solutions 3',
  methods_hr_58: 'Carte du parcours des employés/employé mystère',
  methods_hr_59: "construire le parcours du demandeur d'emploi identifier",
  methods_hr_60:
    "les grandes étapes de la recherche, les canaux de communication, les critères de sélection et d'évaluation des demandeurs d'emploi",
  methods_hr_61: 'surveiller toutes les étapes',
  methods_hr_62:
    "et les canaux de communication avec les demandeurs d'emploi mystères (juste prendre rendez-vous pour un entretien, avoir un entretien, un stage dans l'entreprise)",
  methods_hr_63: 'Identification',
  methods_hr_64:
    "des faiblesses dans les processus de recrutement et d'intégration",
  methods_hr_65: "Recommandations d'amélioration,",
  methods_hr_66: "benchmarking avec les leaders de l'industrie",
  mystery_shopping_1: 'Mystère',
  mystery_shopping_2: 'Achats',
  mystery_shopping_3: 'Évaluez votre service avec de vrais clients',
  mystery_shopping_4:
    'Vérifier les normes évaluer votre personnel se rapprocher de votre client',
  mystery_shopping_5:
    "Nous sommes membre d'élite de l'association professionnelle des fournisseurs d'achats mystères MSPA Europe",
  mystery_shopping_6: "d'expérience",
  mystery_shopping_7: 'visites mensuelles',
  mystery_shopping_8: 'clients mystères du monde entier',
  mystery_shopping_9: 'Projets actifs',
  mystery_shopping_10: 'bureaux, siège en Autriche',
  mystery_shopping_11: 'Fournisseurs européens de shopping mystère',
  mystery_shopping_12: 'notes de qualité de service',
  mystery_shopping_13: 'Questionnaire',
  mystery_shopping_14: 'Logiciel',
  mystery_shopping_15: "Profil de l'acheteur",
  mystery_shopping_16: 'Validation et objection',
  mystery_shopping_17: 'Analytique',
  mystery_shopping_18:
    'Avec ces 5 étapes, notre société 4Service garantit la qualité du service client mystère',
  mystery_shopping_19: 'Programme',
  mystery_shopping_20: 'Les 5 éléments du programme Achats Mystère de 4Service',
  mystery_shopping_21: 'Questionnaire:',
  mystery_shopping_22: "l'essentiel de votre programme",
  mystery_shopping_23:
    'Cette partie du programme est visible par toutes les parties prenantes',
  mystery_shopping_24: 'Pour les top-managers.',
  mystery_shopping_25:
    'Afficher toutes les étapes importantes et les détails du processus',
  mystery_shopping_26: 'Pour les employés.',
  mystery_shopping_27: "Refléter ce que les employeurs attendent d'eux",
  mystery_shopping_28: 'Pour les clients mystères.',
  mystery_shopping_29:
    'Clair et structuré pour éviter la confusion et les préjugés',
  mystery_shopping_30: 'Logiciel Shopmetrics:',
  mystery_shopping_31: 'convivial et innovant',
  mystery_shopping_32: 'Avantages:',
  mystery_shopping_33: 'Intègre',
  mystery_shopping_34: 'avec vos processus métier et vos logiciels',
  mystery_shopping_35: 'Basé sur le GDPR',
  mystery_shopping_36: 'Boîte à outils de gestion de la confidentialité',
  mystery_shopping_37: 'Détaillé',
  mystery_shopping_38: 'Forfait Conformité Légale',
  mystery_shopping_39: 'Rapport',
  mystery_shopping_40: 'constructeur',
  mystery_shopping_41: 'Gestion',
  mystery_shopping_42: "des niveaux d'accès",
  mystery_shopping_43: 'La plupart des questionnaires',
  mystery_shopping_44: 'sont terminés en une heure',
  mystery_shopping_45: 'Profil des acheteurs:',
  mystery_shopping_46: 'Plus de 200 000 clients mystères dans le monde',
  mystery_shopping_47:
    'Critères principaux: proximité maximale avec votre public cible, avec une expérience antérieure avec votre entreprise ou vos concurrents',
  mystery_shopping_48: 'Formation des acheteurs:',
  mystery_shopping_49: "Test de formation d'introduction",
  mystery_shopping_50: 'Skype/Appel téléphonique',
  mystery_shopping_51: "Test d'évaluation (facultatif)",
  mystery_shopping_52: 'Validation et objections',
  mystery_shopping_53: 'Validation',
  mystery_shopping_54: 'Système',
  mystery_shopping_55: '100% rapports',
  mystery_shopping_56: "sont revus par l'équipe de validation",
  mystery_shopping_57: 'Appels supplémentaires',
  mystery_shopping_58: 'pour clarification',
  mystery_shopping_59: 'Enregistrements audio',
  mystery_shopping_60:
    'après la visite pour noter les problèmes les plus importants',
  mystery_shopping_61: 'Fraude aux employés',
  mystery_shopping_62: 'système de prévention',
  mystery_shopping_63: 'Objection',
  mystery_shopping_64: 'Système',
  mystery_shopping_65: "L'évaluation de tout acheteur",
  mystery_shopping_66:
    "peut être contesté par l'employé directement sur le site",
  mystery_shopping_67: 'Chaque objection',
  mystery_shopping_68:
    "est pris en compte et répondu par l'équipe de validation locale",
  mystery_shopping_69: 'Vous pouvez voir la statistique',
  mystery_shopping_70: 'des objections',
  mystery_shopping_71: 'Analytique.',
  mystery_shopping_72: 'Le rapport est présenté en personne et contient:',
  mystery_shopping_73: 'Entreprise',
  mystery_shopping_74: 'points douloureux et préoccupations',
  mystery_shopping_75: 'Dynamique',
  mystery_shopping_76: 'par divisions et sections',
  mystery_shopping_77: 'Corrélation',
  mystery_shopping_78: 'entre le respect des normes et la satisfaction client',
  mystery_shopping_79: 'Analyse comparative',
  mystery_shopping_80: 'contre des concurrents',
  mystery_shopping_81: 'Pratique',
  mystery_shopping_82: "recommandations sur l'amélioration du service",
  mystery_shopping_83: "Qu'est-ce qui nous differencie?",
  mystery_shopping_84: "Nous réalisons des séances d'idéation",
  mystery_shopping_85:
    'Non seulement nous recommandons quoi faire, mais nous recherchons avec vous des moyens pratiques de résoudre les problèmes de service.',
  mystery_shopping_86:
    "Les séances d'idéation sont un outil puissant qui nous aide à:",
  mystery_shopping_87:
    "Développer des orientations pour l'amélioration des produits ou des services",
  mystery_shopping_88:
    'Explorer de nouvelles opportunités de flux de revenus et de nouvelles stratégies commerciales',
  mystery_shopping_89:
    'Trouver des solutions aux défis complexes centrés sur le client',
  mystery_shopping_90:
    'Transformer les points douloureux des clients en délices',
  mystery_shopping_91: 'Nous réalisons des entretiens vidéo',
  mystery_shopping_92:
    'Nous ne recueillons pas seulement des questionnaires - nous recevons également des commentaires en direct des acheteurs',
  mystery_shopping_93: 'Nous faisons cela afin de:',
  mystery_shopping_94:
    'Nos animateurs mèneront des entretiens approfondis et donneront un retour efficace',
  mystery_shopping_95: "Visualisez l'expérience du point de vue du client",
  mystery_shopping_96: 'Améliorez votre stratégie de marketing de contenu',
  mystery_shopping_97: 'Prédire le comportement des clients',
  mystery_shopping_98: 'Nous concevons la Carte du Parcours Client',
  mystery_shopping_99:
    'La cartographie du parcours client nous aide à nous mettre à la place des clients pour comprendre leurs besoins et leurs défis.',
  mystery_shopping_100: 'Créer un parcours client est le meilleur moyen de:',
  mystery_shopping_101: "Voir l'expérience du point de vue du client",
  mystery_shopping_102: 'Améliorez votre stratégie de marketing de contenu',
  mystery_shopping_103: 'Prédire le comportement des clients',
  mystery_shopping_104:
    'Identifier les lacunes dans le service ou les communications',
  mystery_shopping_105: "Nous proposons différents types d'achats mystères",
  mystery_shopping_106: "Types d'achat mystère",
  mystery_shopping_107: 'Appel mystérieux',
  mystery_shopping_108:
    "Appelle vos managers et/ou centre d'appels pour déterminer la qualité du service fourni par téléphone",
  mystery_shopping_109: 'Achat mystère de luxe',
  mystery_shopping_110:
    'Service de vérification dans les lieux de luxe par une catégorie spéciale de clients mystères',
  mystery_shopping_111: 'Visites compétitives',
  mystery_shopping_112:
    'Le moyen le plus simple de comprendre comment fonctionne votre concurrent',
  mystery_shopping_113: 'Employé mystère',
  mystery_shopping_114:
    "Vous aidera à comprendre l'ensemble du parcours de votre employé (embauche, adaptation, processus de bureau, etc.)",
  mystery_shopping_115: 'Visites de motivation',
  mystery_shopping_116:
    "Un outil utile pour stimuler l'offre de services et/ou de produits complémentaires, la vente de produits spécifiques",
  mystery_shopping_117: 'Client mystère en ligne',
  mystery_shopping_118:
    "Simule et mesure l'expérience client dans votre boutique en ligne, votre site Web ou votre application",
  mystery_shopping_119: 'Vérification des soldes et des promotions',
  mystery_shopping_120:
    'Aide à identifier la façon dont les promotions sont affichées dans tous les emplacements et leur efficacité',
  mystery_shopping_121: "Visites d'achat et de retour",
  mystery_shopping_122:
    "Aide à refléter l'ensemble du processus, de l'achat au retour, afin d'améliorer l'expérience client",
  mystery_shopping_123: '> 20 années',
  nps_1: 'Promotion Net',
  nps_2: 'Score',
  nps_3: 'Mesurer, suivre et améliorer la fidélité des clients',
  nps_4: 'Ils répondent avec un score de 0 à 6.',
  nps_5:
    "Ils ont peut-être eu une mauvaise expérience et sont peu susceptibles d'acheter à nouveau chez vous, et peuvent même décourager les autres d'acheter chez vous.",
  nps_6: 'Ce sont des répondants qui obtiennent un score compris entre 7 et 8.',
  nps_7:
    'Ils sont satisfaits de votre service mais pas assez heureux pour être considérés comme des promoteurs.',
  nps_8:
    'Ce sont les répondants qui évaluent votre entreprise comme un 9 ou un 10.',
  nps_9:
    "Cela signifie qu'ils vous recommanderaient à un ami ou à un collègue, et défendent donc vos intérêts.",
  nps_10: "Qu'est-ce que le SRN?",
  nps_11:
    'Il mesure la perception des clients en se basant sur une question simple:',
  nps_12:
    'Quelle est la probabilité que vous recommandiez [Organisation/Produit/Service] à un ami ou à un collègue?',
  nps_13: 'Mon entreprise en a-t-elle besoin?',
  nps_14: 'Le SRN est votre croissance durable',
  nps_15: 'Comprendre la dynamique de la fidélité client',
  nps_16: 'Motivez votre personnel',
  nps_17: 'Comprendre la relation avec différents produits',
  nps_18:
    'Comparez la façon dont les clients vous traitent et traitent vos concurrents',
  nps_19: 'Que puis-je faire avec le SRN?',
  nps_20: "Pour quel type d'entreprise le SRN est-il pertinent ?",
  nps_21: "Méthodes de collecte d'informations :",
  nps_22: 'Nos recommandations lorsque vous travaillez avec SRN',
  nps_23:
    'Cela signifie que nous élaborons des lignes directrices pour aider à transformer les détracteurs en promoteurs',
  nps_24: 'Nous travaillons en "boucle fermée"',
  nps_25:
    'SRN est une recherche de suivi dans laquelle nous suivons les changements dans la fidélité des clients',
  nps_26: 'Nous surveillons la dynamique',
  nps_27:
    'Nous vous proposons de toujours évaluer les concurrents de votre créneau pour une analyse plus approfondie',
  nps_28: 'Évaluation des concurrents',
  price_monitoring_1: 'Surveillance des prix',
  price_monitoring_2: 'Surveillance de la vente au détail',
  price_monitoring_3:
    'Augmentez votre potentiel de vente et optimisez votre politique de prix grâce à la veille tarifaire de vos concurrents',
  price_monitoring_4:
    '4Service fournit des services de surveillance des prix dans le monde entier depuis plus de 20 ans',
  price_monitoring_5:
    "Les prix sont contrôlés dans un court délai par notre équipe d'auditeurs internes qualifiés, ce qui nous permet de réagir rapidement aux changements de prix des concurrents",
  price_monitoring_6:
    'Défis que notre entreprise peut vous aider à résoudre grâce à la surveillance des prix',
  price_monitoring_7: 'Évaluation des prix des concurrents',
  price_monitoring_8: 'et politiques de produits',
  price_monitoring_9: 'Suivi des promotions',
  price_monitoring_10: 'et offres spéciales',
  price_monitoring_11: 'Surveillance des conditions du marché',
  price_monitoring_12: 'et environnement de marché',
  price_monitoring_13: 'Vérification et contrôle des points de vente',
  price_monitoring_14: 'pour équipements spéciaux et marchandises',
  price_monitoring_15: 'Gestion du produit',
  price_monitoring_16: 'flux au sein du commerce',
  price_monitoring_17: "Conditions d'optimisation",
  price_monitoring_18:
    'pour une meilleure collaboration avec les fournisseurs, les revendeurs',
  price_monitoring_19: "Mesurer l'espace en rayon,",
  price_monitoring_20:
    'évaluation du positionnement des marchandises, de la disponibilité des stocks',
  price_monitoring_21: 'Indicateurs clés et critères de suivi',
  price_monitoring_22:
    'Toutes les données sont compilées dans une interface comprenant :',
  price_monitoring_23: 'nom',
  price_monitoring_24: 'de la catégorie de produits et de marques',
  price_monitoring_25: 'disponibilité',
  price_monitoring_26: 'de matériel promotionnel',
  price_monitoring_27: 'prix',
  price_monitoring_28:
    "et la disponibilité d'éventuelles offres promotionnelles",
  price_monitoring_29: 'étagère',
  price_monitoring_30: 'taille',
  price_monitoring_31: 'produit',
  price_monitoring_32: 'afficher',
  price_monitoring_33: 'UGS',
  price_monitoring_34: 'orienté vers',
  price_monitoring_35: 'taper',
  price_monitoring_36: "d'emballage",
  price_monitoring_37: 'tout autre',
  price_monitoring_38: 'informations générales',
  price_monitoring_39: "Analyse d'avant-projet:",
  price_monitoring_40: 'Nous étudions',
  price_monitoring_41:
    'la politique de prix et de produits de votre entreprise, les pratiques commerciales et industrielles',
  price_monitoring_42: 'Nous fournissons',
  price_monitoring_43:
    'une formation, des tests et une certification rapides pour les auditeurs',
  price_monitoring_44: 'Nous personnalisons',
  price_monitoring_45: 'et configurer le logiciel',
  price_monitoring_46: 'Travail sur le terrain:',
  price_monitoring_47: 'Visites au détail',
  price_monitoring_48: 'prises électriques',
  price_monitoring_49: 'Rapport:',
  price_monitoring_50: 'Notre équipe analytique',
  price_monitoring_51:
    "prépare des rapports en ligne dans les comptes personnels de l'entreprise",
  price_monitoring_52: 'Nous fournissons',
  price_monitoring_53:
    'un reportage photo complet, rapport PPT, rapport Excel en termes de type de localité, nom de chaîne, adresse de point de vente',
  price_monitoring_54: 'Comment cela',
  price_monitoring_55: 'fonctionne',
  price_monitoring_56: 'la taille des remises individuelles',
  price_monitoring_57: 'calcul des frais',
  price_monitoring_58: 'taille des bonus individuels',
  price_monitoring_59: 'Programmes de fidélité',
  price_monitoring_60: 'etc.',
  price_monitoring_61: 'Nous utilisons des logiciels spécialisés:',
  price_monitoring_62:
    'PRADATA, Shopmetrics et offrons un support technique complet',
  price_monitoring_63: 'Déploiement rapide',
  price_monitoring_64: "et mise à l'échelle (dès le jour 1)",
  price_monitoring_65: 'Toutes les données confirmées',
  price_monitoring_66: 'par photos et marquage GPS',
  price_monitoring_67: 'Nous avons le nombre nécessaire',
  price_monitoring_68: "d'agents de terrain dans n'importe quel pays",
  price_monitoring_69: 'Nous pouvons travailler avec de grands',
  price_monitoring_70: 'quantités de données',
  price_monitoring_71: 'Suivi des prix individuels',
  price_monitoring_72:
    'Si vous avez des produits complexes - nous pouvons vous aider à définir votre stratégie de prix',
  price_monitoring_73:
    'Nous pouvons surveiller les prix des concurrents individuels, par exemple:',
  price_monitoring_74: 'Bas',
  price_monitoring_75: 'prix',
  price_monitoring_76: 'Haute',
  price_monitoring_77: 'efficacité',
  price_monitoring_78: 'Rapport Excel',
  price_monitoring_79: 'Rapport PPT',
  price_monitoring_80: 'Rapport BI',
  price_monitoring_81:
    'Les données de tarification des concurrents vous aident à prendre les meilleures décisions en matière de tarification',
  price_monitoring_82:
    'Nous fournissons un rapport photo complet, un rapport PPT, un rapport Excel en termes de type de localité',
  qualitative_research_1: 'Qualitatives',
  qualitative_research_2: 'recherches',
  qualitative_research_3:
    "Recueillir, analyser et interpréter les données à travers l'observation des actions et des paroles de vos clients",
  qualitative_research_4: 'Valeurs',
  qualitative_research_5: 'Comportement',
  qualitative_research_6: 'Arrière-plan',
  qualitative_research_7: 'Attentes',
  qualitative_research_8: 'Environnement',
  qualitative_research_9: 'Craintes/obstacles',
  qualitative_research_10: 'A quoi sert le choix du client ?',
  qualitative_research_11: 'Homme avec téléphone',
  qualitative_research_12: 'marque',
  qualitative_research_13: 'catégorie',
  qualitative_research_14: 'publicité',
  qualitative_research_15: 'produit',
  qualitative_research_16:
    "La particularité des méthodes qualitatives est qu'elles nous permettent de comprendre les causes et la motivation du comportement des consommateurs",
  qualitative_research_17:
    "Аanalyse de l'attitude du consommateur face à un phénomène particulier",
  qualitative_research_18: 'Perspectives et expériences individuelles',
  qualitative_research_19:
    'Sujets qui ne peuvent pas être étudiés par des sondages',
  qualitative_research_20: 'Sujets sensibles',
  qualitative_research_21: 'Public difficile à atteindre',
  qualitative_research_22: 'Géographie complexe',
  qualitative_research_23: 'Entretiens approfondis utiles pour:',
  qualitative_research_24: 'Entretiens approfondis',
  qualitative_research_25:
    'Une entrevue en profondeur est une technique de recherche qualitative qui consiste à mener des entrevues individuelles avec les répondants pour explorer leurs points de vue sur une idée, un programme ou une situation particulière. Un entretien approfondi explore les expériences / perceptions / pratiques individuelles de manière détaillée.',
  qualitative_research_26: 'Buts:',
  qualitative_research_27: 'Caractérisation des normes sociales et culturelles',
  qualitative_research_28: 'Public difficile à atteindre',
  qualitative_research_29:
    'Partage et comparaison (Morgan) étudiés par sondages',
  qualitative_research_30:
    'Sujets qui ne peuvent pas être étudiés par des sondages',
  qualitative_research_31: 'Groupes de discussion approfondis utiles pour:',
  qualitative_research_32: 'Groupes de concentration',
  qualitative_research_33:
    "Exploration approfondie des rencontres individuelles, des discussions naissantes, des discussions entre manifestations sous l'influence de l'animateur du groupe.",
  qualitative_research_34:
    'Générer des informations sur les expériences partagées et les normes sociales par le biais de discussions de groupe, de données narratives dans une discussion ciblée.',
  qualitative_research_36: 'Avantages:',
  qualitative_research_37:
    'Étudier le mode de vie et le comportement du consommateur dans différentes conditions réelles (à domicile, en point de vente, au travail, etc.)',
  qualitative_research_38:
    'Rechercher des idées pour le développement de nouveaux produits, emballages, amélioration des services, etc.',
  qualitative_research_39: 'Recherche ethnographique approfondie utile pour: ',
  qualitative_research_40: 'Recherche ethnographique',
  qualitative_research_41:
    "L'ethnographie est un type de recherche qualitative qui consiste à s'immerger dans une communauté ou une organisation particulière pour observer de près leur comportement et leurs interactions.",
  qualitative_research_42:
    "L'ethnographie est une méthode de recherche flexible qui vous permet d'acquérir une compréhension approfondie de la culture, des conventions et de la dynamique sociale partagées d'un groupe. Toutefois, cela implique également des défis pratiques et éthiques.",
  qualitative_research_43: 'Méthodes :',
  qualitative_research_44:
    "L'ethnographie est une étude du consommateur en conditions réelles et en interaction avec un produit/service",
  qualitative_research_45: 'Avantages de travailler avec nous',
  qualitative_research_46:
    "Nous pouvons trouver des répondants de n'importe quel public cible. Et nous contrôlons la qualité du recrutement",
  qualitative_research_47: 'Recrutement de tout type de complexité',
  qualitative_research_48:
    'Nos modérateurs sont multilingues et ont des compétences de facilitation',
  qualitative_research_49: 'Modérateurs experts',
  qualitative_research_50: 'Travailler en ligne/hors ligne',
  qualitative_research_51: "Nous fournissons l'audio/vidéo",
  qualitative_research_52: 'enregistrements',
  qualitative_research_53:
    "Nous pouvons mener des recherches qualitatives dans n'importe quelle localité",
  quantitative_research_1: 'Étude de satisfaction client',
  quantitative_research_2:
    'avec un produit ou un service en particulier (SCI, NPS)',
  quantitative_research_3: 'Mener une segmentation',
  quantitative_research_4: 'enquêtes sur différents marchés',
  quantitative_research_5: 'Étude de marché',
  quantitative_research_6: 'Gestion du NPS',
  quantitative_research_7: 'Analyse de la concurrence',
  quantitative_research_8: 'Quantitatives',
  quantitative_research_9: 'recherches',
  quantitative_research_10:
    'Méthodologie de recherche qui utilise pour tester les théories sur les attitudes et les comportements des gens sur la base de données objectives, numériques et statistiques',
  quantitative_research_11: 'années',
  quantitative_research_12: "d'expérience CX dans le monde",
  quantitative_research_13: 'panneau',
  quantitative_research_14: 'des répondants',
  quantitative_research_15: 'des pays',
  quantitative_research_16: 'nous menons des projets partout dans le monde',
  quantitative_research_17: 'Qui nous sommes',
  quantitative_research_18:
    "4Service est une recherche marketing mondiale et une holding CX. Nous offrons des services experts de collecte de données à une grande variété d'entreprises",
  quantitative_research_19: '4service En Chiffres',
  quantitative_research_20: 'Assisté par ordinateur',
  quantitative_research_21: 'Entrevue Web',
  quantitative_research_22:
    "Une technique de questionnaire basée sur Internet. Le répondant remplit un questionnaire électronique sans l'aide d'un intervieweur. Le questionnaire peut être envoyé par voie électronique ou affiché sur le site Web.",
  quantitative_research_23: 'Entretien Web assisté par ordinateur',
  quantitative_research_24: 'Dans la plupart des cas',
  quantitative_research_25:
    'cette méthode est la plus rentable en termes de coûts de matériel et de temps',
  quantitative_research_26: 'Cette enquête fournit',
  quantitative_research_27:
    "une opportunité d'interviewer un public difficile à atteindre",
  quantitative_research_28: 'Il offre de nombreuses possibilités',
  quantitative_research_29:
    'pour la démonstration de matériel vidéo et audio ainsi que des images',
  quantitative_research_30: 'Les répondants peuvent être',
  quantitative_research_31: "situé n'importe où dans le monde",
  quantitative_research_32: "Cela permet d'enquêter",
  quantitative_research_33:
    "le comportement spécifique de l'audience Internet. Les principaux résultats sont accessibles par le client en temps réel via une interface web",
  quantitative_research_34: 'Admissible à toute confidentialité,',
  quantitative_research_35:
    "sujet ou problème sensible et personnel auquel les répondants ont peut-être hésité à répondre lorsqu'ils ont parlé à un intervieweur",
  quantitative_research_36: '150 000+ panneaux',
  quantitative_research_37:
    'Nous avons notre propre panel de répondants dans le monde entier',
  quantitative_research_38: 'Nous travaillons avec des demandes difficiles',
  quantitative_research_39:
    'En utilisant des outils complémentaires (médias sociaux, publicité), nous pouvons trouver un public spécifique et un profil sophistiqué du répondant',
  quantitative_research_40: 'Nous motivons nos répondants',
  quantitative_research_41:
    'Nous avons conçu un cadre pour encourager la réalisation de longues enquêtes en fournissant des incitations matérielles',
  quantitative_research_42: 'Entretiens face à face',
  quantitative_research_43: "réalisée à l'aide d'une tablette",
  quantitative_research_44:
    "C'est l'une des principales méthodes de collecte de données quantitatives, au cours de laquelle l'enquêteur communique directement avec le répondant sur un questionnaire strictement structuré.",
  quantitative_research_45:
    "Les entretiens face-à-face peuvent être menés sur le lieu de travail ou de résidence des répondants, dans la rue ou sur des lieux de vente désignés à l'aide d'une tablette",
  quantitative_research_46:
    "Entretiens face à face réalisés à l'aide d'une tablette",
  quantitative_research_47: 'Détermination du niveau',
  quantitative_research_48: 'de popularité et de reconnaissance de la marque',
  quantitative_research_49: 'Segmentation des consommateurs',
  quantitative_research_50: "en fonction de leur comportement d'achat",
  quantitative_research_51: "L'analyse de l'efficacité",
  quantitative_research_52: 'de la campagne publicitaire',
  quantitative_research_53: 'Satisfaction du client',
  quantitative_research_54: 'la mesure',
  quantitative_research_55: 'Déterminer le meilleur prix',
  quantitative_research_56: 'pour le produit ou le service',
  quantitative_research_57: 'Analyse',
  quantitative_research_58: 'des préférences des consommateurs',
  quantitative_research_59: 'Étude',
  quantitative_research_60: 'de la façon dont les consommateurs achètent',
  quantitative_research_61: 'Évaluation',
  quantitative_research_62: 'de la capacité du marché',
  quantitative_research_63: 'Logiciel DigSee',
  quantitative_research_64:
    'Nous utilisons un logiciel spécial avec enregistrement audio et GPS',
  quantitative_research_65: 'Intervieweurs et superviseurs',
  quantitative_research_66:
    "nous pouvons mener des entretiens dans n'importe quelle langue et traduire dans la langue préférée du client",
  quantitative_research_67: 'Échantillonnage',
  quantitative_research_68:
    'Le service analytique développe un système de recrutement des répondants pour obtenir le meilleur résultat possible',
  quantitative_research_69: 'Assisté par ordinateur',
  quantitative_research_70: 'Entretien téléphonique',
  quantitative_research_71:
    "Méthodologie de collecte d'informations quantitatives par entretiens téléphoniques à l'aide d'un questionnaire clairement structuré.",
  quantitative_research_72: 'Entretien téléphonique assisté par ordinateur',
  quantitative_research_73: "Nous avons nos propres centres d'appels",
  quantitative_research_74:
    'Nous utilisons un logiciel spécial avec enregistrement audio et GPS',
  quantitative_research_75: 'Opérateurs qualifiés',
  quantitative_research_76:
    'Nos opérateurs parlent différentes langues, sont formés et bien testés et améliorent continuellement leurs compétences',
  quantitative_research_77: 'Bases de données',
  quantitative_research_78:
    'Nous travaillons avec nos propres bases de données et vos bases de clients également',
  quantitative_research_79: 'ANS',
  quantitative_research_80:
    'Nous garantissons la qualité et signons un ANS (Accord de Niveau de Service)',
  quantitative_research_81: "Établissement d'objectifs",
  quantitative_research_82: 'Détermination de la méthodologie',
  quantitative_research_83: 'Segmentation et échantillonnage',
  quantitative_research_84: 'Définir les canaux de collecte des données',
  quantitative_research_85: 'Créer un questionnaire logique pour le répondant',
  quantitative_research_86: 'Processus de collecte de données',
  quantitative_research_87: 'Assurance qualité',
  quantitative_research_88: "Techniques d'analyse",
  quantitative_research_89: 'Traitement des données',
  quantitative_research_90: "Conception de l'enquête",
  quantitative_research_91: 'Localisation GPS des enquêteurs',
  quantitative_research_92: 'Verification des données',
  quantitative_research_93: 'Programmation et test du questionnaire',
  quantitative_research_94: 'La validation des données',
  quantitative_research_95:
    'Enregistrements audio en suivant les exigences du GDPR',
  quantitative_research_96: 'Formation et compétence du personnel de recherche',
  quantitative_research_97: 'Assurance qualité',
  quantitative_research_98: 'Analyse factorielle',
  quantitative_research_99: 'Segmentation',
  quantitative_research_100: 'Cartographie perceptive',
  quantitative_research_101: 'Corrélation',
  quantitative_research_102: 'Modélisation des conversions',
  quantitative_research_103: 'Jaccard',
  quantitative_research_104: 'Analyse groupée',
  quantitative_research_105: 'etc.',
  quantitative_research_106: 'Rapports analytiques',
  quantitative_research_107:
    "Nous utilisons SPSS® et d'autres outils pour créer des rapports analytiques en utilisant différentes méthodologies",
  quantitative_research_108: 'What business challenges сan be solved:',
  quantitative_research_109: 'What business challenges сan be solved:',
  social_responsibility_1: "4Service Responsabilité Sociale d'Entreprise",
  social_responsibility_2:
    "Du cœur et de l'âme de chaque membre de notre équipe. Nous visons à garder une étincelle de bienveillance chez chacun de nos employés et à rendre le monde qui nous entoure un peu meilleur",
  social_responsibility_3: 'Activités éducatives / Accompagnement des jeunes',
  social_responsibility_4:
    'Nos initiatives de sensibilisation pour les enfants et les étudiants',
  social_responsibility_5: "Service Mania jeu d'entreprise",
  social_responsibility_6:
    "Une partie importante de nos activités de formation est le jeu d'entreprise Service Mania.",
  social_responsibility_7:
    'Service Mania vous met au défi de réfléchir stratégiquement à la manière de gérer les situations inhabituelles.',
  social_responsibility_8: 'Présentation de la recherche / Diiya. Entreprise',
  social_responsibility_9:
    'Une perspective commerciale et client sur la situation des services: une présentation des résultats de notre recherche 4Service par Victoria Skorbota, responsable du développement chez 4Service Ukraine',
  social_responsibility_10: 'Sessions de conception de services',
  social_responsibility_11:
    'La méthodologie de conception de service est une partie importante de 4Service. Il y a quelques années, nous sommes devenus un partenaire de la DesignThinkers Academy et non seulement nous utilisons les meilleures pratiques dans notre travail, mais nous dispensons également activement des formations aux meilleures entreprises.',
  social_responsibility_12: 'Charité / Bénévolat',
  social_responsibility_13:
    "Orphelinats, maisons de retraite pour personnes âgées et volontariat pour l'armée ukrainienne. La partie la plus touchante de nos activités",
  social_responsibility_14: '#AideFacileAvecLeGroupe4Service',
  social_responsibility_15:
    "Nous travaillons en partenariat avec le Blagomai Charity Fund et essayons de célébrer et d'aider ceux qui sont dans le besoin",
  social_responsibility_16:
    'Collecte de fonds pour les orphelinats et les maisons de retraite',
  social_responsibility_17:
    "Chez 4Service, nous avons lancé plusieurs collectes de fonds pour les maisons de retraite et les orphelinats afin d'acheter des produits de première nécessité",
  social_responsibility_18: "#Sauvonsl'Ukraine",
  social_responsibility_19:
    'La guerre a touché bon nombre de nos employés et amis proches. Chacun de nous aide autant que possible',
  social_responsibility_20: 'Bien-être animal',
  social_responsibility_21:
    'Tout le monde peut venir à notre bureau avec son animal de compagnie. Nous aidons les refuges, collectons des fonds pour la nourriture et parfois aussi cherchons des maisons pour les animaux sans abri',
  social_responsibility_22: 'Soutien aux refuges pour animaux',
  social_responsibility_23:
    "Les animaux sans abri ont autant besoin d'aide que tous les membres vulnérables de notre société.",
  social_responsibility_24:
    'Notre société a pris en charge le refuge pour animaux errants Sirius (région de Kiev, Ukraine).',
  social_responsibility_25:
    'Des initiatives créatives pour aider les animaux errants',
  social_responsibility_26:
    "Comment porter la question urgente des refuges pour animaux à l'attention de nos clients et partenaires ?",
  social_responsibility_27: 'Bureau acceptant les animaux de compagnie',
  social_responsibility_28:
    "Beaucoup de membres de notre équipe ont des animaux de compagnie. Nous avons créé un environnement sain où chacun peut venir au bureau avec son animal de compagnie et se sentir à l'aise",
  ux_testing_1: 'Tests UX',
  ux_testing_1_1: 'Tests UX',
  ux_testing_2:
    "Approche humaine et basée sur l'IA pour tester et améliorer vos sites Web et applications",
  ux_testing_3: "Qu'est-ce que les tests UX",
  ux_testing_4:
    'Les tests UX vous aident à comprendre comment les gens interagissent avec votre produit, votre application ou votre site Web',
  ux_testing_5: "La navigation n'est pas très claire",
  ux_testing_6: 'Je ne sais pas comment passer une commande',
  ux_testing_7: 'Pas assez de photos dans le catalogue',
  ux_testing_8: 'Vous allez:',
  ux_testing_9: "Découvrir des opportunités d'amélioration",
  ux_testing_10:
    "Identifier les problèmes dans la conception et l'interaction avec le client",
  ux_testing_11:
    "Découvrir les difficultés rencontrées par l'utilisateur lors de l'interaction avec le site",
  ux_testing_12: 'Observer le parcours client',
  ux_testing_13:
    'Reconnaître quand et pourquoi votre public cible quitte le site',
  ux_testing_14:
    "Définir les sections qui suscitent le moins et le plus d'intérêt et d'émotion",
  ux_testing_15: "Test UX basé sur l'utilisateur",
  ux_testing_16:
    '4Service vous fournit les véritables informations humaines dont vous avez besoin pour créer des sites Web et des applications centrés sur le client en utilisant des méthodes qualitatives et quantitatives',
  ux_testing_17:
    'Je ne comprends pas comment utiliser le site. Où le menu est-il caché ?',
  ux_testing_18:
    "Je pense qu'il y a trop d'espace entre les lettres, le texte est illisible",
  ux_testing_19:
    "Les images se chevauchent Est-ce ainsi que c'est censé être ?",
  ux_testing_20:
    'Les tests UX vous aident à comprendre comment les gens interagissent avec votre produit, votre application ou votre site Web',
  ux_testing_21: "Conception de recherche de test UX basée sur l'utilisateur :",
  ux_testing_22: "Découvrir des opportunités d'amélioration",
  ux_testing_23:
    'Détermination du type et de la méthode de test (qualitatif/quantitatif)',
  ux_testing_24: "Rédaction d'hypothèses et de scénarios d'utilisation",
  ux_testing_25: 'Analyse et rapport des résultats des tests',
  ux_testing_26: 'Deux solutions',
  ux_testing_27: 'Nous proposons deux approches pour réussir les tests UX',
  ux_testing_28: "Test UX basé sur l'utilisateur",
  ux_testing_29: "IA + tests UX basés sur l'humain",
  ux_testing_30: 'Nos points forts:',
  ux_testing_31: 'Nous pouvons analyser le paysage concurrentiel',
  ux_testing_32:
    'Nous pouvons faire appel à des experts restreints pour mener et participer à des entretiens',
  ux_testing_33:
    'Nous pouvons mener des entretiens dans différentes langues et les traduire dans la langue requise',
  ux_testing_34: "avec l'utilisabilité existante",
  ux_testing_35: 'métriques ou normes',
  ux_testing_36: 'vos performances',
  ux_testing_37: 'face à un concurrent',
  ux_testing_38: 'versions',
  ux_testing_39: 'du même produit',
  ux_testing_40: 'Note de satisfaction',
  ux_testing_41: 'NPS',
  ux_testing_42: 'Taux de réussite',
  ux_testing_43: "Taux d'erreur",
  ux_testing_44: "Temps d'exécution de la tâche",
  ux_testing_45: "Test UX basé sur l'utilisateur",
  ux_testing_46: 'Méthode quantitative',
  ux_testing_47:
    'Nous utilisons au moins 100 personnes pour participer aux tests UX pour cette étape',
  ux_testing_48:
    "Pertinent lorsque vous avez besoin d'une comparaison de votre site Web ou de votre application :",
  ux_testing_49: 'Mesure de la méthode quantitative :',
  ux_testing_50: 'Observation',
  ux_testing_51:
    "Passer du temps avec un utilisateur ou un groupe d'utilisateurs et observer leur comportement avec le produit lorsqu'ils l'utilisent au quotidien",
  ux_testing_52: 'Interview en profondeur',
  ux_testing_53:
    "Vous permettre d'apprendre les attitudes, les croyances, les désirs et les expériences des utilisateurs qui visitent votre site. Nous recommandons de mener 15 à 20 entretiens",
  ux_testing_54: 'Recherche ethnographique',
  ux_testing_55:
    "Réalisé dans l'environnement des répondants où ils utiliseront le produit. En observant, vous commencez à bien comprendre la psychologie de l'utilisateur, les défis émotionnels auxquels il est confronté.",
  ux_testing_56: "Test UX basé sur l'utilisateur",
  ux_testing_57: 'Méthode qualitative',
  ux_testing_58:
    'Cette méthode aide à comprendre les motivations et la logique du comportement des utilisateurs',
  ux_testing_59: 'Nous utilisons:',
  ux_testing_60:
    'Nous développons une carte du parcours client pour illustrer les résultats de nos recherches',
  ux_testing_61: '30 participants (homme/femme)',
  ux_testing_62:
    'Les répondants présélectionnent et les invitent à répondre au sondage',
  ux_testing_63: 'Entretien en ligne à distance sur la plateforme Wantent',
  ux_testing_64: "IA + basé sur l'humain",
  ux_testing_65: "IA + tests UX basés sur l'humain",
  ux_testing_66:
    "Une combinaison de technologie unique d'intelligence artificielle Wantent et d'utilisateurs réels",
  ux_testing_67: "La grande police dans le titre a attiré l'attention",
  ux_testing_68:
    'Les utilisateurs regardent les numéros  «à voix haute» mais ne lisent pas les informations à côté',
  ux_testing_69:
    'Les tests UX vous aident à comprendre comment les gens interagissent avec votre produit, votre application ou votre site Web',
  ux_testing_70: 'IA + conception de recherche de tests humains',
  ux_testing_71: 'Comment ça fonctionne',
  ux_testing_72:
    "Wantent est une solution d'adaptation au marché du contenu basée sur l'IA.",
  ux_testing_73:
    "Wantent estime l'efficacité des sites Web et des applications en appliquant les technologies ML pour analyser les réactions émotionnelles et l'engagement du public.",
  ux_testing_74:
    "Pour interpréter les résultats, nous utilisons des techniques de narration, la diversité et l'empathie, pour stimuler la performance et la croissance.",
  ux_testing_75:
    "L'attention et les émotions de l'utilisateur lors des transitions entre les sections",
  ux_testing_76:
    'Statistiques par groupe de participants (attention et concentration tout au long de la session)',
  ux_testing_77: 'Analyse des conversions de pages',
  ux_testing_78: "Attention et émotions pendant l'exécution des tâches",
  ux_testing_79: 'Des découvertes sur la perception',
  ux_testing_80: 'du site Web par différents groupes de participants',
  ux_testing_81: 'Analyse de la facilité',
  ux_testing_82:
    'de naviguer dans les pages et la facilité de trouver les éléments importants',
  ux_testing_83: 'Une analyse de perception',
  ux_testing_84: 'des informations fournies sur le site',
  ux_testing_85: 'Zones de définition',
  ux_testing_86:
    "d'amélioration au sein des pages individuelles (navigation, blocs d'information, etc.)",
  ux_testing_87: 'Nous analysons en détail',
  ux_testing_88: 'Principaux résultats de la recherche',
  ux_testing_89:
    'Recommandations pour améliorer la structure du site Web et des pages individuelles',
  ux_testing_90:
    "Nous sélectionnons avec soin le public cible pour les tests d'utilisabilité.",
  ux_testing_91:
    "Nous effectuons des recherches dans différents pays, différentes langues et parmi différents groupes d'utilisateurs",
  ux_testing_92: 'Nous pouvons tester dans les applications',
  ux_testing_93:
    'et sur les sites Web des concurrents et fournir une analyse comparative',
  ux_testing_94: 'Nous combinons différentes méthodes de recherche.',
  ux_testing_95:
    'Nos animateurs qualifiés guident le participant tout au long du processus de test.',
  ux_testing_96: 'Nous effectuons des tests',
  ux_testing_97: "sur une variété d'appareils et de versions de logiciels",
  ux_testing_98: 'Pourquoi nous choisir?',
  wantent_1: '4Service x',
  wantent_2: 'Wantent',
  wantent_3:
    "Un projet partenaire unique de 4Service et Wantent © Une combinaison d'intelligence artificielle et d'une approche humaine pour l'analyse de contenu",
  wantent_4: 'mesurer le succès avant que le contenu ne soit diffusé',
  wantent_5: 'atténuer les risques liés aux problèmes de diversité',
  wantent_6:
    'des informations approfondies et des recommandations pour améliorer le contenu',
  wantent_7: 'décisions objectives sur le contenu et la stratégie marketing',
  wantent_8:
    "Wantent est une solution d'adaptation au marché du contenu basée sur l'IA.",
  wantent_9:
    "Wantent est une solution basée sur l'intelligence artificielle pour faire correspondre le contenu au marché. Wantent évalue l'efficacité du contenu vidéo en appliquant des technologies ML pour analyser la réponse émotionnelle et l'engagement du public.",
  wantent_10:
    "Wantent aide à définir l'adéquation au marché du contenu et à maximiser l'efficacité du contenu en",
  wantent_11: 'Configuration du scénario et recrutement des participants',
  wantent_12:
    'conception de projet personnalisée et audience dans le monde entier',
  wantent_13: 'Recueillir les réactions des téléspectateurs',
  wantent_14: 'commentaires détaillés et clairs du public',
  wantent_15: 'Analyse de la perception du contenu',
  wantent_16: 'analyse du comportement et des réactions émotionnelles par IA',
  wantent_17: "Livraison d'idées pour la conclusion finale",
  wantent_18: "recommandations d'améliorations",
  wantent_19: "Wantent maximise l'efficacité de votre contenu",
  wantent_20: 'Pré-test du concept publicitaire',
  wantent_21: 'Test de concept créatif',
  wantent_22:
    "Vérifier la cohérence de la future création avec la stratégie développée et comprendre les réactions du public cible pour d'éventuels ajustements aux concepts/messages créatifs",
  wantent_23: 'Test de visibilité des éléments de marque (post-production)',
  wantent_24:
    "Confirmation de l'efficacité de l'investissement publicitaire/parrainage",
  wantent_25: 'Tests publicitaires',
  wantent_26: 'Test A/B',
  wantent_27:
    "Comparer l'efficacité des créations et accroître l'engagement et l'attention pendant la phase de production",
  wantent_28: 'Tests publicitaires lancés',
  wantent_29:
    "Définir les performances des messages et créer des recommandations pour améliorer l'audio et les visuels",
  wantent_30: 'Solutions pour la télévision',
  wantent_31: 'Pilotes, promos, nouveaux formats, test des présentateurs TV',
  wantent_32:
    'Mener une analyse comparative pour identifier la plus engageante et la renforcer',
  wantent_33: "Tests d'émissions de télévision et de longs métrages",
  wantent_34:
    "Analyser l'implication, le niveau d'attention, la réponse émotionnelle des téléspectateurs lors du visionnage de longs contenus dans des conditions naturelles. Nous retrouvons automatiquement les pics d'audience et les moments de distraction pour évaluer et améliorer le montage vidéo",
  wantent_35: 'Tests UI/UX',
  wantent_36: "Prototypes de sites Web et tests d'applications mobiles",
  wantent_37:
    "Analyse de la réaction et des émotions des utilisateurs à l'aide de la technologie Wantent :",
  wantent_38: "niveau d'attention",
  wantent_39: 'réactions émotionnelles',
  wantent_40: 'cartes thermiques de la direction du regard des participants',
  wantent_41:
    "Analyse de l'utilisabilité du site Web et de l'application basée sur les commentaires des participants :",
  wantent_42: "Échelle d'utilisabilité du système (EUS)",
  wantent_43: "Score d'effort du client (facilité d'interaction)",
  wantent_44: 'Score de Promotion Net (SPN)',
  wantent_45: 'Conformité GDPR et confidentialité des utilisateurs',
  wantent_46:
    'Wantent est entièrement conforme aux politiques GDPR et CCPA et respecte les principes de collecte, de stockage, de traitement et de protection des données personnelles des participants',
  wantent_47: 'Nikita Lobyntsev',
  wantent_48: 'СDO, Reface (médias et divertissement)',
  wantent_49:
    "Nous expérimentons chaque jour des tonnes de nouvelles idées de contenu avec des technologies d'apprentissage automatique. Wantent nous aide à comprendre et à évaluer les risques et les avantages, à découvrir les commentaires, le comportement et la perception des utilisateurs pour obtenir la causalité des différents aspects de nos solutions.",
  wantent_50: 'Alexander Smirnov',
  wantent_51: 'co-propriétaire TABASCO (Publicité)',
  wantent_52:
    "Wantent fournit un service vraiment unique qui nous aide à connaître des détails très mineurs sur ce que les consommateurs aiment et n'aiment pas. Il n'y a aucun moyen de tricher, il n'y a aucun moyen de mentir - Wantent sait toujours la vérité, et par la présente, nous, en tant qu'experts en marketing, obtenons des directives inestimables (hahaha - nous connaissons le prix exact et il est abordable) sur la façon d'améliorer nos communications.",
  wantent_53: 'Avis',
  terms_1: 'Termes et conditions',
  terms_2: "Conditions d'utilisation",
  terms_3:
    "Veuillez lire attentivement ces conditions d'utilisation («conditions d'utilisation», «accord») avant d'utiliser le site Web",
  terms_4:
    '(«site Web») exploité par 4Service Holdings GmbH («4Service», «nous», «nous», «notre»). ',
  terms_5:
    "Conditions d'utilisation En utilisant ce site Web, vous certifiez que vous avez lu et examiné le présent accord et que vous acceptez de vous conformer à ses termes. Si vous ne souhaitez pas être lié par les termes du présent accord, il vous est conseillé de quitter le site Web en conséquence. 4Service n'accorde l'utilisation et l'accès à ce site Web, ses produits et ses services qu'à ceux qui en ont accepté les termes.",
  terms_6: 'Politique de Confidentialité',
  terms_7:
    'Avant de continuer à utiliser notre site Web, nous vous conseillons de lire notre politique de confidentialité concernant notre collecte de données utilisateur. Cela vous aidera à mieux comprendre nos pratiques.',
  terms_8: "Limite d'âge",
  terms_9:
    "Vous devez avoir au moins 16 (seize) ans avant de pouvoir utiliser ce site Web. En utilisant ce site Web, vous garantissez que vous avez au moins 16 (seize) ans et que vous pouvez légalement adhérer au présent accord. 4Service n'assume aucune responsabilité pour les responsabilités liées à la fausse déclaration d'âge.",
  terms_10: 'Propriété intellectuelle',
  terms_11:
    "Vous acceptez que tous les matériaux, produits et services fournis sur ce site Web sont la propriété de 4Service, ses sociétés affiliées, administrateurs, dirigeants, employés, agents, fournisseurs ou concédants de licence, y compris tous les droits d'auteur, secrets commerciaux, marques de commerce, brevets et autres propriétés intellectuelles. Vous acceptez également de ne pas reproduire ou redistribuer la propriété intellectuelle de 4Service de quelque manière que ce soit, y compris les enregistrements électroniques, numériques ou de nouvelles marques.",
  terms_12: 'Accéder à notre site Web',
  terms_13:
    "L'accès à notre site est autorisé sur une base temporaire, et nous nous réservons le droit de retirer ou de modifier le service que nous fournissons sur notre site sans préavis. Nous ne serons pas responsables si, pour une raison quelconque, notre site est indisponible à tout moment ou pour toute période. De temps à autre, nous pouvons restreindre l'accès à certaines parties de notre site, ou à l'intégralité de notre site, aux utilisateurs qui se sont inscrits auprès de nous. Vous êtes responsable de prendre toutes les dispositions nécessaires pour que vous ayez accès à notre site, y compris l'utilisation d'équipements compatibles avec notre site. Vous êtes également responsable de vous assurer que toutes les personnes qui accèdent à notre site via votre connexion Internet connaissent ces conditions et qu'elles les respectent.",
  terms_14: 'Loi applicable',
  terms_15:
    "En visitant ce site Web, vous acceptez que les lois de la République d'Autriche, sans égard aux principes des lois sur les conflits, régissent ces termes et conditions, ou tout litige de quelque nature que ce soit qui pourrait survenir entre 4Service et vous.",
  terms_16: 'Des disputes',
  terms_17:
    'Tout litige lié de quelque manière que ce soit à votre visite sur ce site Web sera arbitré par le tribunal de commerce de Vienne.',
  terms_18: 'Indemnité',
  terms_19:
    "Vous acceptez d'indemniser 4Service et ses sociétés affiliées et de dégager 4Service de toute responsabilité contre les réclamations et demandes légales pouvant découler de votre utilisation ou mauvaise utilisation de notre site Web. Nous nous réservons le droit de sélectionner nos propres conseillers juridiques.",
  terms_20: 'La période de stockage',
  terms_21:
    'Compte tenu des finalités du traitement, la durée de conservation des données à caractère personnel des Utilisateurs (durée de conservation) est de 24 mois à compter de la date à laquelle le consentement au traitement des données est dûment obtenu de Vous.',
  terms_22: 'Limitation de responsabilité',
  terms_23:
    "4Service n'est pas responsable des dommages qui pourraient vous survenir à la suite de votre mauvaise utilisation de notre site Web. 4Service se réserve le droit, sans préavis, d'éditer, de modifier et de changer cet accord à tout moment en mettant à jour cette publication. Votre utilisation continue du site Web maintenant, ou après la publication de tout changement ou modification, indiquera votre acceptation de ces changements ou modifications. Si une partie de cet accord est déclarée illégale, nulle ou inapplicable, cette partie sera réputée divisible et n'affectera pas la validité et l'applicabilité des autres dispositions. Les preuves éventuelles d'utilisation du site Web à des fins illégales seront fournies aux autorités chargées de l'application de la loi. Cet accord est un accord entre 4Service et l'utilisateur.",
  terms_24:
    "Veuillez adresser toutes les questions et préoccupations liées à la confidentialité/utilisation à l'adresse suivante :",
  terms_25: '4Service Holdings GmbH',
  terms_26: 'Tegetthoffstrasse 7',
  terms_27: '1010 Vienne',
  terms_28: "L'Autriche",
  terms_29: 'Avis',
  terms_30:
    'Lorsque les données personnelles que Vous nous fournissez ne sont pas inexactes alors, Vous êtes en droit de nous demander de les corriger effectivement (GDPR Article 16).',
  thank_you_1: 'Merci!',
  thank_you_2: 'Nous vous répondrons dans les 24 heures',
  thank_you_3:
    'P.S.  Nos responsables étudient déjà votre demande pour vous proposer une meilleure offre',
  thank_you_4: 'Menu',
  thank_you_5: 'femme, dactylographie',
  privacy_policy_1:
    'Formulaire de déclaration de confidentialité pour les personnes concernées (utilisateurs du site Web)',
  privacy_policy_2:
    'Ce formulaire de déclaration de confidentialité (avis de confidentialité) est destiné aux personnes physiques (personnes concernées) dont les données personnelles sont collectées par 4Service Holdings GmbH, conformément aux exigences du GDPR dans les cadres de visite du site Web.',
  privacy_policy_3:
    '(ci-après Site Web et Utilisateurs du Site Web/Utilisateurs respectivement).',
  privacy_policy_4:
    "Le formulaire de déclaration de confidentialité pour les personnes concernées (utilisateurs du site Web) de 4Service Holdings GmbH est appliqué dans toutes les sociétés du groupe 4Service, qui sont sous l'autorité légale sous la supervision ou le contrôle de 4Service Holdings GmbH.",
  privacy_policy_5: 'Contacts',
  privacy_policy_6: '4Service Holdings GmbH',
  privacy_policy_7: '(Société/nous/nous),',
  privacy_policy_8: 'Adresse:',
  privacy_policy_9: 'Tegetthoffstraße 7, 1010 Vienne, Autriche.',
  privacy_policy_10: 'E-mail:',
  privacy_policy_12: 'Qui sommes-nous ?',
  privacy_policy_13:
    "Un Individu (Vous) qui visite le Site Internet dans un but quelconque. Aux fins du présent document, vous serez également indiqué en tant qu'utilisateur.",
  privacy_policy_14: 'Données personnelles que nous collectons auprès de vous',
  privacy_policy_15:
    'Lorsque vous entrez sur notre site Web, nous vous envoyons un avis de confidentialité, dans lequel nous vous proposons de nous demander de nous donner votre consentement pour le traitement des informations suivantes :',
  privacy_policy_16:
    "Adresse IP, nom d'utilisateur, nom, adresse, numéro de téléphone (fixe ou mobile), adresse e-mail, adresse, nom de l'entreprise, pays, adresse e-mail, prénom, nom, numéro de téléphone, province, état et code postal , données d'utilisation, données sur l'interaction avec des réseaux sociaux ou des plateformes externes, informations sur l'enregistrement et l'authentification sur le site Web",
  privacy_policy_17: 'situation géographique.',
  privacy_policy_18:
    'Dans le cas où vous exprimez votre volonté et nous donnez un tel consentement, nous commençons à traiter ces informations vous concernant.',
  privacy_policy_19: 'Légalité (consentement)',
  privacy_policy_20:
    'Lorsque vous entrez sur notre site Web, nous vous envoyons un avis de confidentialité, dans lequel nous vous proposons de nous demander de nous donner votre consentement pour le traitement des informations suivantes :',
  privacy_policy_21:
    'La base légale pour le traitement des données personnelles est le consentement, que nous allons obtenir de vous en remplissant le formulaire de consentement sur le lien suivant :',
  privacy_policy_22:
    'Si vous remplissez le formulaire de consentement, cela signifie que vous comprenez et acceptez toutes les conditions spécifiées dans le présent avis de confidentialité.',
  privacy_policy_23: 'Retrait du consentement',
  privacy_policy_24:
    "Vous avez le droit de retirer à tout moment le consentement que vous nous avez fourni précédemment. Le retrait du consentement n'affecte pas la licéité du traitement fondé sur le consentement avant son retrait. Vous pouvez retirer votre consentement en nous envoyant la demande appropriée à l'adresse e-mail suivante withdraw@4service-group.com, ci-joint, le formulaire de la demande est disponible au lien suivant :",
  privacy_policy_25: "Formulaire de retrait pour l'utilisateur",
  privacy_policy_26: 'Objectifs du traitement',
  privacy_policy_27:
    'Nous traitons vos données personnelles aux fins suivantes :',
  privacy_policy_28:
    "– améliorer les services aux clients (Il permet une réponse plus efficace aux demandes des clients) ; – personnalisation de l'expérience des Utilisateurs. Cela permet de déterminer qui est le plus intéressé par les services) ; – améliorer le Site. (Il permet d'améliorer la qualité des produits et services, la commodité de leur utilisation, de développer de nouveaux services, d'améliorer nos produits et services) ; – communiquer avec l'utilisateur avec des newsletters, du matériel marketing ou promotionnel et d'autres informations qui incluent nos actualités, mises à jour, informations sur les services avec la remarque sur l'instruction comment refuser de recevoir des e-mails de suivi. – effectuer des recherches et des analyses statistiques et autres sur la base de données anonymisées. – fournir des services personnalisés à l'Utilisateur et exécuter des accords et des contrats ; – participer de l'Utilisateur à divers projets mis en œuvre par nous via le Site, en répondant aux requêtes adressées par l'Utilisateur via le Site, la recherche, la tenue des comptes et des enregistrements et la promotion des services.",
  privacy_policy_29:
    'Sujets auxquels les données personnelles peuvent être transférées',
  privacy_policy_30:
    'Lors du traitement de vos données personnelles, nous transférons vos données personnelles aux entités qui agissent en tant que sous-traitants de la Société. Les processeurs de la Société agissent exclusivement sur la base des instructions de la Société. Les détails des actions menées par les sous-traitants de la Société et la liste de ces sous-traitants peuvent être trouvés dans la Politique de confidentialité et de protection des données au lien suivant : Politique de confidentialité et de protection des données',
  privacy_policy_31:
    'Les pays vers lesquels les données personnelles peuvent être transférées',
  privacy_policy_32:
    "La Société transfère vos données personnelles sur la base d'une décision d'adéquation telle que stipulée par le GDPR et la Commission européenne. Des informations supplémentaires concernant le transfert de données personnelles aux États-Unis peuvent être trouvées dans la politique de confidentialité et de protection des données au lien suivant : Politique de confidentialité et de protection des données.",
  privacy_policy_33: 'La période de stockage',
  privacy_policy_34:
    'Compte tenu des finalités du traitement, la durée de conservation des données personnelles des Utilisateurs (durée de conservation) est de 24 mois à compter de la date à laquelle le consentement au traitement des données est dûment obtenu de la part de Vous.',
  privacy_policy_35: "Droit d'accès",
  privacy_policy_36:
    "Vous avez le droit de savoir si des données personnelles vous concernant sont traitées et 2) si tel est le cas, d'accéder à ces données avec de nombreuses stipulations supplémentaires énoncées dans l'article 15 du GDPR .",
  privacy_policy_37: 'Droit de rectification',
  privacy_policy_38:
    'Lorsque les données personnelles que Vous nous fournissez ne sont pas inexactes alors, Vous êtes en droit de nous demander de les corriger effectivement (GDPR Article 16).',
  privacy_policy_39: "Droit à l'effacement (droit à l'oubli)",
  privacy_policy_40:
    "Vous avez le droit d'obtenir de notre part l'effacement de vos données personnelles sans retard injustifié et nous aurons l'obligation d'effacer vos données personnelles sans retard injustifié lorsque les motifs énoncés dans l'article 17 du GDPR s'appliquent.",
  privacy_policy_41: 'Droit de restriction du traitement',
  privacy_policy_42:
    "Vous avez le droit de limiter le traitement de vos données personnelles avec plusieurs exceptions dans le cadre du RGPD, en particulier indiqué dans l'article 18 du RGPD.",
  privacy_policy_43:
    'Nous sommes tenus de vous informer des données collectées, de la manière dont elles sont utilisées, de la durée de leur conservation et si elles seront partagées avec des tiers. Ces informations doivent être communiquées de manière concise et dans un langage simple.',
  privacy_policy_44: 'Droit à la portabilité des données',
  privacy_policy_45:
    'Vous êtes autorisé à obtenir et à réutiliser vos données personnelles à vos propres fins dans différents services.',
  privacy_policy_46: "Droit d'opposition",
  privacy_policy_47:
    "Vous avez le droit de vous opposer au traitement des données personnelles qui sont traitées par la Société. Nous devons cesser de traiter les données personnelles à moins que nous ne démontrions des motifs légitimes impérieux pour le traitement qui prévalent sur les intérêts, les droits et les libertés de l'individu ou si le traitement est destiné à l'établissement ou à l'exercice de la défense de réclamations légales.",
  privacy_policy_48:
    "Droit de ne pas faire l'objet d'une décision fondée uniquement sur un traitement automatisé",
  privacy_policy_49:
    "Vous avez le droit de vous opposer à tout profilage automatisé qui se produit sans consentement. Par la présente, le Vous avez le droit que vos données personnelles soient traitées avec l'implication humaine.",
  privacy_policy_50: 'Réclamations',
  privacy_policy_51:
    "Dans le cas où vous souhaitez déposer une réclamation concernant la manière dont vos données personnelles sont traitées par la Société (ou par les sous-traitants, décrits ci-dessus), ou la manière dont votre réclamation a été traitée, vous avez le droit de déposer une réclamation directement auprès du l'autorité et la Société.",
  privacy_policy_52: 'Les détails de chacun de ces contacts sont :',
  privacy_policy_53: 'Autorité de surveillance:',
  privacy_policy_54: "L'autorité Autrichienne de protection des données",
  privacy_policy_55: 'Österreichische Datenschutzbehörde',
  privacy_policy_56: 'Wickenburggasse 8',
  privacy_policy_57: '1080 Vienne',
  privacy_policy_58: 'Autriche / Europe',
  privacy_policy_59: 'Entreprise:',
  privacy_policy_60: '4Service Holdings GmbH (Société/nous/nous),',
  privacy_policy_61: 'Adresse: Tegetthoffstraße 7, 1010 Vienne, Autriche.',
  privacy_policy_62: 'E-mail:',
  privacy_policy_63:
    'Politique de confidentialité et de protection des données',
  privacy_policy_64:
    'En savoir plus sur comment et pourquoi nous utilisons vos données ici: Politique de confidentialité et de protection des données',
  privacy_policy_65: 'Approbation',
  privacy_policy_66:
    "La Société a élaboré tous les documents internes pour définir les rôles du personnel concernant le traitement des données personnelles au sein de la Société, en particulier responsable de l'approbation et de la vérification de la légitimité de ce document est le directeur général.",
  contacts_1: 'Pour les clients',
  contacts_2:
    "Pour toutes sortes d'entreprises, agences d'études de marché et grandes entreprises, partenaires commerciaux et médias.",
  contacts_3: 'Tegetthoffstrasse 7, Vienne, Autriche',
  contacts_4: 'Pour les acheteurs',
  contacts_5:
    'Locaux et voyageurs, chauffeurs et étudiants, clients mystères professionnels et casaniers.',
  contacts_6: 'Contactez-nous depuis votre pays :',
  contacts_7: 'Italie',
  contacts_8: 'Etats-Unis',
  contacts_9: 'ROYAUME-UNI',
  contacts_10: 'Suisse',
  contacts_11: 'Pays-Bas',
  contacts_12: 'Slovaquie',
  contacts_13: 'Slovénie',
  contacts_14: 'Roumanie',
  contacts_15: 'AZ',
  contacts_16: 'Ukraine',
  contacts_17: 'Kazakhstan',
  contacts_18: 'Reste du monde',
  menu_1: 'CJM',
  menu_2: 'NPS',
  menu_3: 'CSI',
  menu_4: 'BHT',
  menu_5: 'Tests UX',
  menu_6: 'Recherche RH',
  menu_7: 'Recherche qualitative',
  menu_8: 'Recherche quantitative',
  menu_9: 'Achat mystère',
  menu_10: 'Voicer',
  menu_11: 'Play4Sales',
  menu_12: 'Wantent',
  menu_13: 'Suivi des tarifs',
  menu_14: 'À propos de nous',
  menu_15: 'Contacts',
  menu_16: 'Blog',
  menu_17: 'Méthodes',
  menu_18: 'Des produits',
  menu_19: 'Shoppers \n platform',
  menu_20: 'CSR',
  nps_passives: 'Passifs',
  nps_detractors: 'Détracteurs',
  nps_promoters: 'Promoteurs',
  nps_a_score: 'une note',
  area: 'Zone',
  products_tabs_1:
    "Afin d'atteindre une efficacité maximale, nous réalisons également dans des complexes:",
  products_tabs_2: 'Des produits',
  products_tabs_3:
    'La recherche qualitative consiste à collecter et à analyser des données non numériques pour comprendre des concepts, des opinions ou des expériences',
  products_tabs_4:
    "Les méthodes de recherche quantitative sont une mesure objective et une analyse numérique des données recueillies par le biais de sondages, de questionnaires et d'enquêtes",
  our_clients_1: 'Nos clients',
  preview_cases_section_1: 'Cas',
  preview_cases_section_2:
    'Accédez aux cas pour lire des articles plus intéressants',
  blog_1: 'Blog de contenu passionnant',
  blog_2: 'Aucun article disponible pour votre demande.',
  about: 'À propos',
  why_us: 'Pourquoi nous',
  about_us: 'À propos de nous',
  key_features: 'Principales caractéristiques',
  solutions: 'Solutions',
  monitoring: 'Surveillance',
  how: 'Comment',
  how_it_works: 'Comment ça fonctionne',
  when_to_use: 'Quand utiliser',
  mystery_shopping: 'Achat mystère',
  voicer: 'La voix du client',
  wantent: 'Test du contenu vidéo',
  play4sales: 'Play4Sales',
  price_monitoring: 'Suivi des tarifs',
  service_design: 'Conception de services',
  qualitative_research: 'Recherche qualitative',
  quantitative_research: 'Recherche quantitative',
  cawi: 'CAWI',
  f2f: 'F2F',
  cati: 'CATI',
  hashtag_automotive: '#Automobile',
  hashtag_retayl: '#Vente au détail',
  hashtag_all_posts: '#Tous_les messages',
  hashtag_feedback: '#Avis',
  hashtag_4Service_csr: '#4Service_CSR',
  hashtag_cases: '#Cas',
  hashtag_market_research: '#étude de marché',
  more: 'Plus',
  speak_to_an_expert: 'Parlez à un expert',
  book_consultancy: 'Commander une consultation',
  read_all: 'Lis tout',
  find_out_more: 'En savoir plus',
  address: 'Adresse',
  mon_fri: 'Lun-ven',
  phone_number: 'Numéro de téléphone',
  flag: 'drapeau',
  scroll_to_left: 'défiler vers la gauche',
  online: 'en ligne',
  tel: 'Tél',
  email: 'E-mail',
  light_it_up: 'Allumez-le',
  about_us_60: 'Alina Andreieva',
  about_us_61: 'Directeur du développement commercial',
  about_us_62: 'Julia Kravchenko',
  about_us_63: 'Chef de projet senior',
  about_us_64: 'Olga Aksonova',
  about_us_65: 'Responsable du développement commercial',
  about_us_66: 'Zhelevskiy Dmitriy',
  about_us_67: "Directeur d'exploitation",
  about_us_roles_1: 'Le management',
  about_us_roles_2: 'Équipe mondiale',
  about_us_roles_3: 'Propriétaires de produits',
  ad_testing_1: 'Tests publicitaires',
  ad_testing_2:
    'Une campagne publicitaire est un énorme investissement. Assurez-vous que vos annonces sont puissantes et offrent un retour sur investissement maximal',
  ad_testing_3: 'verrouiller',
  ad_testing_4: 'Industries que nous servons',
  ad_testing_5:
    "4Service est un holding mondial de recherche sur l'expérience client",
  ad_testing_6:
    "4Service est une recherche marketing mondiale et une holding CX. Nous offrons des services experts de collecte de données à une grande variété d'entreprises",
  ad_testing_7: 'Cash & Carry',
  ad_testing_8: 'Détail',
  ad_testing_9: 'Électronique',
  ad_testing_10: 'Luxe/Premium',
  ad_testing_11: 'Restauration & Hospitalité',
  ad_testing_12: 'Automobile',
  ad_testing_13: 'Voyages & Loisirs',
  ad_testing_14: 'Mode',
  ad_testing_15: 'Banques',
  ad_testing_16: 'Montres &  Bijoux',
  ad_testing_17: 'Ménage & Appareil',
  ad_testing_18: 'Membre Esomar',
  ad_testing_19:
    'Nous avons mené des recherches de tests publicitaires sur la publicité pour diverses entreprises dans différents domaines',
  ad_testing_20: "Vous avez besoin de tests publicitaires si vous l'êtes",
  ad_testing_21: 'Promouvoir un nouveau produit ou service',
  ad_testing_22: 'Abordant un sujet sensible',
  ad_testing_23:
    "Essayer d'atteindre un nouveau groupe démographique ou marché cible",
  ad_testing_24: 'Discuter de plusieurs options de conception différentes',
  ad_testing_25:
    "Besoin d'une preuve de concept pour les équipes de direction ou les investisseurs",
  ad_testing_26: 'Vous obtiendrez des réponses aux questions',
  ad_testing_27:
    'Quels canaux publicitaires sont les plus adaptés à ces publicités?',
  ad_testing_28:
    'Utilisons-nous les bons visuels pour faire passer le message?',
  ad_testing_29: 'Vos textes et messages publicitaires résonnent-ils?',
  ad_testing_30:
    'Quels canaux publicitaires sont les plus adaptés à ces publicités?',
  ad_testing_31: "Qu'est-ce qui attire leur attention en premier lieu?",
  ad_testing_32:
    "L'emplacement et la mise en page de l'annonce sont-ils faciles à comprendre?",
  ad_testing_33: 'Nous évaluons',
  ad_testing_34: "L'image de marque",
  ad_testing_35:
    'comment votre marque est liée à la publicité et dans quelle mesure elle restera dans les mémoires',
  ad_testing_36: 'Indicateurs clés',
  ad_testing_37: "L'intégration",
  ad_testing_38: 'Reconnaissance',
  ad_testing_39: 'Adapter',
  ad_testing_40: 'Créatif',
  ad_testing_41:
    "Comment votre publicité se démarque et attire l'attention des gens",
  ad_testing_42: 'Indicateurs clés',
  ad_testing_43: 'RECONNAISSANCE',
  ad_testing_44: 'AIMER',
  ad_testing_45: 'MTO',
  ad_testing_46: 'MOTIVATION',
  ad_testing_47: 'Expérience client, engagement émotionnel',
  ad_testing_48:
    "Grâce à l'utilisation de la technologie de l'intelligence artificielle et d'analystes experts, nous analysons les émotions réelles des gens et obtenons des informations sur leurs perceptions de la publicité",
  ad_testing_49: 'Évaluation',
  ad_testing_50: 'Mâle',
  ad_testing_51: 'Femelle',
  ad_testing_52: 'Engagement élevé',
  ad_testing_53: 'Attention',
  ad_testing_54: "Qu'est-ce que le public a aimé et rappelé?",
  ad_testing_55: 'Actrice',
  ad_testing_56: 'Égalité',
  ad_testing_57: 'Arrière-plan musical',
  ad_testing_58: 'Émotions positives',
  ad_testing_59: "Des gens de races et d'apparences différentes",
  ad_testing_60: 'Prises de vue en extérieur',
  ad_testing_61: "Qu'est-ce que le public n'a PAS aimé et rappelé?",
  ad_testing_62:
    "La voix de l'intervenant ne correspondait pas à ce qui se passait à l'écran",
  ad_testing_63: 'Faible attention au produit',
  ad_testing_64: 'Trop dynamique',
  ad_testing_65: 'Difficile à comprendre',
  ad_testing_66: 'TRP',
  ad_testing_67: 'impressions',
  ad_testing_68: 'Spectacles',
  ad_testing_69: 'Conversion',
  ad_testing_70: 'Médias',
  ad_testing_71:
    "Nous mesurons l'efficacité d'une campagne publicitaire en conjonction avec l'activité médiatique, en calculant l'impact des investissements médiatiques dans différents canaux sur les résultats surveillés.",
  ad_testing_72:
    "Nous effectuons des recherches publicitaires dans n'importe quel format, plate-forme ou canal",
  ad_testing_73: 'contenu vidéo',
  ad_testing_74: 'Extérieur',
  ad_testing_75: 'Publicités télévisées',
  ad_testing_76: 'impression',
  ad_testing_77: 'numérique',
  ad_testing_78: 'campagnes publicitaires',
  ad_testing_79: 'Affichage et publicité POS',
  ad_testing_80: 'animatique',
  ad_testing_81:
    'Avec notre aide, vous pouvez tester votre publicité à tout moment',
  ad_testing_82: 'Concept',
  ad_testing_83: 'quelles idées ont une capacité plus forte?',
  ad_testing_84: 'Production à un stade précoce',
  ad_testing_85: 'quelles animatiques entreront en production?',
  ad_testing_86: 'Production',
  ad_testing_87:
    "comment éditer une vidéo pour qu'elle puisse retenir l'attention?",
  ad_testing_88: 'Pré-média',
  ad_testing_89:
    "quels canaux de communication fonctionneront et à quel résultat peut-on s'attendre?",
  ad_testing_90: 'Post-test des annonces',
  ad_testing_91: 'Production',
  ad_testing_92:
    "ma publicité est-elle captivante ? L'efficacité de la créativité diminue-t-elle avec le temps?",
  ad_testing_93: 'Pré-média',
  ad_testing_94:
    "Quelle est l'efficacité de ma publicité en chiffres ? comment se compare-t-elle à la concurrence?",
  ad_testing_95: 'Pré-test des annonces',
  ad_testing_96:
    'Pour vous fournir des données de haute qualité, nous utilisons',
  ad_testing_97: 'Méthodologie de recherche quantitative',
  ad_testing_98: 'CAWI',
  ad_testing_99: 'Méthodologie de recherche qualitative',
  ad_testing_100: 'Groupes de discussion',
  ad_testing_101: 'IA',
  ad_testing_102: 'Wantent',
  ad_testing_103: 'Les rapports analytiques que vous recevez',
  ad_testing_104:
    "Notre équipe d'analystes, d'experts en médias et de spécialistes du marketing préparera et vous présentera un rapport sur mesure avec des recommandations détaillées",
  ad_testing_age: 'âge',
  ad_testing_ad: 'Publicité',
  automotive_industry_price_analysis_1: 'Automotive Industry Price Analysis',
  automotive_industry_price_analysis_2:
    'Competitive price analysis can help prevent lost profits, increase market share, and monitor your market position. Allow us to assist you in selecting a successful competitive strategy!',
  automotive_industry_price_analysis_3: 'Request a quote',
  automotive_industry_price_analysis_4: 'Our methodology',
  automotive_industry_price_analysis_5: 'Stylish elegant people car salon',
  automotive_industry_price_analysis_6:
    'The Mystery Shopping method is the only approach that accurately reflects a real customer experience and provides a credible overview.',
  automotive_industry_price_analysis_7:
    'We offer precise figures and dependable multilateral data',
  automotive_industry_price_analysis_8:
    'We have global coverage spanning every country in the world',
  automotive_industry_price_analysis_9:
    'Our in-house resources include mystery shoppers and auditors',
  automotive_industry_price_analysis_10: 'We validate the quality of our data',
  automotive_industry_price_analysis_11: 'Our advantages:',
  automotive_industry_price_analysis_12:
    'Price monitoring for specific competitive models (B2B and B2C)',
  automotive_industry_price_analysis_13: 'Monitoring the level of discount',
  automotive_industry_price_analysis_14:
    'Monitoring retailer stock for your brand and competitors',
  automotive_industry_price_analysis_15:
    'Monitoring e-commerce automotive prices',
  automotive_industry_price_analysis_16: 'Price communication analysis',
  automotive_industry_price_analysis_17:
    'Keeping track of retailer shipping charges',
  automotive_industry_price_analysis_18:
    'Monitoring promotions and sales at retailers',
  automotive_industry_price_analysis_19: 'Determining monthly payment amounts',
  automotive_industry_price_analysis_20: 'Calculating lease payments',
  automotive_industry_price_analysis_21:
    'Analyzing specific pricing considerations for electric vehicles (EVs)',
  automotive_industry_price_analysis_22:
    'Conducting direct-to-consumer versus retailer price comparisons',
  automotive_industry_price_analysis_23:
    'Developing subscription pricing models',
  automotive_industry_price_analysis_24:
    '4Service is a global customer experience (CX) holding company with over 20 years of experience',
  automotive_industry_price_analysis_25:
    "You'll use data on consumers' willingness to pay for different features to optimize your offer and identify the most important features for your target customers.",
  automotive_industry_price_analysis_26:
    "4Service's analysis of prices in the automotive industry includes:",
  automotive_industry_price_analysis_27:
    "Lowest prices aren't always the key to competitiveness for car brands. Let us help you create a long-term pricing strategy",
  automotive_industry_price_analysis_28:
    'of customers understand that prices are increasing across all brands',
  automotive_industry_price_analysis_29: '29',
  automotive_industry_price_analysis_30:
    'of customers said they would still purchase a car they like, even if the brand informed them of an upcoming price increase,',
  automotive_industry_price_analysis_31: '31',
  automotive_industry_price_analysis_32:
    'of people consider leasing and monthly payments to be the most preferred model for buying a car for them',
  automotive_industry_price_analysis_33: '48',
  automotive_industry_price_analysis_34:
    'of dealers mentioned the possibility of price increases during the communication/selling process',
  automotive_industry_price_analysis_35: '47.6',
  automotive_industry_price_analysis_36: 'Key figures',
  automotive_industry_price_analysis_37:
    "You'll figure out how to price your product relative to other vehicles in the lineup.",
  automotive_industry_price_analysis_38:
    "You'll use data on consumers' willingness to pay for different features to optimize your offer and identify the most important features for your target customers.",
  automotive_industry_price_analysis_39:
    'of customers said they would still purchase a car they like, even if the brand informed them of an upcoming price increase',
  automotive_industry_price_analysis_40:
    "You'll be able to determine the appropriate monthly payment and leasing rates.",
  automotive_industry_price_analysis_41:
    'What benefits for your brand or dealer will you gain:',
  automotive_industry_price_analysis_42: 'You submit an application',
  automotive_industry_price_analysis_43:
    'Our manager contacts you to determine the main objectives',
  automotive_industry_price_analysis_44: 'We conduct a marketing research',
  automotive_industry_price_analysis_45:
    'We regularly provide you with reports and recommendations in an online format',
  automotive_industry_price_analysis_46: 'Car with sale sticker',
  automotive_industry_price_analysis_47: 'We launch the project in 1 day!',
  automotive_industry_price_analysis_48:
    'We offer a comprehensive set of reports including photo reports, PPT reports, and Excel reports, segmented by the type of locality',
  retail_audit_1: 'AUDIT DE MARKETING DE TERRAIN/audit de vente au détail',
  retail_audit_2:
    'Collectez des données détaillées sur votre magasin ou votre marque, analysez les obstacles à la croissance et créez des stratégies efficaces pour les surmonter',
  retail_audit_3: 'Demander un devis',
  retail_audit_4:
    "4Service réalise des audits de magasins sur place au nom des marques pour évaluer l'efficacité de leurs stratégies promotionnelles, de leurs équipes d'exécution sur le terrain et de leurs tactiques marketing dans l'atteinte du marché cible prévu",
  retail_audit_5:
    "Nous observons méticuleusement l'exécution des campagnes promotionnelles et veillons au respect des directives promotionnelles.",
  retail_audit_6: 'Top 10',
  retail_audit_7: 'Agences CX en Europe',
  retail_audit_8: "années d'expérience",
  retail_audit_9: 'couverture dans les pays',
  retail_audit_10: '1,6 million',
  retail_audit_11: 'évaluations par an',
  retail_audit_12: 'Que suivons-nous?',
  retail_audit_13: 'Présence de matériel point de vente (POS)',
  retail_audit_14:
    'Présence et activation des ambassadeurs de marque, en veillant au respect du calendrier prévu',
  retail_audit_15:
    "Évaluation de l'implication des ambassadeurs de marque dans les campagnes de promotion",
  retail_audit_16:
    "Évaluation de l'interaction avec les consommateurs, en veillant au respect des directives promotionnelles et des messages de marque",
  retail_audit_17: 'Mesures de prévention de la fraude',
  retail_audit_18:
    "Votre entreprise peut utiliser les résultats d'un audit de marketing sur le terrain pour :",
  retail_audit_19: 'Évaluer la conformité au budget et aux processus',
  retail_audit_20: 'Comparer les résultats réels aux objectifs prévus',
  retail_audit_21: 'Améliorer la performance des campagnes marketing ',
  retail_audit_22: "Planifier stratégiquement pour l'aveni",
  retail_audit_23: 'Identifier les opportunités de réduction des coûts',
  retail_audit_24: 'Améliorer les ventes et les taux de conversion',
  retail_audit_25: 'Optimiser le retour sur investissement (ROI)',
  retail_audit_26:
    'ILS ONT CHOISI 4SERVICE COMME PARTENAIRE DE LEUR INVESTISSEMENT COMMERCIAL',
  retail_audit_27:
    "Un fabricant international de produits du tabac de premier plan s'est associé à 4Service pour améliorer ses marques et améliorer ses performances en magasin. Nos équipes ont fourni une évaluation du marketing sur le terrain et un reporting en direct de l'expérience client.",
  retail_audit_28:
    "augmentation de la précision de la planification de l'agence de marketing sur le terrain",
  retail_audit_29: 'augmentation de la diffusion du message de la marque',
  retail_audit_30:
    "augmentation de l'engagement des ambassadeurs de marque (activation des consommateurs)",
  retail_audit_31:
    'augmentation de la précision de la collecte de données et de la conformité au RGPD',
  retail_audit_32:
    'Quelles informations précieuses avons-nous tendance à trouver :',
  retail_audit_33:
    "Un manque de cohérence ou même un conflit entre les termes de l'ALD entraîne des frais non approuvés importants pour le client",
  retail_audit_34:
    'Facturation incorrecte des frais en raison de la confusion entre les types de services fournis',
  retail_audit_35:
    "Non-exécution du service sur le terrain, y compris l'affectation incorrecte du personnel à l'emplacement prévu | PDV",
  retail_audit_36:
    "Facturation sur la base d'une estimation plutôt que de données réelles et non rapprochées",
  retail_audit_37:
    'Application incorrecte des directives promotionnelles fournissant des données frauduleuses',
  retail_audit_38: 'Comment travaillons-nous ?',
  retail_audit_39:
    "Notre auditeur visite les points de vente désignés en fonction du plan d'activation de l'agence.",
  retail_audit_40:
    "Ils évaluent les indicateurs de performance clés (IPC) tels que définis dans le brief, tels que la présence du point de vente, l'engagement des ambassadeurs de marque, le respect des directives promotionnelles et les mesures de prévention de la fraude.",
  retail_audit_41: 'Validation:',
  retail_audit_42: 'Nous recueillons et consolidons les données obtenues.',
  retail_audit_43: 'Un rapport détaillé est rédigé.',
  retail_audit_44:
    "Notre équipe de validation examine minutieusement toutes les enquêtes pour en vérifier l'exactitude, y compris la conformité avec le cahier des charges du projet et tout commentaire supplémentaire.",
  retail_audit_45: 'Système de reporting en ligne :',
  retail_audit_46:
    'Les questionnaires complétés et validés sont téléchargés sur notre portail en ligne dans un délai de 36 heures.',
  retail_audit_47:
    "Les clients ont accès à un portail de compte personnel, ce qui leur permet de suivre l'avancement à tout moment.",
  retail_audit_48:
    'Un rapport final contenant les résultats et les recommandations est fourni.',
  retail_audit_49: 'Parlez à un expert',
  retail_audit_50: 'Nos points forts :',
  facility_check_audit_1: 'Facilité',
  facility_check_audit_2: "Vérifier l'audit",
  facility_check_audit_3:
    "Élevez l'expérience de marque dans les moindres détails",
  facility_check_audit_4: 'Explorez notre approche',
  facility_check_audit_5: 'À propos',
  facility_check_audit_6: 'Qui sommes nous',
  facility_check_audit_7:
    "En tant que holding mondial d'expérience client, 4Service fournit une suite complète de services pour aider les marques à créer et à offrir des expériences exceptionnelles à leurs clients.",
  facility_check_audit_8: "Notre facilité de vérification d'audit du service",
  facility_check_audit_9:
    'est conçu pour vous aider à garantir que vos emplacements physiques et la présence de votre marque dans les magasins de détail répondent aux normes de votre marque et créent une impression positive pour les clients.',
  facility_check_audit_10: 'Solutions 1',
  facility_check_audit_11: 'Audit de vérification de facilité',
  facility_check_audit_12:
    "est un examen minutieux qui évalue l'alignement de vos points de vente ou stands avec les standards de votre marque. Non seulement nous vérifions les attributs physiques, mais nous évaluons également comment ces éléments influencent la façon dont votre marque est perçue par vos précieux clients.",
  facility_check_audit_13: 'Deux solutions',
  facility_check_audit_14: 'Deux méthodes de mise en œuvre',
  facility_check_audit_15: 'Méthode',
  facility_check_audit_16:
    'Audit de vérification de facilité par des auditeurs experts',
  facility_check_audit_17: 'Validation de la vérification de facilité',
  facility_check_audit_18: 'Rapports',
  facility_check_audit_19:
    'Vous recevrez des rapports, complets avec des preuves visuelles et des statistiques, accessibles sous 48 heures',
  facility_check_audit_20:
    "Un audit de vérification de facilité bien exécuté peut offrir un certain nombre d'avantages à votre marque, notamment :",
  facility_check_audit_21: 'Conversion client améliorée:',
  facility_check_audit_22:
    "Une expérience client positive peut conduire à une augmentation des ventes et à une fidélisation des affaires. Nos audits peuvent vous aider à identifier et à traiter tous les domaines susceptibles d'avoir un impact sur la satisfaction client.",
  facility_check_audit_23: 'Image de marque améliorée:',
  facility_check_audit_24:
    'Vos emplacements physiques sont un point de contact clé pour les clients et ils peuvent jouer un rôle important dans le façonnement de votre image de marque. Nos audits peuvent vous aider à garantir que vos emplacements sont cohérents avec les valeurs de votre marque et créent une impression positive pour les clients.',
  facility_check_audit_25: 'Pourquoi nous?',
  facility_check_audit_26:
    "Pourquoi l'audit de vérification de facilité est-il important?",
  facility_check_audit_27:
    "Un audit de vérification de facilité bien exécuté peut offrir un certain nombre d'avantages à votre marque, notamment:",
  facility_check_audit_28: 'Image de marque améliorée:',
  facility_check_audit_29:
    'Vos emplacements physiques sont un point de contact clé pour les clients et ils peuvent jouer un rôle important dans le façonnement de votre image de marque. Nos audits peuvent vous aider à garantir que vos emplacements sont cohérents avec les valeurs de votre marque et créent une impression positive pour les clients.',
  facility_check_audit_30: 'Conversion client améliorée:',
  facility_check_audit_31:
    "Une expérience client positive peut conduire à une augmentation des ventes et à une fidélisation des affaires. Nos audits peuvent vous aider à identifier et à traiter tous les domaines susceptibles d'avoir un impact sur la satisfaction client.",
  facility_check_audit_32: 'Qui a besoin',
  facility_check_audit_33:
    "Qui a besoin d'un audit de vérification de facilité?",
  facility_check_audit_34:
    "Facility Check Audit est un service précieux pour toute marque disposant d'emplacements physiques et d'une présence physique. C'est particulièrement important pour les marques qui souhaitent:",
  facility_check_audit_35: 'Assurer le respect des standards de la marque:',
  facility_check_audit_36:
    "Nos audits peuvent vous aider à identifier les domaines dans lesquels vos emplacements pourraient s'écarter des normes de votre marque.",
  facility_check_audit_37: "Améliorer l'expérience client:",
  facility_check_audit_38:
    "Nos audits peuvent vous aider à identifier les opportunités d'améliorer l'expérience client sur vos sites.",
  facility_check_audit_39: 'Construire une marque plus forte:',
  facility_check_audit_40:
    'Our audits can help you ensure that your physical locations are creating a positive impression for customers.',
  facility_check_audit_41:
    'Le client mystère prend toutes les photos nécessaires à un endroit spécifié.',
  facility_check_audit_42:
    'Ensuite, le client mystère remplit un questionnaire et attache les photos.',
  facility_check_audit_43:
    'Le validateur reçoit les photos et les vérifie pour leur conformité aux normes.',
  facility_check_audit_44:
    'Audit de vérification de facilité par des auditeurs experts',
  facility_check_audit_45: 'Étape de préparation:',
  facility_check_audit_46: "Approbation collaborative du calendrier d'audit",
  facility_check_audit_47:
    "Les concessionnaires sont préparés et informés de l'audit à venir",
  facility_check_audit_48: "Formation d'auditeur:",
  facility_check_audit_49:
    'Nos formateurs 4Service expérimentés donnent aux auditeurs les instructions de la marque',
  facility_check_audit_50: "Étape d'audit de facilité:",
  facility_check_audit_51:
    "Les auditeurs, menés de lettres d'autorisation spéciales, visitent les lieux",
  facility_check_audit_52:
    'Examen approfondi de chaque point, en suivant méticuleusement les instructions',
  facility_check_audit_53:
    'Documentation visuelle, ainsi que des évaluations par rapport aux prérequis du HQ',
  facility_check_audit_54: 'Rapport PPT concluant',
  facility_check_audit_55:
    'Un rapport PowerPoint complet, expliquant les résultats',
  facility_check_audit_56:
    'Présentation immersive en ligne/hors ligne des résultats audités',
  facility_check_audit_57: 'Validation de la vérification de facilité',
  facility_check_audit_58: 'Étapes:',
  facility_check_audit_59: "Étape d'audit de la facilité:",
  facility_check_audit_60:
    "Les représentants de la marque exploitent l'application 4Service pour capturer des photos sur site",
  facility_check_audit_61: 'Respect de critères visuels et descriptifs stricts',
  facility_check_audit_62: 'Étape de validation:',
  facility_check_audit_63:
    'Des validateurs diligents examinent minutieusement chaque rapport',
  facility_check_audit_64:
    'Comparaison complète des photos avec les standards de la marque',
  facility_check_audit_65:
    'Annotations et informations intégrées au rapport pour plus de clarté',
  facility_check_audit_66:
    'Communication directe avec les auditeurs pour des clarifications, si nécessaire',
  facility_check_audit_67: 'Phase de rapport:',
  facility_check_audit_68:
    'Rapports, complets avec preuves visuelles et statistiques, accessibles dans un délai de deux jours',
  facility_check_audit_69: "Contactez-nous aujourd'hui",
  cx_solution_1: 'Nous ne trouvons pas seulement des informations,',
  cx_solution_2: 'Nous amenons votre entreprise vers de nouveaux sommets',
  cx_solution_3:
    "Des experts avec plus de 20 ans d'expérience client découvrent des solutions complexes et vous proposent un plan d'action précis qui contribue à votre croissance",
  cx_solution_4:
    'Découvrez une suite unique de services dans une solution évolutive',
  cx_solution_5: 'Solution CX tout-en-un',
  cx_solution_6: 'Test publicitaire',
  cx_solution_7: 'AI & Neuromarketing',
  cx_solution_8: 'Voix du client',
  cx_solution_9: 'Recherche documentaire',
  cx_solution_10: 'CJM',
  cx_solution_11: 'Achat mystère',
  cx_solution_12: 'Conseil CX',
  cx_solution_13: 'Écoute des réseaux sociauxg',
  cx_solution_14: 'Recherche quantitative et qualitative',
  cx_solution_15: 'Conception de services',
  cx_solution_16: 'Stratégie marketing',
  cx_solution_17: 'Tests UX/UI',
  cx_solution_18: "Informations sur l'audience",
  cx_solution_19: 'Nous sommes honorés de contribuer à votre succès',
  cx_solution_20:
    "Nous personnalisons et combinons des outils et des services en fonction de la raison d'être et des objectifs de votre entreprise pour fournir des résultats sur mesure",
  cx_solution_21: 'Recherche approfondie',
  cx_solution_22:
    'Renforcez votre prise de décision, en vous offrant un avantage concurrentiel sur un marché centré sur les données.',
  cx_solution_23:
    'La recherche qualitative consiste à collecter et analyser des données non numériques pour comprendre des concepts, des opinions ou des expériences',
  cx_solution_24:
    'Les méthodes de recherche quantitatives sont une mesure objective et une analyse numérique des données collectées via des sondages, des questionnaires et des enquêtes',
  cx_solution_25:
    "Nous utilisons un ensemble distinct d'outils en fonction de vos besoins spécifiques",
  cx_solution_26: 'Outils',
  cx_solution_27: 'CAWI',
  cx_solution_28:
    "Les entretiens Web assistés par ordinateur sont un outil essentiel dans notre arsenal, nous permettant de mener des enquêtes et de collecter des données via des plateformes en ligne avec l'aide de logiciels spécialisés, garantissant une collecte de données efficace et précise pour les besoins d'études de marché de nos clients",
  cx_solution_29: 'CATI',
  cx_solution_30:
    "Les entretiens téléphoniques assistés par ordinateur sont une méthode que nous utilisons pour mener des enquêtes et recueillir des données précieuses par téléphone, en utilisant un logiciel spécialisé pour rationaliser le processus et garantir l'exactitude de nos efforts d'études de marchés",
  cx_solution_31: 'F2F',
  cx_solution_32:
    "Le face-à-face fait partie intégrante de notre approche, facilitant les interactions directes et les engagements personnalisés avec les clients, favorisant ainsi la confiance, la compréhension et une communication efficace, essentielles à l'établissement de relations durables et au succès de l'entreprise",
  cx_solution_33: 'Bureau de recherche',
  cx_solution_34:
    'La recherche documentaire implique une collecte et une analyse complètes de données, qui constituent une étape fondamentale de notre processus de recherche pour recueillir des informations et éclairer la prise de décision stratégique pour nos clients',
  cx_solution_35: 'Analyse SWOT',
  cx_solution_36:
    "L'analyse SWOT est un outil d'évaluation stratégique qui aide nos clients à identifier les forces et les faiblesses internes, ainsi que les opportunités et les menaces externes, fournissant des informations précieuses pour éclairer la prise de décision et développer des stratégies commerciales efficaces",
  cx_solution_37: 'Recherche ethnographique',
  cx_solution_38:
    "Étudier les personnes et les cultures par l'immersion et l'observation dans leurs environnements naturels, conduisant souvent à une compréhension approfondie des comportements, des croyances et des dynamiques sociales",
  cx_solution_39: 'Entretiens approfondis',
  cx_solution_40:
    'Les entretiens approfondis sont une méthode de recherche qualitative que nous utilisons, impliquant des discussions approfondies en tête-à-tête avec les participants pour approfondir leurs perspectives, leurs expériences et leurs opinions, fournissant ainsi des informations et une compréhension riches pour les objectifs de recherche de nos clients et processus de décision',
  cx_solution_41: "Séances d'idéation",
  cx_solution_42:
    "Les séances d'idéation sont des séances de réflexion collaboratives que nous facilitons, réunissant diverses perspectives et expertises pour générer des idées et des solutions créatives pour les défis ou opportunités de nos clients, favorisant l'innovation et faisant avancer les initiatives stratégiques",
  cx_solution_43: "Personnages d'acheteur",
  cx_solution_44:
    "Les personnalités des acheteurs sont des profils détaillés que nous développons, représentant les clients cibles de nos clients sur la base de recherches et d'analyses de données, nous permettant de mieux comprendre leurs besoins, leurs préférences et leurs comportements, et d'adapter nos stratégies et nos communications pour interagir efficacement et trouver avec eux le moteur du succès de l'entreprise",
  cx_solution_45: 'Achat mystère',
  cx_solution_46:
    "L'achat mystere est un service stratégique que nous proposons, mesurant et surveillant les interactions entre un client et une entreprise au cours d'un scénario prédéfini",
  cx_solution_47: 'Accompagner la vérification',
  cx_solution_48:
    "La vérification d'accompagnement est une forme spécialisée d'achat mystère dans laquelle nos évaluateurs accompagnent les clients tout au long de leur expérience de service, fournissant des informations complètes sur tous les aspects du parcours client, permettant à nos clients d'acquérir une compréhension approfondie de leur prestation de services et d'apporter des améliorations éclairées pour améliorer la satisfaction et la fidélité globales des clients",
  cx_solution_49: 'Analyse de collecte de commentaires',
  cx_solution_50:
    "L'analyse de la collecte de commentaires est un processus critique que nous entreprenons, impliquant la collecte et l'analyse systématiques des commentaires des clients provenant de divers canaux tels que les enquêtes, les avis et les médias sociaux, nous permettant d'extraire des informations valables, d'identifier les tendances et de prendre des décisions sur les données des recommandations à nos clients pour améliorer leurs produits, leurs services et leur expérience client globale",
  cx_solution_51: 'Groupes de concentration',
  cx_solution_52:
    "Les groupes de concentration sont des sessions de recherche dynamiques que nous facilitons, réunissant un groupe diversifié de participants pour discuter en profondeur de sujets ou de produits spécifiques, nous permettant de recueillir des informations qualitatives, de découvrir des attitudes, des préférences et des perceptions, et d'explorer des idées et des concepts, éclairer en fin de compte les processus de prise de décision et de développement de stratégie de nos clients",
  cx_solution_53: '20 ans à créer des expériences client exceptionnelles',
  cx_solution_54:
    'Assurez-vous que votre message captive et résonne auprès de votre public.',
  cx_solution_55:
    'Nous nous engageons à fournir des résultats qui dépassent vos attentes',
  cx_solution_56:
    'Nous découvrons des solutions complexes qui contribuent à votre croissance',
  cx_solution_57:
    'Améliorez votre expérience client grâce à notre méthodologie ',
  cx_solution_58:
    'La cartographie du parcours client consiste à définir un public cible, à comprendre ses besoins et son environnement, puis à créer un plan pour offrir une expérience engageante',
  cx_solution_59: 'BHT',
  cx_solution_60:
    "La Recherche de Suivi de la Santé de la marque peut mesurer les performances de votre marque en termes de notoriété et d'utilisation, de positionnement et de performance de la marque",
  cx_solution_61: 'CSI',
  cx_solution_62:
    "L'Indice de Satisfaction Client est un score qui indique le degré de satisfaction de vos clients vis-à-vis des produits/services de l'entreprise.",
  cx_solution_63:
    "La surveillance des prix est le processus de collecte, de traitement et d'analyse des prix des concurrents",
  cx_solution_64: 'NPS',
  cx_solution_65:
    "Le Score Net du Promoteur est la référence en matière de mesures de l'expérience client, basé sur une question : quelle est la probabilité que vous recommandiez une organisation/un produit/un service?",
  cx_solution_66: 'Recherche RH',
  cx_solution_67:
    'La Recherche sur les Ressources Humaines est utilisée pour évaluer les pratiques et les performances RH et propose une analyse détaillée du développement et de la gestion actuels',
  cx_solution_68:
    "Les tests d'utilisabilité sont la manière dont de vraies personnes interagissent avec un site Web, une application ou un autre produit et observent leur comportement et leurs réactions",
  cx_solution_69: 'Autre',
  cx_solution_70:
    'Les experts sélectionnent la meilleure méthodologie pour votre entreprise',
  cx_solution_71: 'Commençons',
  cx_solution_72: '1 - RÉUNION DE LANCEMENT',
  cx_solution_73:
    "Lors de la réunion de lancement, nous identifions et nous concentrons sur les tâches clés. Résultat: comprendre l'objectif, les tâches de recherche, les délais, les méthodes et les domaines de responsabilité.",
  cx_solution_74: '2 - DÉVELOPPEMENT DE CONCEPT',
  cx_solution_75:
    "Le service d'analyse de 4Service regroupe les données et coordonne la structure du rapport. Nous présentons ensuite les résultats au client, complétés par des informations et des recommandations de mise en œuvre.",
  cx_solution_76: '3 - TRAVAUX SUR TERRAIN',
  cx_solution_77:
    "Pour mener la recherche, nous recrutons des participants, menons des entretiens et d'autres étapes de recherche fondamentale. Le client reçoit des entretiens audio-vidéo retranscrits, ainsi que des résumés concis.",
  cx_solution_78: '4 - ANALYTIQUE',
  cx_solution_79:
    "Le service d'analyse de 4Service regroupe les données et coordonne la structure du rapport. Nous présentons ensuite les résultats au client, complétés par des informations et des recommandations de mise en œuvre.",
  cx_solution_80: '5 - ATELIER',
  cx_solution_81:
    'Conduire une discussion avec le client concernant le plan de mise en œuvre',
  cx_solution_82: 'En savoir plus sur nos meilleures pratiques',
  cx_solution_83:
    "La Carte du Parcours Client permet un examen rapide de tous les canaux de communication, l'identification des lacunes potentielles aux points de contact cruciaux et une compréhension globale des domaines qui peuvent s'écarter du parcours prévu.",
  cx_solution_84: "Quand l'utiliser?",
  cx_solution_85:
    "Créez une campagne publicitaire personnalisée basée sur des données réelles sur les groupes d'audience",
  cx_solution_86:
    'Découvrez les déclencheurs des différents segments du public cible et répondez à leurs besoins',
  cx_solution_87:
    'Ciblez précisément chaque groupe et obtenez un maximum de conversion',
  cx_solution_88:
    "La technique d'Accompagnement de Verification permet d'évaluer les possibilités de moyens optimaux pour atteindre vos objectifs.",
  cx_solution_89: "Test d'un nouveau site Web ou produit avant le lancement",
  cx_solution_90:
    "Vérification des processus développés avant d'implémenter les modifications",
  cx_solution_91:
    'Évaluer le site Web ou le produit dans un contexte de baisse des ventes',
  cx_solution_92:
    "Lancement d'un nouveau produit adapté aux préférences du client",
  cx_solution_93:
    'Étudier les entreprises concurrentes pour assurer une position de leader sur le marché',
  cx_solution_94:
    'Nous proposons une approche globale à 360° pour créer un modèle de service orienté client.',
  cx_solution_95: 'Formation et développement du personnel',
  cx_solution_96: 'Amélioration des normes de service',
  cx_solution_97: 'Augmentation du flux de clients et des ventes',
  cx_solution_98: 'Parametres des KPI et du système de motivation du personnel',
  cx_solution_99: 'Découvrez nos réussites',
  cx_solution_100: 'Automotive',
  cx_solution_101: 'Tabac',
  cx_solution_102: 'Commerce de détail',
  cx_solution_103: 'Phase qualitative',
  cx_solution_104: 'Phase quantitative (segmentation)',
  cx_solution_105: 'Groupes de concentration',
  cx_solution_106: "Couverture de l'audience mondiale en ligne",
  cx_solution_107: 'Mélange de techniques lors des entretiens',
  cx_solution_108: 'Questions ouvertes uniquement',
  cx_solution_109: 'Assistance aux répondants 24/7',
  cx_solution_110:
    'Préservation des détails importants et une image réelle du chemin',
  cx_solution_111: 'Etude de marché',
  cx_solution_112: "Diagnostic de l'entreprise et des concurrents",
  cx_solution_113:
    'Modèles de service mis à jour et nouveaux processus de business',
  cx_solution_114: "Promotion d'un service orienté client",
  cx_solution_115:
    'Mise en œuvre des changements et contrôle du développement des stratégies de service',
  usage_attitude_1: 'Utilisation et \n Attitude (U&A)',
  usage_attitude_2:
    'Obtenir des informations complètes sur la manière dont le public cible \n perçoit, utilise et interagit avec votre marque.',
  usage_attitude_3: 'Parler à un exper',
  usage_attitude_4: 'À propos',
  usage_attitude_5:
    '<b>Explorez les habitudes des consommateurs</b> pour découvrir en profondeur leurs préférences et leurs influences',
  usage_attitude_6:
    "<b>Découvrez le 'pourquoi'</b> derrière les choix des consommateurs grâce à notre analyse détaillée",
  usage_attitude_7:
    '<b>Comprenez comment</b> les clients interagissent avec vos produits pour obtenir des informations stratégiques',
  usage_attitude_8:
    '<b>Obtenez des informations sur</b> le comportement, les habitudes et les préférences des consommateurs pour prendre des décisions informés',
  usage_attitude_9: 'Experience',
  usage_attitude_10:
    "4Service experts avec plus de 20 ans d'expérience client créent des solutions uniques qui vous aident à développer votre entreprise",
  usage_attitude_11: 'Contactez-nous',
  usage_attitude_12: 'Pour qui',
  usage_attitude_13: "Qui profite de l'utilisation et l'attitude de la marque?",
  usage_attitude_14:
    'Diverses industries et secteurs de services sont intéressés par:',
  usage_attitude_15:
    '<b>Reconnaître</b> les demandes et les problèmes des clients',
  usage_attitude_16:
    '<b>Identifier</b> les opportunités pour stimuler la consommation des clients',
  usage_attitude_17:
    '<b>Développement</b> de nouvelles catégories de produits ou stratégies marketing',
  usage_attitude_18:
    '<b>Acquérir</b> une compréhension approfondie du paysage concurrentiel',
  usage_attitude_19:
    'La méthodologie est sélectionnée individuellement en fonction de la demande du client',
  usage_attitude_20:
    "<b>La recherche qualitative</b> implique la collecte et l'analyse de données non numériques pour saisir des concepts, des opinions ou des expériences.",
  usage_attitude_21:
    "Les méthodes de <b>recherche quantitative</b> impliquent de mesurer objectivement et d'analyser numériquement les données obtenues par le biais de sondages, de questionnaires et d'enquêtes.",
  usage_attitude_22:
    '<b>Après avoir collecté les données</b>, les experts analysent les informations et tirent des conclusions pour vous fournir les meilleures recommandations.',
  usage_attitude_23: 'Qui a besoin',
  usage_attitude_24: 'Votre Histoire de Réussite Commence Ici',
  usage_attitude_25:
    '<b>Vous recevrez une recherche</b> contenant des informations précieuses qui facilitent la prise de décision stratégique, garantissant que les actions sont basées sur une compréhension approfondie du comportement des consommateurs.',
  usage_attitude_26:
    '<b>La recherche fournit une base solide pour affiner</b> et optimiser les stratégies de marque, en les alignant plus étroitement sur les préférences et les attitudes des consommateurs.',
  usage_attitude_27: 'Solutions',
  usage_attitude_28: 'Explorer de nombreuses opportunités avec nos solutions',
  usage_attitude_29: 'Préférence de produit au sein de la catégorie',
  usage_attitude_30:
    "Identifiez les principales préférences des consommateurs dans diverses catégories, notamment la nourriture, les boissons, les vêtements, l'électronique, etc.",
  usage_attitude_31: "Consommation / comportement d'usage",
  usage_attitude_32:
    "Analyser la fréquence de consommation ou d'utilisation de produits/services spécifiques, et identifier les occasions et les moments associés à leur utilisation.",
  usage_attitude_33: "Comportement d'achat",
  usage_attitude_34:
    "Examiner les canaux que les clients utilisent pour leurs achats et identifiez les sources d'informations sur lesquelles ils s'appuient.",
  usage_attitude_35: 'Motivations et besoins des consommateurs',
  usage_attitude_36:
    'Examiner les motivations et les obstacles qui influencent la consommation. Évaluer les facteurs ayant une incidence sur les décisions d’achat des consommateurs. Explorer la perception de la catégorie de produits et analyser la façon dont le prix est perçu.',
  usage_attitude_37: 'Notre approche',
  usage_attitude_38: "Étude sur l'utilisation et l'attitude de la marque",
  usage_attitude_39: 'Informations',
  usage_attitude_40:
    'Réaliser des enquêtes en utilisant à la fois des méthodes quantitatives et qualitatives',
  usage_attitude_41: 'Commencer',
  usage_attitude_42:
    'Fixer des objectifs et déterminer des méthodes en fonction des demandes individuelles',
  usage_attitude_43: 'Analyse',
  usage_attitude_44: 'Techniques sophistiquées employées par nos experts',
  usage_attitude_45:
    "Nous utilisons un ensemble distinct d'outils en fonction de vos besoins spécifiques",
  usage_attitude_46: 'СATI',
  usage_attitude_47:
    "Les Entretiens Téléphoniques Assistés par Ordinateur sont une méthode que nous utilisons pour mener des enquêtes et recueillir des données précieuses par téléphone, en utilisant un logiciel spécialisé pour rationaliser le processus et garantir l'exactitude de nos efforts d'études de marché.",
  usage_attitude_48: 'CAWI',
  usage_attitude_49:
    "Les Entretiens Web assistés par ordinateur sont un outil essentiel dans notre arsenal, nous permettant de mener des enquêtes et de collecter des données via des plateformes en ligne avec l'aide de logiciels spécialisés, garantissant une collecte de données efficace et précise pour les besoins d'études de marché de nos clients.",
  usage_attitude_50: 'CAPI',
  usage_attitude_51:
    "L'Entretien Personnel Assisté par Ordinateur est une méthode que nous utilisons pour optimiser les processus de collecte de données, rendre les entretiens plus fluides et garantir des résultats précis et pertinents pour les besoins de votre entreprise.",
  usage_attitude_52: 'Groupes de concentration',
  usage_attitude_53:
    "Les groupes de concentration sont des sessions de recherche dynamiques que nous facilitons, réunissant un groupe diversifié de participants pour discuter en profondeur de sujets ou de produits spécifiques, nous permettant de recueillir des informations qualitatives, de découvrir des attitudes, des préférences et des perceptions, et d'explorer des idées et des concepts, en fin de compte, éclairer les processus de prise de décision et de développement de stratégies de nos clients.",
  usage_attitude_54: 'F2F',
  usage_attitude_55:
    "Le Face-à-Face fait partie intégrante de notre approche, facilitant les interactions directes et les engagements personnalisés avec les clients, favorisant ainsi la confiance, la compréhension et une communication efficace, essentielles à l'établissement de relations durables et à la conduite de groupes de discussion sur la réussite commerciale.",
  usage_attitude_56: 'Recherche Ethnographique',
  usage_attitude_57:
    "Étudier les personnes et les cultures par l'immersion et l'observation dans leurs environnements naturels, conduisant souvent à une compréhension approfondie des comportements, des croyances et des dynamiques sociales.",
  parthership: 'Partenariat',
};
