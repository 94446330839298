export const uk: { [key: string]: string } = {
  subscribe_with_click: 'Підпишіться одним кліком',
  subscribe: 'Підписатися на розсилку',
  menu_21: 'Аналіз автомобільних цін',
  form_title: 'Ми сконтактуємо з вами найближчим часом',
  form_required: "Поля, зазначені *, є обов'язковими для заповнення",
  form_name_placeholder: "Ім'я",
  form_email_placeholder: 'Електронна пошта',
  form_phone_placeholder: 'Номер телефону',
  form_company_placeholder: 'Компанія',
  form_submit: 'Надіслати',
  form_1: 'Я згоден на обробку персональних даних відповідно до',
  form_2: 'політики конфіденційності',
  validation_privacy_policy: 'Будь ласка, прийміть Політику конфіденційності',
  validation_phone: 'Номер телефону недійсний',
  validation_email: 'Неправильний формат електронної пошти',
  validation_required: "Не заповнені обов'язкові поля",
  submit_error: 'Виникла помилка при збереженні форми',
  footer_1: 'Наші партнери:',
  footer_2: 'Методи',
  footer_3: 'Продукти',
  footer_4: 'Контакти:',
  footer_5: 'Вихідні дані',
  footer_6: 'Політика конфіденційності',
  about_us_1: 'Корпоративна соціальна відповідальність 4Service',
  about_us_2:
    'Від серця та душі кожного члена нашої команди. Ми плекаємо іскри доброти у кожному нашому співробітникові та прагнемо зробити світ навколо нас трохи кращим',
  about_us_3: 'Корпоративна соціальна відповідальність 4Service',
  about_us_4:
    'Від серця та душі кожного члена нашої команди. Ми плекаємо іскри доброти у кожному нашому співробітникові та прагнемо зробити світ навколо нас трохи кращим',
  about_us_5: 'Бізнес-гра «СервісМанія»',
  about_us_6: 'Наші просвітницькі ініціативи для дітей та студентів',
  about_us_7: 'Благодійність / Волонтерство',
  about_us_8:
    'Дитячі будинки, будинки для людей похилого віку та волонтерство в українській армії. Найзворушливіша частина нашої діяльності',
  about_us_9: 'Турбота про тварин',
  about_us_10:
    'Будь-хто може прийти до нас в офіс зі своїм вихованцем. Ми допомагаємо притулкам, збираємо кошти на їжу, а також шукаємо притулок для бездомних тварин.',
  about_us_11: 'Компанія 4Service',
  about_us_12:
    'Міжнародна дослідницька компанія з управління клієнтським досвідом.',
  about_us_13:
    'Ми реалізуємо комплексні проєкти для збільшення продажів та розвитку бізнесу клієнтоорієнтованих компаній.',
  about_us_14:
    '4Service — міжнародний дослідницький холдинг зі штаб-квартирою у Відні (Австрія). Компанія була заснована у 2001 році, щоб покращувати якість життя своїх клієнтів та людей, які взаємодіють з бізнесами, користуючись їхніми послугами та продуктами. Назва «Forservice» була обрана невипадково — адже компанія втілює високі стандарти обслуговування клієнтів. ',
  about_us_15:
    'Історія 4Service розпочалася у Києві (Україна), де компанія стала провідним постачальником послуг таємних покупок. Як дослідницький холдинг ми успішно працюємо у 52 країнах, відкриваючи нові можливості комплексного підходу до аналізу та покращення всього ланцюжка обслуговування клієнтів.',
  about_us_16: 'заснування задля сервісу',
  about_us_17: 'перший офіс за межами України',
  about_us_18: 'перенесення штаб-квартири до Відня',
  about_us_19: 'елітний член MSPA, у Топ-10 агентств Європи',
  about_us_20: 'відкриття офісів у 9 країнах',
  about_us_21: 'перші цифрові проєкти, Voicer',
  about_us_22: 'партнери Академії DesignThinkers',
  about_us_23: 'перші глобальні проєкти клієнтського досвіду',
  about_us_24: 'Місія',
  about_us_25:
    'Наша місія – покращувати обслуговування клієнтів по всьому світу! Нас надихають якісні зміни, які ми спостерігаємо у людях та компаніях, з якими працюємо.',
  about_us_26: '20+ років Клієнтського Досвіду',
  about_us_27: '95 країн',
  about_us_28:
    'Ми збираємо та ділимося найкращим галузевим досвідом з усього світу',
  about_us_29: 'Головний офіс у Відні',
  about_us_30:
    'країн. Ми збираємо найкращий галузевий досвід з усього світу та ділимося ним',
  about_us_31: 'рейтингів якості обслуговування',
  about_us_32:
    'Ми входимо до топ-10 європейських агентств з управління клієнтським досвідом',
  about_us_33: 'представництв та штаб-квартира в Австрії',
  about_us_34: 'активних проєктів',
  about_us_35: 'Ваше майбутнє робоче місце',
  about_us_36: 'Наші офіси',
  about_us_37:
    '4Service™ працює над створенням комфортного робочого простору для своїх співробітників. Задля своєї зручності та максимальної продуктивності кожен співробітник може сам вибрати формат роботи: в офісі, віддалений чи гібридний. Додатково компанія подбала про наявність кімнат для відпочинку, безкоштовної психологічної підтримки та доброзичливої атмосфери. А ще ми дружні не тільки до людей, а й до домашніх улюбленців.',
  about_us_38: 'Команда, яка сяє та запалює',
  about_us_39:
    'Відгуки наших клієнтів та взаємне зростання — пріоритет для нашої багатонаціональної команди. Шлях нашого клієнта під час співпраці є таким же важливим для нашого бізнесу, як запуск нових продуктів чи досягнення фінансових цілей. Ми працюємо, щоб створювати позитивний вплив на спільноти та пропонувати нашим клієнтам інноваційні рішення з думкою про людей.',
  about_us_40: 'Усе',
  about_us_41: 'Управління',
  about_us_42: 'Команда Глобал',
  about_us_43: 'Власники продукту',
  about_us_44: 'Наші партнери',
  about_us_45:
    'Реалізуємо комплексні проєкти для збільшення продажів та розвитку бізнесу клієнтоорієнтованих компаній',
  about_us_46:
    'є одним із провідних інститутів професійного навчання, орієнтованих на дизайн. Він представлений у 25 країнах. Тут навчаються, розвиваються та підтримуються творчі мультидисциплінарні команди та спільноти. Дизайн-мислення дозволяє розробляти інноваційні ідеї, пропонувати зміни та вирішувати складні проблеми.',
  about_us_47:
    'Їхня місія полягає в тому, щоб надати простір і час командам та окремим особам, щоб вони могли надихатися та кидати собі виклик, навчатися на практиці та зміцнювати свої здібності до творчого вирішення проблем, а також допомагати організаціям створювати необхідне середовище та мислення для запуску сміливих інновацій.',
  about_us_48:
    'є представником Торгової асоціації компаній, що займаються індустрією Mystery Shopping. Асоціація працює на регіональній основі по всьому світу, її відділення розташоваі в Америці, Європі, Африці, Азії та в Тихоокеанському регіоні. Компанії-члени об’днуються, щоб спільними зусиллями зміцнювати індустрію Mystery Shopping.',
  about_us_49:
    'Це некомерційна організація, що функціонує на основі членства. Її учасники утворюють співтовариство, яке переконане: аналіз даних, дослідження та ідеї можуть покращити життя суспільства, організацій та кожної людини.',
  about_us_50:
    'Штаб-квартира ESOMAR знаходиться в Амстердамі (Нідерланди). З 1947 року ESOMAR представлена у більш ніж 130 країнах завдяки компаніям-учасникам.',
  about_us_51:
    '— професійна спільнота приватних фахівців та компаній, об’єднаних метою розвитку та взаємодії у сфері контакт-центру та обслуговування клієнтів. Філософія UCCA — підвищення культури індустрії контакт-центрів, засноване на сучасних технологіях та високих стандартах.',
  about_us_52:
    'Українська асоціація контакт-центрів була заснована у 2010 році.',
  about_us_53:
    'вивчає найкращий світовий досвід у сфері освіти, щоб пристосувати та використати його на українському ринку. Саме тому Академія ДТЕК співпрацює з провідними міжнародними бізнес-школами: INSEAD, IE Business School та Stanford University.',
  about_us_54:
    'Академія ДТЕК орієнтована на розвиток клієнтоорієнтованої та інноваційної культури в D.Client School, ID School, Executive Education: Energy of Innovation, Power of Communications, цифровій програмі DX School, Effective Leader: Pre-MBA, Change Energy.',
  about_us_55: 'Сергій Пашковський',
  about_us_56: 'Вікторія Скорбота',
  about_us_57: 'Артем Фомін',
  about_us_58: 'Макс Папка',
  about_us_59: 'Менеджер з міжнародних продажів',
  about_us_59_female: 'Менеджерка з міжнародних продажів',
  bht_1: "Відстеження здоров'я",
  bht_2: 'бренду',
  bht_3: 'Бренди неймовірно сильні',
  bht_4: 'Дізнайтеся, наскільки добре працює ваш бренд загалом',
  bht_5: 'Про',
  bht_6: "Що таке відстеження здоров'я бренду (Brand Health Tracking)?",
  bht_7:
    'Це вид дослідження ринку, що проводиться з певною періодичністю (кожні два тижні, кожен місяць тощо) задля отримання інформації про статус бренду на',
  bht_8:
    'основі таких показників, як популярність, затребуваність, імідж, характеристики споживачів та ін.',
  bht_9: 'Купують бренд найчастіше',
  bht_10: 'Лояльний',
  bht_11: 'Купують бренд раз на 3 місяці',
  bht_12: 'Постійні клієнти',
  bht_13: 'Купують бренд раз на 6 місяців',
  bht_14: 'Клієнти у короткостроковій перспективі',
  bht_15: 'Купують бренд раз на рік',
  bht_16: 'Клієнти у довгостроковій перспективі',
  bht_17: 'Знайомі із брендом, але жодного разу не користувалися/не купувати',
  bht_18: 'Знайомі, але не є клієнтами',
  bht_19: 'Не ознайомлені: ніколи не чули про бренд',
  bht_20: 'Не ознайомлені',
  bht_21: 'Профіль покупця бренду',
  bht_22:
    'Оцінка послідовності зусиль, доданих у довгостроковій перспективі для створення бренду',
  bht_23: 'Вимірювання рівня знань клієнтів про бренд',
  bht_24: 'Демонстрація ефективності рекламно-інформаційних кампаній',
  bht_25:
    'Визначення впливу періоду активної реклами на рівень продажів/маркетингову діяльність конкурентів.',
  bht_26:
    'Забезпечення власника бізнесу постійним контролем у періоди безперервних ринкових змін',
  bht_27: 'Які завдання може вирішити відстеження здоров’я бренду (ВЗБ)?',
  bht_28: 'Запит на дослідження',
  bht_29: 'Завдання для вирішення',
  bht_30: 'Наприклад,',
  bht_31:
    'якщо компанії потрібен лише один сеанс ВЗБ на квартал під час вимірювання часток ринку, опитування респондентів слід проводити частіше, вимірюючи впізнаваність бренду з реклами серед клієнтів.',
  bht_32: "CATI (комп'ютерне телефонне інтерв'ю)",
  bht_33: 'Інтерв’ю віч-на-віч',
  bht_53: "Інтерв'ю",
  bht_34: "СAWI (інтернет-інтерв'ю за допомогою комптютера)",
  bht_35: 'Завдання для вирішення',
  bht_36: 'Збираючи інформацію',
  bht_37:
    'за допомогою різних методів, ВЗБ зазвичай ділиться на три етапи — залежно від цільової аудиторії та складності її охоплення:',
  bht_38:
    'Ми використовуємо як традиційні, так й інноваційні методи збору даних.',
  bht_39:
    'Ми працюємо з двома моделями: одна з простим набором показників, а інша націлена на складніші галузі — такі, як харчова промисловість та сфера послуг. Більш складна модель передбачає поглиблене дослідження, що включає до 10 розрахункових показників.',
  bht_40: 'Ми пропонуємо',
  bht_41: 'контроль вихідних даних',
  bht_42: 'Ми покриваємо',
  bht_43: 'всю країну',
  bht_44: 'Ми пропонуємо рішення',
  bht_45:
    'для нединамічних ринків/ринків з обмеженою кількістю гравців (Brand Point Analyzer)',
  bht_46: 'Чому ми?',
  bht_47: 'Чому варто обрати саме нас?',
  bht_50: 'Слабкий результат',
  bht_51: 'Гарний результат',
  bht_52: 'Середній результат',
  cases_1:
    'Як підвищити якість обслуговування клієнтів при покупці електромобілів в автосалонах Hyundai?',
  cases_2: 'Карта Шляху клієнта',
  cases_3: 'Формування ідей',
  cases_4: 'Якісні дослідження',
  cases_5:
    "Що потрібно змінити в магазинах (як у процесі обслуговування, так і в інтер'єрі, у сприйнятті бренду), щоб викликати у покупців інтерес до бренду Oppo?",
  cases_6: 'Онлайн Карта Шляху Клієнта',
  cases_7: 'Тестування інтерфейсу користувача',
  cases_8: "Інтерв'ю",
  cases_9:
    'Що потрібно змінити в магазинах, щоб викликати інтерес покупців до бренду Oppo?',
  cases_10: 'Якісна частина',
  cases_11: 'Формування ідей',
  cases_12: 'Кількісна частина',
  cases_13: 'Карта Шляху Клієнта',
  cases_14: 'Кейси',
  cases_15: 'Усе',
  cases_16: 'Давайте виконаємо Ваш чудовий кейс разом',
  csi_1: 'Індекс',
  csi_2: 'задоволеності клієнта',
  csi_3: 'Визначаємо причини задоволеності',
  csi_4: 'та незадоволеності споживачів',
  csi_5: 'Дослідження задоволеності клієнтів',
  csi_6:
    'це визначення чинників, причин і мотивів, що впливають на рішення клієнта про купівлю, і навіть на поведінку цільової аудиторії.',
  csi_7: 'Опитування задоволеності клієнтів',
  csi_8:
    'показує, наскільки клієнт задоволений чи не задоволений конкретним аспектом послуги чи продуктом загалом',
  csi_9: 'Опитування задоволеності',
  csi_10:
    'проводиться серед існуючих клієнтів компанії та користувачів певних продуктів/послуг.',
  csi_11: 'Що таке Індекс задоволеності клієнта (CSI)?',
  csi_12:
    'CSI вимірює ступінь, у якому були задоволені очікування клієнтів щодо купівлі продукту чи послуги, що надаються вашою компанією.',
  csi_13: 'Щаслива людина',
  csi_14: 'Про',
  csi_15: 'Визначити будь-які приховані фактори, ',
  csi_16: 'які можуть вплинути на задоволеність клієнтів',
  csi_17: 'Визначити причини',
  csi_18: 'будь-якого зростання/ падіння попиту на товари та послуги',
  csi_19: 'Оптимізувати',
  csi_20: 'ділові процеси',
  csi_21: 'Адаптувати їх',
  csi_22: 'під потреби сучасного споживача',
  csi_23: 'Сформувати ефективне рекламне повідомлення',
  csi_24: 'та позиціонувати продукт під запит клієнта',
  csi_25: 'Завдання для вирішення',
  csi_26: 'Які завдання можуть вирішити опитування задоволеності клієнтів',
  csi_27: 'Чоловік з телефоном',
  csi_28: 'Ми будуємо математичну модель',
  csi_29:
    'факторів, які можуть вплинути на задоволеність/лояльність клієнтів (регресійний аналіз)',
  csi_30: 'Оптимізуємо',
  csi_31: 'бізнес-процеси компанії',
  csi_32: 'Ми будуємо розрахунковий індекс задоволеності клієнтів',
  csi_33: '(CSI) і порівнюємо його з декларативним',
  csi_34: 'Ми проводимо дослідження',
  csi_35: 'для сегментів B2B та B2C.',
  csi_36: 'Наші анкети розроблені командою',
  csi_37: 'аналітиків спеціально під індивідуальні проєкти наших клієнтів',
  csi_38: 'Чому ми',
  csi_39: 'Чому варто вибрати саме нас?',
  csi_40: 'Методи збору інформації:',
  main_1:
    'бізнесів покращують свої фінансові показники, вивчаючи досвід своїх клієнтів',
  main_2:
    'Консультації та послуги у сфері клієнтського досвіду, погляд 360° на ваш бізнес',
  main_3:
    'Відкрийте для себе наші рішення та інструменти, які допоможуть реалізувати ваші бізнес-завдання',
  main_4: 'Продукти',
  main_5:
    'Якісне дослідження - це збір та аналіз нечислових даних для розуміння концепцій, думок або досвіду.',
  main_6:
    "Кількісні методи дослідження - це об'єктивний вимір та числовий аналіз даних, зібраних за допомогою опитувань, анкет та голосувань.",
  main_7:
    'Таємний покупець — це метод дослідження ринку для вимірювання та моніторингу взаємодії між покупцем та компанією чи організацією в рамках наперед визначеного сценарію.',
  main_8:
    "Система управління зворотним зв'язком. Голос вашого клієнта в одній системі - Системі управління зворотним зв'язком зі споживачем",
  main_9:
    'Платформа аналізу контенту на основі штучного інтелекту, що надає інформацію від аудиторії',
  main_10:
    'Програмне забезпечення для керування продуктивністю. Додаток у формі гри для збільшення продажів',
  main_11:
    'Моніторинг цін - це процес збирання, обробки та аналізу цін конкурентів.',
  main_12:
    'Сервіс-дизайн — це те, як проводячи час із людьми, які користуватимуться продуктом чи послугою, зрозуміти їхні потреби та бажання.',
  main_13:
    'Наша аналітична команда застосовує новітні та фундаментальні підходи, ефективність яких підтверджена досвідом багатьох клієнтів.',
  main_14: 'Методології',
  main_15: 'Карта шляху клієнта',
  main_16:
    'Карта Шляху Клієнта (CJM) – це визначення цільової аудиторії, розуміння її потреб та середовища, а потім створення плану для залучення споживачів та їхньої успішної взаємодії з вашим брендом.',
  main_17: 'Карта Шляху Клієнта CJM',
  main_18: 'Індекс Споживчої Лояльності (NPS)',
  main_19:
    'Net Promoter Score (NPS) — це золотий стандарт якості обслуговування клієнтів, заснований на одному питанні: наскільки ймовірно, що ви порекомендуєте організацію/ продукт/ послугу?',
  main_20: 'Індекс Споживчої Лояльності NPS',
  main_21: "Відстеження Здоров'я Бренду BHT",
  main_22:
    'Дослідження Brand Health Tracking (BHT) може виміряти, як ваш бренд працює з погляду впізнаваності та використання, позиціонування бренду та ефективності бренду.',
  main_23: "Відстеження Здоров'я Бренду BHT",
  main_24: 'Дослідження Людських Ресурсів',
  main_25:
    'Дослідження людських ресурсів використовується для оцінки напрацювань та продуктивності відділу кадрів та пропонує докладний аналіз поточного розвитку та управління.',
  main_26: 'HR-дослідження',
  main_27: 'Індекс задоволеності клієнтів',
  main_28:
    'Дослідження людських ресурсів використовується для оцінки напрацювань та продуктивності HR та пропонує докладний аналіз поточного розвитку та управління.',
  main_29: 'Індекс задоволеності клієнтів CSI',
  main_30: 'UX-тестування',
  main_31:
    'Тестування зручності використання (UX) – це перевірка того, як реальні люди взаємодіють із веб-сайтом, програмою або іншим продуктом; спостереження за поведінкою користувачів та його реакцією на продукт.',
  main_32: 'Чому ми',
  main_33:
    'Наша місія — допомагати компаніям створювати позитивний клієнтський досвід.',
  main_34: 'Зосередженість на якості',
  main_35: 'Ми контролюємо якість',
  main_36: 'даних дослідження. Усі набори даних є доступними для клієнтів',
  main_37: 'Економічна ефективність',
  main_38: 'Наш досвід, ресурси та оптимізація',
  main_39: 'бізнес-процесів — це те, що заощадить ваші гроші та час',
  main_40: 'Розумні рішення',
  main_41: 'Ми використовуємо різні техніки',
  main_42: 'та інструменти, щоб вирішувати конкретні проблеми',
  main_52: 'Дрібнооптова торгівля',
  main_53: 'Електроніка',
  main_54: 'Люкс/ && Преміум',
  main_55: 'Ресторани та готелі',
  main_56: 'Роздрібна торгівля',
  main_57: 'Автомобільний',
  main_58: 'Мода',
  main_59: 'Банки',
  main_60: 'Подорожі та відпочинок',
  main_61: 'Годинники та прикраси',
  main_62: 'Побутова техніка',
  main_63: 'Блог',
  main_64: 'Перейдіть до блогу, щоб прочитати більше цікавих матеріалів',
  main_65: 'Аудит роздрібної торгівлі',
  main_66:
    'Збирайте докладні дані про свій магазин або бренд, аналізуйте перешкоди росту та створюйте ефективні стратегії їх подолання',
  banner_1:
    'СX консалтинг та послуги, які дають змогу побачити ваш бізнес на 360°',
  banner_2: 'Будьте в курсі останніх досліджень!',
  banner_3:
    'Я даю згоду на обробку персональних даних відповідно до Політики конфіденційності',
  banner_4: '*Оберіть ринок по якому б ви хотіли отримувати інформацію',
  banner_5: 'Дякуємо за вашу підписку!',
  banner_6: 'Ми поділимося з вами лише найцікавішим контентом!',
  banner_7: 'Надіслати',
  banner_8: 'Україна',
  banner_9: 'Казахстан',
  cjm_1: 'Карта',
  cjm_2: 'шляху клієнта',
  cjm_3: 'Увійдіть у світ клієнтів та розділіть їхній досвід',
  cjm_4: 'Що таке карта шляху клієнта (Customer Journey Map)?',
  cjm_5:
    'CJM є візуалізованою історією взаємодії споживача з продуктом, послугою, компанією або брендами — різними каналами та протягом певного періоду часу.',
  cjm_6:
    "Це дозволяє компаніям об'єктивно аналізувати досвід взаємодії з клієнтами, визначати та усувати бар'єри, що виникають, і пропонувати рекомендації щодо покращення продукту.",
  cjm_7: 'Визначення ЦА, сегментація',
  cjm_8: 'Визначення етапів взаємодії із клієнтом',
  cjm_9: 'Визначення основних каналів взаємодії з клієнтами',
  cjm_10: 'Дослідження клієнтського досвіду',
  cjm_11: "Аналіз бар'єрів та гіпотеза оптимізації",
  cjm_12: 'Виконання та перевірка гіпотез',
  cjm_13: 'Розробка Карти шляху клієнта складається з наступних кроків:',
  cjm_14: 'Карта шляху клієнта продемонструє:',
  cjm_15:
    'CJM надає візуалізовану історію взаємодії споживача з продуктом, послугою, компанією або брендом різними каналами та протягом певного періоду часу.',
  cjm_16: 'Ми забезпечуємо командну роботу',
  cjm_17: 'між аналітиками та дизайнерами',
  cjm_18: 'Ми пропонуємо індивідуальний',
  cjm_19: 'підхід до кожного клієнта',
  cjm_20: 'Ми маємо інструменти для збільшення',
  cjm_21:
    'задоволеності клієнтів послугою/товаром та, зрештою, підвищення їхньої лояльності до компанії.',
  cjm_22: 'Ми гарантуємо',
  cjm_23: 'оптимізацію шляху споживача та облік бізнес-процесів компанії',
  cjm_24: 'Ми пропонуємо нашим клієнтам',
  cjm_25:
    "можливість використання комбінованих підходів: CATI, CAWI, фокус-групи, глибинні інтерв'ю, експертні інтерв'ю, етнографію та ін.",
  cjm_26: 'Чому варто вибрати саме нас?',
  methods_hr_1: 'Дослідження Управління Персоналом',
  methods_hr_2:
    "Отримайте об'єктивну інформацію про свою команду та дізнайтесь, як підвищити ефективність роботи з персоналом.",
  methods_hr_3: 'Декілька причин, з яких вам знадобиться HR-дослідження',
  methods_hr_4: 'опитаних співробітників',
  methods_hr_5: 'реалізовано HR проєктів',
  methods_hr_6: 'проведено навчальних семінарів для персоналу',
  methods_hr_7:
    'Провідний європейський холдинг з клієнтського досвіду та досліджень співробітників',
  methods_hr_8: 'HR-дослідження 4Service у цифрах',
  methods_hr_9:
    "Для виявлення проблем та слабких місць на кожному етапі життя співробітників вашої компанії: пошук/набір/адаптація/кар'єра розвиток/переатестація",
  methods_hr_10: 'Щоб знати, які випадки заслуговують на надбавки та бонуси',
  methods_hr_11:
    'Вам потрібно прогнозувати чи пропонувати рішення незадоволеності співробітників',
  methods_hr_12:
    'Завдяки дослідженням у галузі управління персоналом, можливі нові та поточні програми, практики та методи оцінки політики у галузі управління персоналом.',
  methods_hr_13: "Для покращення психічного здоров'я співробітників компанії",
  methods_hr_14:
    'Якщо ви хочете підвищити продуктивність за рахунок цілеспрямованого підходу',
  methods_hr_15:
    'Якщо у вас є міжнародна та/або віддалена команда, і вам потрібно нею керувати',
  methods_hr_16:
    'Зрозуміти, наскільки привабливим є ваш бренд роботодавця для потенційних співробітників',
  methods_hr_17: 'Задоволеність співробітників',
  methods_hr_18: 'Дослідження HR-брендингу',
  methods_hr_19: 'Культурна різноманітність',
  methods_hr_20: 'Плани винагород та заробітної плати',
  methods_hr_21: 'Залучення та лояльність команди',
  methods_hr_22: 'Співробітники захищених категорій',
  methods_hr_23: 'Напрямки досліджень',
  methods_hr_24: 'Рішення 1',
  methods_hr_25:
    'eNPS, Задоволеність співробітників, Лояльність співробітників',
  methods_hr_26: 'Що ми вимірюємо:',
  methods_hr_27: 'Прихильність:',
  methods_hr_28:
    'лояльність співробітників, прогнозування майбутньої поведінки, визначення ваги факторів, що впливають на лояльність',
  methods_hr_29: 'Залученість:',
  methods_hr_30:
    'залученість співробітників до роботи, зацікавленість у результатах та досягненнях',
  methods_hr_31: 'Взаємодія:',
  methods_hr_32: 'визначення цінностей співробітників та цінностей компанії',
  methods_hr_33: 'Довіра 360:',
  methods_hr_34:
    'довіра до керівництва, колег, підлеглих, довіра до стратегій та політики роботодавця',
  methods_hr_35: 'Задоволеність:',
  methods_hr_36:
    'визначення унікальних факторів для кожної компанії; оцінка задоволеності за кожним ключовим фактором, що впливає на лояльність.',
  methods_hr_37: 'Рішення 2',
  methods_hr_38: 'Бренд роботодавця',
  methods_hr_39: 'Внутрішній аналіз бренду',
  methods_hr_40:
    'роботодавця (опитування співробітників, акціонерів, внутрішніх рекрутерів та HR-фахівців)',
  methods_hr_41: 'Зовнішній аналіз роботодавця',
  methods_hr_42:
    '(Аналіз EVP у порівнянні з конкурентами, опитування зовнішніх рекрутерів, опитування претендентів на посади)',
  methods_hr_43: 'Аналіз впізнаваності та репутації бренду',
  methods_hr_44: 'аналіз',
  methods_hr_45: 'Аналіз присутності роботодавця',
  methods_hr_47: 'Збір відгуків про роботодавця',
  methods_hr_48: 'збори',
  methods_hr_49: 'Конкурентний бенчмаркінг',
  methods_hr_50: 'на таких ключових факторах, як',
  methods_hr_51: 'Зарплата,',
  methods_hr_52: 'робоче середовище, атмосфера, характер роботи',
  methods_hr_53: 'Аналіз потреб та очікувань',
  methods_hr_54: 'претендентів',
  methods_hr_55: "Глибинні інтерв'ю:",
  methods_hr_56: 'використовуються кабінетні та кількісні дослідження',
  methods_hr_57: 'Рішення 3',
  methods_hr_58: 'Карта шляху співробітника/ Таємний співробітник',
  methods_hr_59: 'Побудова шляху пошуку роботи, визначення',
  methods_hr_60:
    'основних етапів пошуку, каналів комунікації, критеріїв відбору та оцінки претендентів',
  methods_hr_61: 'Контроль усіх етапів',
  methods_hr_62:
    "та каналів зв'язку за допомогою таємних кандидатів (просто запис на співбесіду, проходження співбесіди, стажування в компанії)",
  methods_hr_63: 'Визначення',
  methods_hr_64: 'слабких місць у процесі найму та адаптації',
  methods_hr_65: 'Рекомендації щодо покращення,',
  methods_hr_66: 'порівняння з лідерами галузі',
  mystery_shopping_1: 'Таємний',
  mystery_shopping_2: 'Покупець',
  mystery_shopping_3:
    'Оцініть реальними клієнтами послуги, які надає ваша компанія',
  mystery_shopping_4:
    'Перевірте та визначте стандарти, як вашому персоналу стати ближче до клієнта',
  mystery_shopping_5:
    'Ми є елітним членом професійної провідної асоціації постачальників таємних покупок MSPA Europe.',
  mystery_shopping_6: 'досвіду',
  mystery_shopping_7: 'відвідувань щомісяця',
  mystery_shopping_8: 'таємних покупців по всьому світу',
  mystery_shopping_9: 'активних проєктів',
  mystery_shopping_10: 'офісів, штаб-квартира в Австрії',
  mystery_shopping_11: 'постачальників послуги таємного покупця у Європі',
  mystery_shopping_12: 'рейтингів якості обслуговування',
  mystery_shopping_13: 'Анкета',
  mystery_shopping_14: 'Програмне забезпечення',
  mystery_shopping_15: 'Профіль покупця',
  mystery_shopping_16: 'Твердження та заперечення',
  mystery_shopping_17: 'Аналітика',
  mystery_shopping_18:
    'Завдяки цим 5 крокам наша компанія 4Service гарантує якість послуги таємного покупця.',
  mystery_shopping_19: 'Програма',
  mystery_shopping_20: '5 елементів програми «Таємний покупець» від 4Service',
  mystery_shopping_21: 'Анкета:',
  mystery_shopping_22: 'суть вашої програми',
  mystery_shopping_23:
    'Ця частина програми доступна для перегляду всіма зацікавленими сторонами',
  mystery_shopping_24: 'Для топ-менеджерів.',
  mystery_shopping_25: 'Показати всі важливі кроки та деталі процесу',
  mystery_shopping_26: 'Для співробітників.',
  mystery_shopping_27: 'Відобразити те, що роботодавці очікують від них',
  mystery_shopping_28: 'Для таємних покупців.',
  mystery_shopping_29:
    'Ясність і структуризація, щоб уникнути плутанини та упередженості',
  mystery_shopping_30: 'Програмне забезпечення Shopmetrics:',
  mystery_shopping_31: 'зручне та інноваційне',
  mystery_shopping_32: 'Переваги:',
  mystery_shopping_33: 'Інтегрується',
  mystery_shopping_34: 'з вашими бізнес-процесами та програмним забезпеченням',
  mystery_shopping_35: 'Регламент GDPR',
  mystery_shopping_36: 'Інструментарій керування конфіденційністю',
  mystery_shopping_37: 'Детальний',
  mystery_shopping_38: 'Пакет дотримання правових норм',
  mystery_shopping_39: 'Автоматизована',
  mystery_shopping_40: 'побудова звітів',
  mystery_shopping_41: 'Управління',
  mystery_shopping_42: 'рівнів доступу',
  mystery_shopping_43: 'Більшість анкет',
  mystery_shopping_44: 'виконуються протягом години',
  mystery_shopping_45: 'Профіль покупця:',
  mystery_shopping_46: '200 000+ таємних покупців у всьому світі',
  mystery_shopping_47:
    'Основні критерії: максимальна близькість до вашої цільової аудиторії, наявність попереднього досвіду роботи з вашою компанією чи конкурентами',
  mystery_shopping_48: 'Навчання покупців:',
  mystery_shopping_49: 'Вступний тренувальний тест',
  mystery_shopping_50: 'Скайп/телефонний дзвінок',
  mystery_shopping_51: 'Оціночний тест (за бажанням)',
  mystery_shopping_52: 'Твердження та заперечення',
  mystery_shopping_53: 'Система',
  mystery_shopping_54: 'тверджень',
  mystery_shopping_55: '100% звітів',
  mystery_shopping_56: 'перевіряються групою валідаторів',
  mystery_shopping_57: 'Додаткові дзвінки',
  mystery_shopping_58: "з метою роз'яснення",
  mystery_shopping_59: 'Аудіозаписи,',
  mystery_shopping_60: 'щоб після візиту відзначити найважливіші моменти',
  mystery_shopping_61: 'Система профілактики',
  mystery_shopping_62: 'шахрайства співробітників',
  mystery_shopping_63: 'Система',
  mystery_shopping_64: 'заперечень',
  mystery_shopping_65: 'Будь-яка оцінка покупця',
  mystery_shopping_66:
    'може бути заперечена співробітником безпосередньо на сайті',
  mystery_shopping_67: 'Кожне заперечення',
  mystery_shopping_68:
    'розглядається та на нього відповідає місцева група валідаторів',
  mystery_shopping_69: 'Ви можете переглянути статистику',
  mystery_shopping_70: 'заперечень',
  mystery_shopping_71: 'Аналітика.',
  mystery_shopping_72: 'Звіт подається особисто і містить:',
  mystery_shopping_73: 'Больові',
  mystery_shopping_74: 'точки та проблеми компанії',
  mystery_shopping_75: 'Динаміку',
  mystery_shopping_76: 'за підрозділами та відділами',
  mystery_shopping_77: 'Кореляцію',
  mystery_shopping_78: 'між дотриманням стандартів та задоволеністю клієнтів',
  mystery_shopping_79: 'Конкурентний',
  mystery_shopping_80: 'бенчмаркінг',
  mystery_shopping_81: 'Практичні',
  mystery_shopping_82: 'рекомендації щодо покращення сервісу',
  mystery_shopping_83: 'Що нас відрізняє?',
  mystery_shopping_84: 'Проводимо сеанси генерації ідей',
  mystery_shopping_85:
    'Ми не лише видаємо рекомендації, що робити, а й разом із вами шукаємо практичні шляхи вирішення сервісних завдань.',
  mystery_shopping_86:
    'Сеанси формування ідей це потужний інструмент, який допомагає нам:',
  mystery_shopping_87: 'Розробити напрямки покращення продукту чи послуги',
  mystery_shopping_88:
    'Вивчити нові можливості потоку доходів та бізнес-стратегій',
  mystery_shopping_89: 'Знайти рішення складних клієнтоорієнтованих завдань',
  mystery_shopping_90: 'Перетворити больові точки клієнтів на принаду',
  mystery_shopping_91: "Проводимо відеоінтерв'ю",
  mystery_shopping_92:
    'Ми не просто збираємо анкети – ми також отримуємо живі коментарі від покупців',
  mystery_shopping_93: 'Ми робимо це для того, щоб:',
  mystery_shopping_94:
    "Наші фасилітатори проведуть глибинні інтерв’ю та нададуть ефективний зворотній зв'язок",
  mystery_shopping_95: 'Погляньте на обслуговування з точки зору клієнта',
  mystery_shopping_96: 'Поліпшіть свою контент-маркетингову стратегію',
  mystery_shopping_97: 'Прогнозуйте поведінку клієнтів',
  mystery_shopping_98: 'Ми розробляємо Карту шляху клієнта',
  mystery_shopping_99:
    'Створення Карти шляху клієнта допомагає нам стати на місце споживачів, щоб зрозуміти їхні потреби та проблеми.',
  mystery_shopping_100: 'Створення шляху клієнта - найкращий спосіб, щоб',
  mystery_shopping_101: 'Розглянути досвід із точки зору споживача',
  mystery_shopping_102: 'Поліпшити свою стратегію контент-маркетингу',
  mystery_shopping_103: 'Прогнозувати поведінку клієнтів',
  mystery_shopping_104: 'Виявити прогалини в обслуговуванні чи комунікаціях',
  mystery_shopping_105: 'Ми пропонуємо різні види таємних покупок',
  mystery_shopping_106: 'Типи таємних покупок',
  mystery_shopping_107: 'Таємний телефонуючий',
  mystery_shopping_108:
    'Телефонує вашим керуючим та/або в кол-центр для визначення якості послуг, що надаються по телефону',
  mystery_shopping_109: 'Люксовий таємний шопінг',
  mystery_shopping_110:
    'Перевірка сервісу у люксових закладах спеціальною категорією таємних покупців',
  mystery_shopping_111: 'Конкурентні візити',
  mystery_shopping_112:
    'Найпростіший спосіб зрозуміти, як працює ваш конкурент',
  mystery_shopping_113: 'Таємний співробітник',
  mystery_shopping_114:
    'Допоможе вам зрозуміти весь шлях вашого співробітника (найм, адаптація, офісні процеси тощо)',
  mystery_shopping_115: 'Мотиваційні візити',
  mystery_shopping_116:
    'Корисний інструмент для стимулювання пропозиції додаткових послуг та/або товарів, продажу конкретних товарів',
  mystery_shopping_117: 'Таємний покупець онлайн',
  mystery_shopping_118:
    'Імітує та визначає якість обслуговування клієнтів у вашому інтернет-магазині, на веб-сайті або у додатку.',
  mystery_shopping_119: 'Перевірка продажів та рекламних акцій',
  mystery_shopping_120:
    'Допомагає визначити, як рекламні акції відображаються у всіх місцях і наскільки ефективно вони працюють.',
  mystery_shopping_121: 'Покупка та повторні візити',
  mystery_shopping_122:
    'Допомагає відобразити процес від покупки до повернення, щоб поліпшити якість обслуговування клієнтів.',
  mystery_shopping_123: '20+ років',
  nps_1: 'Індекс',
  nps_2: 'Споживчої Лояльності NPS',
  nps_3: 'Вимірює, відстежує та підвищує лояльність клієнтів',
  nps_4: 'Вони відповідають із оцінкою від 0 до 6.',
  nps_5:
    'Можливо, у них був поганий досвід, тож вони навряд чи будуть купувати у вас знову і можуть навіть відмовити інших від покупки у вас.',
  nps_6: 'Це респонденти, які обрали оцінку від 7 до 8 балів.',
  nps_7:
    'Вони задоволені вашим сервісом, але не настільки, щоб вважатися промоутерами.',
  nps_8: 'Це респонденти, які оцінюють ваш бізнес на 9 чи 10 балів.',
  nps_9:
    'Це означає, що вони порекомендують вас другу чи колезі та будуть висловлюватися на вашу користь.',
  nps_10: 'Що таке NPS?',
  nps_11: 'Він вимірює сприйняття клієнтів на основі одного простого питання:',
  nps_12:
    'Наскільки можливо, що ви порекомендуєте [Організацію/ Продукт/Послугу] другу чи колезі?',
  nps_13: 'Чи потрібно це моїй компанії?',
  nps_14: 'NPS — ваше стійке зростання',
  nps_15: 'Зрозуміти динаміку лояльності клієнтів',
  nps_16: 'Мотивувати свій персонал',
  nps_17: 'Дізнатися ставлення до різних продуктів',
  nps_18: 'Порівняти, як споживачі ставляться до вас та ваших конкурентів',
  nps_19: 'Що я можу зробити з NPS?',
  nps_20: 'Для якого бізнесу є актуальним NPS?',
  nps_21: 'Методи збирання інформації:',
  nps_22: 'Наші рекомендації під час роботи з NPS',
  nps_23:
    'Це означає, що ми розробляємо рекомендації, які допоможуть перетворити недоброзичливих на промоутерів.',
  nps_24: 'Працюємо за методом «замкнутого циклу».',
  nps_25:
    'NPS – це спостережне дослідження, в якому ми відстежуємо зміни лояльності клієнтів.',
  nps_26: 'Слідкуємо за динамікою',
  nps_27:
    'Ми пропонуємо завжди оцінювати конкурентів у вашій ніші для більш глибокого аналізу',
  nps_28: 'Оцінка конкурентів',
  price_monitoring_1: 'Моніторинг цін',
  price_monitoring_2: 'та рітейл-моніторинг',
  price_monitoring_3:
    'Збільшує потенціал продажу та оптимізує цінову політику за рахунок моніторингу цін конкурентів',
  price_monitoring_4:
    '4Service більше 20 років надає послуги з моніторингу цін у всьому світі.',
  price_monitoring_5:
    'Наша команда кваліфікованих штатних аудиторів у стислі терміни відстежує ціни, що дозволяє нам швидко реагувати на зміни цін конкурентів.',
  price_monitoring_6:
    'Завдання, які наша компанія допомагає вирішити за допомогою моніторингу цін',
  price_monitoring_7: 'Оцінка цін конкурентів',
  price_monitoring_8: 'та продуктової політики',
  price_monitoring_9: 'Моніторинг рекламних акцій',
  price_monitoring_10: 'та спеціальних пропозицій',
  price_monitoring_11: "Моніторинг ринкової кон'юнктури",
  price_monitoring_12: 'та ринкового середовища',
  price_monitoring_13: 'Перевірка та контроль торгових точок',
  price_monitoring_14: 'на наявність спецобладнання та товарів',
  price_monitoring_15: 'Управління продуктовим',
  price_monitoring_16: 'потоком усередині торгівлі',
  price_monitoring_17: 'Умови оптимізації',
  price_monitoring_18: 'для кращої співпраці з постачальниками, дилерами',
  price_monitoring_19: 'Вимірювання простору на полицях,',
  price_monitoring_20: 'оцінка розташування товарів, наявності запасів',
  price_monitoring_21: 'Ключові показники та критерії моніторингу',
  price_monitoring_22: 'Всі дані зібрані в одному інтерфейсі, включаючи:',
  price_monitoring_23: 'назву',
  price_monitoring_24: 'категорії продукту та бренду',
  price_monitoring_25: 'доступність',
  price_monitoring_26: 'рекламних матеріалів',
  price_monitoring_27: 'ціну',
  price_monitoring_28: 'та наявність акційних пропозицій',
  price_monitoring_29: 'розмір',
  price_monitoring_30: 'полиць',
  price_monitoring_31: 'представлення',
  price_monitoring_32: 'продукту',
  price_monitoring_33: 'SKU',
  price_monitoring_34: 'фейсинг',
  price_monitoring_35: 'тип',
  price_monitoring_36: 'упаковки',
  price_monitoring_37: 'будь-яка інша',
  price_monitoring_38: 'загальна інформація',
  price_monitoring_39: 'Передпроєктний аналіз:',
  price_monitoring_40: 'Ми вивчаємо',
  price_monitoring_41:
    'цінову та продуктову політику вашої компанії, бізнесові та галузеві методи роботи',
  price_monitoring_42: 'Ми забезпечуємо',
  price_monitoring_43:
    'оперативне навчання, тестування та сертифікацію аудиторів',
  price_monitoring_44: 'Ми індивідуально адаптуємо',
  price_monitoring_45: 'та налаштовуємо програмне забезпечення',
  price_monitoring_46: 'Польові роботи:',
  price_monitoring_47: 'Відвідування точок',
  price_monitoring_48: 'роздрібної торгівлі',
  price_monitoring_49: 'Складання звітів:',
  price_monitoring_50: 'Наша аналітична команда',
  price_monitoring_51: 'готує онлайн-звіти в особистих кабінетах компанії',
  price_monitoring_52: 'Ми надаємо',
  price_monitoring_53:
    'повний фотозвіт, звіт PPT, звіт Excel із зазначенням типу населеного пункту, назви мережі, адреси торгової точки',
  price_monitoring_54: 'Як це',
  price_monitoring_55: 'працює',
  price_monitoring_56: 'розмір індивідуальних знижок',
  price_monitoring_57: 'розрахунок вартості',
  price_monitoring_58: 'розмір індивідуальних бонусів',
  price_monitoring_59: 'програми лояльності',
  price_monitoring_60: 'і т.д.',
  price_monitoring_61:
    'Ми використовуємо спеціалізоване програмне забезпечення:',
  price_monitoring_62:
    'PRADATA, Shopmetrics та пропонуємо всебічну технічну підтримку',
  price_monitoring_63: 'Швидке використання',
  price_monitoring_64: 'та масштабування (з першого дня)',
  price_monitoring_65: 'Усі дані підтверджені',
  price_monitoring_66: 'фотографіями та GPS-мітками',
  price_monitoring_67: 'У нас є необхідна кількість',
  price_monitoring_68: 'польових агентів у будь-якій країні',
  price_monitoring_69: 'Ми можемо працювати з великою',
  price_monitoring_70: 'кількістю даних',
  price_monitoring_71: 'Моніторинг індивідуальних цін',
  price_monitoring_72:
    'Якщо ви маєте комплекс продуктів, ми допоможемо вам визначити стратегію ціноутворення',
  price_monitoring_73:
    'Ми можемо відстежувати окремі ціни конкурентів, наприклад:',
  price_monitoring_74: 'Низькі',
  price_monitoring_75: 'витрати',
  price_monitoring_76: 'Висока',
  price_monitoring_77: 'ефективність',
  price_monitoring_78: 'Звіт Excel',
  price_monitoring_79: 'РРТ-звіт',
  price_monitoring_80: 'звіт бізнес-аналітики',
  price_monitoring_81:
    'Дані про ціни конкурентів допомагають ухвалювати оптимальні цінові рішення.',
  price_monitoring_82:
    'Надаємо повний фотозвіт, звіт PPT, звіт Excel за типом населеного пункту',
  qualitative_research_1: 'Якісні',
  qualitative_research_2: 'дослідження',
  qualitative_research_3:
    'Збір, аналіз та інтерпретація даних на основі спостережень за діями та словами ваших клієнтів.',
  qualitative_research_4: 'Цінності',
  qualitative_research_5: 'Поведінка',
  qualitative_research_6: 'Накопичений досвід',
  qualitative_research_7: 'Очікування',
  qualitative_research_8: 'Довкілля',
  qualitative_research_9: "Страхи/бар'єри",
  qualitative_research_10: 'Якою є мета клієнтського вибору?',
  qualitative_research_11: 'Чоловік з телефоном',
  qualitative_research_12: 'бренд',
  qualitative_research_13: 'категорія',
  qualitative_research_14: 'реклама',
  qualitative_research_15: 'продукт',
  qualitative_research_16:
    'Унікальність якісних методів у тому, що вони дозволяють зрозуміти причини та мотивацію споживчої поведінки.',
  qualitative_research_17: 'Аналіз ставлення споживача до того чи іншого явища',
  qualitative_research_18: 'Індивідуальні погляди та досвід',
  qualitative_research_19:
    'Теми, які неможливо дослідити за допомогою опитувань',
  qualitative_research_20: 'Делікатні теми',
  qualitative_research_21: 'Важкодоступну аудиторію',
  qualitative_research_22: 'Складну географію',
  qualitative_research_23: "Глибинні інтерв'ю корисні, коли йдеться про:",
  qualitative_research_24: "Глибинні інтерв'ю",
  qualitative_research_25:
    "Глибинне інтерв'ю — це метод якісного дослідження, який включає проведення індивідуальних інтерв'ю з респондентами для вивчення їх поглядів на ту чи іншу ідею, програму чи ситуацію. Глибинне інтерв'ю докладно досліджує індивідуальний досвід/сприйняття/практику.",
  qualitative_research_26: 'Цілі:',
  qualitative_research_27: 'Характеристику соціальних та культурних норм',
  qualitative_research_28: 'Важкодоступну аудиторію',
  qualitative_research_29:
    'Обмін інформацією та порівняння, досліджені шляхом опитувань',
  qualitative_research_30:
    'Теми, які не можна дослідити за допомогою опитувань',
  qualitative_research_31: 'Поглиблені фокус-групи корисні, коли йдеться про:',
  qualitative_research_32: 'Фокус-групи',
  qualitative_research_33:
    'Глибоке вивчення окремих зустрічей та дискусій, що виникають, під проводом модератора групи.',
  qualitative_research_34:
    'Отримання інформації про загальний досвід та соціальні норми через цілеспрямоване групове обговорення та дані з розповідей.',
  qualitative_research_36: 'Переваги:',
  qualitative_research_37:
    'Вивчення способу життя та поведінки споживача у різних реальних умовах (вдома, у торговій точці, на роботі і т.д.)',
  qualitative_research_38:
    'Пошуку інформації для розробки нових продуктів, упаковки, покращення обслуговування тощо.',
  qualitative_research_39: 'Поглиблене етнографічне дослідження корисне для:',
  qualitative_research_40: 'Етнографічне дослідження',
  qualitative_research_41:
    'Етнографія — це тип якісного дослідження, яке включає занурення в життя певної спільноти або організації, щоб зблизька спостерігати за їхньою поведінкою та взаємодією.',
  qualitative_research_42:
    "Етнографія - це гнучкий метод дослідження, який дозволяє вам глибше зрозуміти загальну культуру, традиції та соціальну динаміку групи. Однак, він також пов'язаний з деякими практичними та етичними викликами.",
  qualitative_research_43: 'Методи:',
  qualitative_research_44:
    'Етнографія — це вивчення в реальних умовах споживача та його взаємодії з товаром / послугою.',
  qualitative_research_45: 'Переваги роботи з нами',
  qualitative_research_46:
    'Ми можемо знайти респондентів із будь-якої цільової аудиторії. І контролюємо якість підбору',
  qualitative_research_47: 'Рекрутинг будь-якої складності',
  qualitative_research_48:
    'Наші модератори розмовляють кількома мовами і мають навички сприяння та усунення труднощів',
  qualitative_research_49: 'Досвідчені модератори',
  qualitative_research_50: 'Робота онлайн/офлайн',
  qualitative_research_51: 'Надаємо аудіо/відео',
  qualitative_research_52: 'запису',
  qualitative_research_53:
    'Ми можемо провести якісне дослідження у будь-якій місцевості',
  quantitative_research_1: 'Дослідження задоволеності клієнтів',
  quantitative_research_2: 'певним продуктом чи послугою (SCI, NPS)',
  quantitative_research_3: 'Проведення сегментації',
  quantitative_research_4: 'огляди різних ринків',
  quantitative_research_5: 'Дослідження ринку',
  quantitative_research_6: 'Управління NPS',
  quantitative_research_7: 'Аналіз конкурентів',
  quantitative_research_8: 'Кількісні',
  quantitative_research_9: 'дослідження',
  quantitative_research_10:
    "Методологія дослідження використовується для перевірки теорій щодо ставлення та поведінки людей на основі об'єктивних, числових та статистичних даних.",
  quantitative_research_11: 'років',
  quantitative_research_12: 'досвіду CX по всьому світу',
  quantitative_research_13: 'панель',
  quantitative_research_14: 'респондентів',
  quantitative_research_15: 'країни',
  quantitative_research_16: 'ми працюємо над проєктами по всьому світу',
  quantitative_research_17: 'Хто ми',
  quantitative_research_18:
    '4Service – глобальний холдинг маркетингових досліджень та CX. Ми пропонуємо експертні послуги зі збору даних для різних підприємств',
  quantitative_research_19: '4service у числах',
  quantitative_research_20: "Комп'ютеризоване",
  quantitative_research_21: "Веб-інтерв'ю",
  quantitative_research_22:
    "Техніка анкетування в Інтернеті. Респондент заповнює електронну анкету без допомоги інтерв'юера. Анкета може бути надіслана в електронному вигляді або розміщена на сайті.",
  quantitative_research_23: "Веб-інтерв'ю з використанням комп'ютера",
  quantitative_research_24: 'У більшості випадків',
  quantitative_research_25:
    'цей спосіб є найбільш рентабельним з позиції матеріальних та часових витрат',
  quantitative_research_26: 'Це опитування дає',
  quantitative_research_27:
    "можливість взяти інтерв'ю у важкодоступної аудиторії",
  quantitative_research_28: 'Він дає широкі можливості',
  quantitative_research_29:
    'для демонстрації зображень, відео- та аудіоматеріалів',
  quantitative_research_30: 'Респонденти можуть',
  quantitative_research_31: 'знаходитися у будь-якій точці світу',
  quantitative_research_32: 'Дозволяє досліджувати',
  quantitative_research_33:
    'специфічну поведінку інтернет-аудиторії. Клієнт отримує доступ до основних результатів у реальному часі через веб-інтерфейс',
  quantitative_research_34: 'Метод є прийнятним для будь-якої конфіденційної,',
  quantitative_research_35:
    "чутливої та особистої теми чи питання, на яке респонденти, можливо, не хотіли б відповідати при інтерв'юері",
  quantitative_research_36: '150 000+ панель',
  quantitative_research_37:
    'Ми маємо власну панель респондентів по всьому світу.',
  quantitative_research_38: 'Працюємо зі складними запитами',
  quantitative_research_39:
    'Використовуючи додаткові інструменти (соціальні мережі, рекламу), ми можемо знайти конкретну аудиторію та специфічний профіль респондента.',
  quantitative_research_40: 'Ми мотивуємо наших респондентів',
  quantitative_research_41:
    'Ми розробили систему заохочення до проходження довгих опитувань шляхом надання матеріальних заохочень.',
  quantitative_research_42: "Особисті інтерв'ю",
  quantitative_research_43: 'проводяться за допомогою планшета',
  quantitative_research_44:
    "Це один з основних методів збору кількісних даних, в ході якого інтерв'юер безпосередньо спілкується з респондентом за структурованою анкетою.",
  quantitative_research_45:
    "Очні інтерв'ю можуть проводитись за місцем роботи або проживання респондента, на вулиці або у спеціально відведених місцях продажу з використанням планшета.",
  quantitative_research_46: "Очні інтерв'ю з використанням планшета",
  quantitative_research_47: 'Визначення рівня',
  quantitative_research_48: 'популярності та впізнаваності бренду',
  quantitative_research_49: 'Сегментація споживачів',
  quantitative_research_50: 'на основі їхньої купівельної поведінки',
  quantitative_research_51: 'Аналіз ефективності',
  quantitative_research_52: 'рекламної кампанії',
  quantitative_research_53: 'Задоволеність клієнтів',
  quantitative_research_54: 'вимір',
  quantitative_research_55: 'Визначення найкращої ціни',
  quantitative_research_56: 'для продукту чи послуги',
  quantitative_research_57: 'Аналіз',
  quantitative_research_58: 'споживчих переваг',
  quantitative_research_59: 'Вивчення',
  quantitative_research_60: 'того, як споживачі купують',
  quantitative_research_61: 'Оцінка',
  quantitative_research_62: 'ємності ринку',
  quantitative_research_63: 'Програмне забезпечення DigSee',
  quantitative_research_64:
    'Використовуємо спеціальне ПЗ з аудіозаписом та GPS',
  quantitative_research_65: "Інтерв'юери та супервайзери",
  quantitative_research_66:
    "ми можемо проводити інтерв'ю будь-якою мовою і перекладати мову, якій віддає перевагу Клієнт",
  quantitative_research_67: 'Вибірка',
  quantitative_research_68:
    'Аналітичний відділ розробляє систему підбору респондентів для отримання найкращого результату',
  quantitative_research_69: "Комп'ютеризоване",
  quantitative_research_70: "Телефонне інтерв'ю",
  quantitative_research_71:
    "Методика збирання кількісної інформації за допомогою телефонних інтерв'ю з використанням чітко структурованої анкети.",
  quantitative_research_72: "Телефонне інтерв'ю з використанням комп'ютера",
  quantitative_research_73: 'У нас є власні кол-центри',
  quantitative_research_74:
    'Використовуємо спеціальне ПЗ з аудіозаписом та GPS',
  quantitative_research_75: 'Кваліфіковані оператори',
  quantitative_research_76:
    'Наші оператори володіють різними мовами, пройшли навчання, атестацію та постійно вдосконалюють свої навички.',
  quantitative_research_77: 'Бази даних',
  quantitative_research_78:
    'Ми працюємо як з власними базами даних, так і з вашими базами клієнтів.',
  quantitative_research_79: 'Угода про рівень послуг SLA',
  quantitative_research_80:
    'Ми гарантуємо якість та підписуємо SLA (Service Level Agreement)',
  quantitative_research_81: 'Постановка цілей',
  quantitative_research_82: 'Визначення методології',
  quantitative_research_83: 'Сегментація та вибірка',
  quantitative_research_84: 'Визначення каналів збору даних',
  quantitative_research_85: 'Створення анкети, логічної для респондента',
  quantitative_research_86: 'Процес збору даних',
  quantitative_research_87: 'Забезпечення якості',
  quantitative_research_88: 'Методи аналізу',
  quantitative_research_89: 'Обробка даних',
  quantitative_research_90: 'Розробка опитування',
  quantitative_research_91:
    "Відстеження місцезнаходження інтерв'юерів за допомогою GPS",
  quantitative_research_92: 'Перевірка даних',
  quantitative_research_93: 'Програмування та тестування анкети',
  quantitative_research_94: 'Валідація даних',
  quantitative_research_95: 'Аудіозаписи з дотриманням вимог GDPR',
  quantitative_research_96: 'Підготовка та компетентність наукового персоналу',
  quantitative_research_97: 'Забезпечення якості',
  quantitative_research_98: 'Факторний аналіз',
  quantitative_research_99: 'Сегментація',
  quantitative_research_100: 'Побудова карти сприйняття',
  quantitative_research_101: 'Кореляція',
  quantitative_research_102: 'Моделювання конверсій',
  quantitative_research_103: 'Коефіцієнт подібності Жаккара',
  quantitative_research_104: 'Кластерний аналіз',
  quantitative_research_105: 'і т.д.',
  quantitative_research_106: 'Аналітичні звіти',
  quantitative_research_107:
    'Ми використовуємо SPSS® та інші інструменти для створення аналітичних звітів із застосуванням різних методологій.',
  quantitative_research_108: 'Які бізнес-задачі вирішуються:',
  quantitative_research_109: 'Які бізнес-задачі вирішуються:',
  social_responsibility_1: 'Корпоративна соціальна відповідальність 4Service',
  social_responsibility_2:
    'Від серця та душі кожного члена нашої команди. Ми намагаємося зберегти іскру доброти в кожному нашому співробітнику і зробити світ навколо нас трохи кращим',
  social_responsibility_3: 'Освітня діяльність / Підтримка молоді',
  social_responsibility_4:
    'Наші просвітницькі ініціативи для дітей та студентів',
  social_responsibility_5: 'Бізнес-гра «Сервісоманія»',
  social_responsibility_6:
    'Важливою частиною наших навчальних заходів є ділова гра «Сервісоманія».',
  social_responsibility_7:
    'Service Mania вчить мислити стратегічно, щоб легко долати незвичайні ситуації.',
  social_responsibility_8: 'Презентація дослідження/Дія.Бізнес',
  social_responsibility_9:
    'Погляд бізнесу та клієнта на ситуацію з сервісом: презентація результатів нашого дослідження від Вікторії Скорботи, керівниці відділу розвитку 4Service Україна',
  social_responsibility_10: 'Сесії з сервіс-дизайну',
  social_responsibility_11:
    'Методологія Service Design є важливою частиною 4Service. Кілька років тому ми стали партнером DesignThinkers Academy і не тільки використовуємо найкращі практики у своїй роботі, але й активно навчаємо найкращі компанії',
  social_responsibility_12: 'Благодійність / Волонтерство',
  social_responsibility_13:
    'Дитячі будинки, будинки для людей похилого віку та волонтерство на користь української армії. Найзворушливіша частина нашої діяльності',
  social_responsibility_14: '#HelpEasyWith4ServiceGroup',
  social_responsibility_15:
    'Ми співпрацюємо з Благодійним фондом «Благомай» і намагаємося дарувати свято та допомагати тим, хто гостро цього потребує',
  social_responsibility_16:
    'Збір засобів для дитячих будинків та будинків для людей похилого віку',
  social_responsibility_17:
    'У 4Service ми ініціювали кілька зборів коштів для будинків для людей похилого віку та дитячих будинків на купівлю предметів першої необхідності.',
  social_responsibility_18: '#SaveUkraine',
  social_responsibility_19:
    'Війна торкнулася багатьох наших співробітників та близьких друзів. Кожен з нас допомагає чим може',
  social_responsibility_20: 'Турбота про тварин',
  social_responsibility_21:
    'Будь-хто може прийти до нас в офіс зі своїм улюбленцем. Ми допомагаємо притулкам, збираємо кошти на корм, інколи ж шукаємо дім для бездомних тварин.',
  social_responsibility_22: 'Підтримка притулків для тварин',
  social_responsibility_23:
    'Бездомні тварини потребують допомоги так само, як і решта вразливих членів нашого суспільства.',
  social_responsibility_24:
    'Наша компанія взяла під свою опіку притулок для бездомних тварин «Сіріус» (Київська область, Україна).',
  social_responsibility_25:
    'Креативні ініціативи на допомогу бездомним тваринам',
  social_responsibility_26:
    'Через які ми доносимо актуальність проблеми притулків для тварин до наших клієнтів та партнерів',
  social_responsibility_27: 'Офіс, дружній до домашніх тварин',
  social_responsibility_28:
    'Багато членів нашої команди мають домашніх тварин. Ми створили здорове середовище, в якому кожен може прийти в офіс зі своїм улюбленцем і почуватися комфортно.',
  social_responsibility_29: 'Наші соціальні активності',
  ux_testing_1: 'UX-тестування',
  ux_testing_1_1: 'UX-тестування',
  ux_testing_2:
    'Тестування та покращення ваших веб-сайтів та додатків: людський підхід та підхід із використанням штучного інтелекту',
  ux_testing_3: 'Що таке UX-тестування',
  ux_testing_4:
    'UX-тестування допоможе вам зрозуміти, як люди взаємодіють з вашим продуктом, програмою або веб-сайтом.',
  ux_testing_5: 'Навігація не дуже зрозуміла',
  ux_testing_6: 'Я не розумію, як зробити замовлення',
  ux_testing_7: 'Недостатньо фото у каталозі',
  ux_testing_8: 'Ви зможете:',
  ux_testing_9: 'Відкрити шляхи вдосконалення',
  ux_testing_10: 'Виявити проблеми у дизайні та взаємодії з клієнтом',
  ux_testing_11:
    'Дізнатися, з якими труднощами стикається користувач при взаємодії із сайтом',
  ux_testing_12: 'Спостерігати за подорожжю клієнта',
  ux_testing_13: 'Дізнатися, коли і чому ваша цільова аудиторія залишає сайт',
  ux_testing_14:
    'Визначити, які розділи викликають найменший та найбільший інтерес та емоції',
  ux_testing_15: 'UX-тестування користувачами',
  ux_testing_16:
    '4Service надає реальну людську інформацію, необхідну для створення клієнтоорієнтованих веб-сайтів і додатків з використанням якісних і кількісних методів.',
  ux_testing_17: 'Я не розумію, як користуватись сайтом. Де заховане меню?',
  ux_testing_18:
    'Я вважаю, що між буквами надто великий простір, текст не читається',
  ux_testing_19: 'Картинки накладаються одна на одну. Так і має бути?',
  ux_testing_20:
    'UX-тестування допоможе вам зрозуміти, як люди взаємодіють з вашим продуктом, програмою або веб-сайтом.',
  ux_testing_21: 'Дизайн дослідження тестування користувача UX:',
  ux_testing_22: 'Відкриття можливості для вдосконалення',
  ux_testing_23: 'Визначення типу та методу тестування (якісне/ кількісне)',
  ux_testing_24: 'Складання гіпотез та сценаріїв користувача',
  ux_testing_25: 'Аналіз та звіт про результати випробувань',
  ux_testing_26: 'Два рішення',
  ux_testing_27: 'Ми пропонуємо два підходи до успішного тестування UX',
  ux_testing_28: 'UX-тестування користувачами',
  ux_testing_29: 'UX-тестування людиною та штучним інтелектом',
  ux_testing_30: 'Наші сильні сторони:',
  ux_testing_31: 'Ми можемо аналізувати конкурентне середовище',
  ux_testing_32:
    "Можемо залучити вузьких спеціалістів для проведення та участі в інтерв'ю",
  ux_testing_33:
    "Ми можемо проводити інтерв'ю різними мовами та перекладати їх потрібною мовою",
  ux_testing_34: 'з існуючою зручністю використання',
  ux_testing_35: 'показників чи стандартів',
  ux_testing_36: 'ваша діяльність',
  ux_testing_37: 'проти конкурентних',
  ux_testing_38: 'версії',
  ux_testing_39: 'того ж продукту',
  ux_testing_40: 'Рейтинг задоволеності',
  ux_testing_41: 'Індекс Споживчої Лояльності NPS',
  ux_testing_42: 'Показники успіху',
  ux_testing_43: 'Частота помилок',
  ux_testing_44: 'Час виконання завдання',
  ux_testing_45: 'UX-тестування користувачами',
  ux_testing_46: 'Кількісний метод',
  ux_testing_47:
    'Ми залучуємо не менше 100 осіб для участі в UX-тестуванні на цьому етапі',
  ux_testing_48:
    'Актуально, коли вам необхідно порівняти ваш сайт або додаток:',
  ux_testing_49: 'Кількісний метод виміру:',
  ux_testing_50: 'Спостереження',
  ux_testing_51:
    'Проведення часу з користувачем або групою користувачів та спостереження за їх взаємодією з продуктом, коли вони використовують його у повсякденному житті.',
  ux_testing_52: "Глибинне інтерв'ю",
  ux_testing_53:
    "Дозволяє вам вивчити ставлення, переконання, бажання та досвід користувачів, які приходять на ваш сайт. Ми рекомендуємо провести 15-20 інтерв'ю",
  ux_testing_54: 'Етнографічні дослідження',
  ux_testing_55:
    'Проводяться у середовищі респондентів, де вони будуть використовувати продукт. Спостереження дозволяють добре розуміти психологію користувача, емоційні проблеми, з якими він стикається.',
  ux_testing_56: 'UX-тестування користувачами',
  ux_testing_57: 'Якісний метод',
  ux_testing_58:
    'Цей метод допомагає зрозуміти мотиви та логіку поведінки користувачів.',
  ux_testing_59: 'Ми використовуємо:',
  ux_testing_60:
    'Ми розробляємо карту шляху клієнта, щоб проілюструвати результати нашого дослідження.',
  ux_testing_61: '30 учасників (чоловіки/жінки)',
  ux_testing_62:
    'Респонденти проходять попередню перевірку та отримують запрошення взяти участь в опитуванні.',
  ux_testing_63: 'Віддалена онлайн-співбесіда на платформі Wantent',
  ux_testing_64: 'ШІ + людина',
  ux_testing_65: 'UX-тестування: ШІ+людина',
  ux_testing_66:
    'Поєднання унікальної технології штучного інтелекту Wantent та залучення реальних користувачів',
  ux_testing_67: 'Великий шрифт у заголовку привернув увагу',
  ux_testing_68:
    'Користувачі дивляться на «гучномовні» цифри, але не читають інформацію поруч із ними',
  ux_testing_69:
    'UX-тестування допоможе вам зрозуміти, як люди взаємодіють з вашим продуктом, програмою або веб-сайтом.',
  ux_testing_70: 'Методологія дослідження - ШІ + тестування людиною',
  ux_testing_71: 'Як це працює',
  ux_testing_72:
    'Wantent — це рішення, що ґрунтується на штучному інтелекті, яке відповідає ринку контенту.',
  ux_testing_73:
    'Wantent оцінює ефективність веб-сайтів та додатків, застосовуючи технології машинного навчання для аналізу емоційних реакцій та залучення аудиторії.',
  ux_testing_74:
    'Для інтерпретації результатів ми використовуємо методи сторітелінгу, різноманітності та емпатії, щоб стимулювати продуктивність та зростання.',
  ux_testing_75: 'Увагу та емоції користувача при переході по розділам',
  ux_testing_76:
    'Статистику щодо груп учасників (увагу та зосередженість протягом усього сеансу)',
  ux_testing_77: 'Аналіз конверсій сторінок',
  ux_testing_78: 'Увагу та емоції при виконанні завдань',
  ux_testing_79: 'Висновки щодо сприйняття',
  ux_testing_80: 'сайту різними групами учасників',
  ux_testing_81: 'Аналіз легкості',
  ux_testing_82: 'навігації по сторінках та легкості пошуку важливих елементів',
  ux_testing_83: 'Аналіз сприйняття',
  ux_testing_84: 'інформації, розміщеної на сайті',
  ux_testing_85: 'Визначення фрагментів',
  ux_testing_86:
    'для доопрацювання всередині окремих сторінок (навігація, інформаційні блоки тощо)',
  ux_testing_87: 'Ми детально аналізуємо',
  ux_testing_87_about: 'підхід',
  ux_testing_88: 'Основні результати дослідження',
  ux_testing_89:
    'Рекомендації щодо покращення структури сайту та окремих сторінок',
  ux_testing_90:
    'Ми ретельно відбираємо цільову аудиторію для тестування зручності використання.',
  ux_testing_91: 'Проводимо дослідження в різних країнах',
  ux_testing_92: 'Можемо протестувати додатки',
  ux_testing_93: 'та сайти конкурентів та провести порівняльний аналіз.',
  ux_testing_94: 'Ми комбінуємо різноманітні методи дослідження.',
  ux_testing_95:
    'Наші кваліфіковані фасилітатори проводять учасника через процес тестування.',
  ux_testing_96: 'Ми проводимо тестування',
  ux_testing_97: 'на різних пристроях та версіях програмного забезпечення',
  ux_testing_98: 'Чому варто вибрати саме нас?',
  ux_testing_99: ', різними мовами та серед різних груп користувачів',
  wantent_1: '4Service х',
  wantent_2: 'Wantent',
  wantent_3:
    'Унікальний партнерський проєкт від 4Service та Wantent © Поєднання штучного інтелекту та людського підходу до контент-аналізу',
  wantent_4: 'вимірювання успішності ще до того, як контент вийде в ефір',
  wantent_5: "зниження ризиків, пов'язаних із проблемами різноманітності",
  wantent_6: 'глибокого розуміння та рекомендацій щодо покращення контенту',
  wantent_7: "об'єктивних рішень щодо контенту та маркетингової стратегії",
  wantent_8:
    'Wantent — засноване на штучному інтелекті рішення, адаптоване до контент-ринку.',
  wantent_9:
    'Wantent — це технологічне рішення на основі штучного інтелекту для того, щоб привести контент у відповідність до особливостей ринку. Wantent, застосовуючи технології машинного навчання, оцінює ефективність відеоконтенту, аналізує емоційний відгук та залученість аудиторії.',
  wantent_10:
    'Wantent допомагає визначити, наскільки контент відповідає ринку, а також максимізувати ефективність контенту за рахунок:',
  wantent_11: 'Налаштування сценарію та підбір учасників',
  wantent_12:
    'Індивідуальна розробка проєкту та залучення аудиторії з усього світу',
  wantent_13: 'Збір реакції користувачів',
  wantent_14: 'Детальний та точний зворотній зв’язок від аудиторії',
  wantent_15: 'Аналіз сприйняття контенту',
  wantent_16:
    'Аналіз користувацької поведінки та емоційних реакції за допомогою ШІ',
  wantent_17: 'Надання інсайтів, отриманих у результаті аналізу',
  wantent_18: 'Фінальні рекомендації для покращення контенту',
  wantent_19: 'Wantent максимізує ефективність вашого контенту',
  wantent_20: 'Попереднє тестування концепції реклами',
  wantent_21: 'Тестування креативної концепції',
  wantent_22:
    'Перевірка майбутнього креативу на відповідність до розробленої стратегії та розуміння реакції цільової аудиторії, яке дозволить коригувати креативні концепції/меседжі',
  wantent_23: 'Тестування видимості елементів бренду (постпродакшн)',
  wantent_24: 'Підтвердження ефективності рекламної/спонсорської інвестиції',
  wantent_25: 'Тестування реклами',
  wantent_26: 'A/B-тестування',
  wantent_27:
    'Порівняння ефективності креативів, підвищення залученості та утримання уваги на етапі виробництва',
  wantent_28: 'Запуск тестування реклами',
  wantent_29:
    'Визначення ефективності повідомлень та створення рекомендацій щодо покращення звукового супроводу та зображень',
  wantent_30: 'Рішення для ТБ',
  wantent_31: 'Пілотні випуски, промо, нові формати, тестування телеведучих',
  wantent_32:
    'Проведення порівняльного аналізу для виявлення найбільш привабливого контенту та його посилення',
  wantent_33: 'Тестування телешоу та художніх фільмів',
  wantent_34:
    'Аналіз залученості глядачів, рівня уваги, емоційного відгуку під час перегляду тривалого контенту в природних умовах. Ми автоматично знаходимо піки незацікавленості аудиторії та моменти втрати уваги для оцінки та покращення монтажу відео',
  wantent_35: 'UI/UX-тестування',
  wantent_36: 'Тестування прототипів сайтів та мобільних додатків',
  wantent_37:
    'Аналіз реакції та емоцій користувачів за допомогою технології Wantent:',
  wantent_38: 'рівень уваги',
  wantent_39: 'емоційні реакції',
  wantent_40: 'теплові карти напряму погляду учасників',
  wantent_41:
    'Аналіз юзабіліті веб-сайтів та програм на основі відгуків учасників:',
  wantent_42: 'Шкала зручності використання системи (SUS)',
  wantent_43: 'Оцінка зусиль клієнта (простота взаємодії)',
  wantent_44: 'Чистий показник просування (NPS)',
  wantent_45: 'Відповідність GDPR та конфіденційність користувачів',
  wantent_46:
    'Wantent повністю відповідає політикам GDPR та CCPA та принципам збору, зберігання, обробки та захисту персональних даних учасників.',
  wantent_47: 'Микита Лобинцев',
  wantent_48: 'СDO, Reface (Медіа та Розваги)',
  wantent_49:
    "Щодня ми експериментуємо з багатьма новими ідеями контенту за допомогою технологій машинного навчання. Wantent допомагає нам зрозуміти та оцінити ризики та переваги, дізнатися відгуки, поведінку та сприйняття користувачів, щоб встановити причинно-наслідковий зв'язок різних аспектів наших рішень.",
  wantent_50: 'Олександр Смирнов',
  wantent_51: 'співвласник TABASCO (Реклама)',
  wantent_52:
    'Wantent надає дійсно унікальну послугу, яка допомагає нам дізнатися про нюанси того, що подобається і не подобається споживачам. Немає можливості обдурити або збрехати – Wantent завжди знає правду, і тим самим ми, як маркетологи, отримуємо безцінні (хахаха – ми знаємо точну ціну і вона доступна) рекомендації щодо покращення наших комунікацій.',
  wantent_53: 'Відгуки',
  terms_1: 'Умови',
  terms_2: 'Угода про умови використання',
  terms_3:
    'Будь ласка, уважно прочитайте цю Угоду про умови використання («умови використання», «угоду») перед використанням веб-сайту.',
  terms_4:
    '(«сайт»), яким керує 4Service Holdings GmbH («4Service», «нас», «ми», «наш»).',
  terms_5:
    'Умови використання: Використовуючи цей веб-сайт, ви підтверджуєте, що прочитали та ознайомилися з цією Угодою та погоджуєтеся дотримуватися її умов. Якщо ви не бажаєте дотримуватися умов цієї Угоди, вам рекомендується відпрвідно залишити веб-сайт. 4Service дозволяє використання та доступ до цього веб-сайту, його продуктів та послуг лише тим, хто прийняв його умови.',
  terms_6: 'Політика конфіденційності',
  terms_7:
    'Перш ніж ви продовжите використовувати наш веб-сайт, ми рекомендуємо ознайомитися з нашою Політикою конфіденційності щодо збору даних про користувачів. Це допоможе вам краще зрозуміти наші практики.',
  terms_8: 'Вікове обмеження',
  terms_9:
    "Вам має бути не менше 16 (шістнадцяти) років, перш ніж ви зможете використати цей веб-сайт. Використовуючи цей веб-сайт, ви гарантуєте, що вам виповнилося 16 (шістнадцять) років, і ви можете юридично дотримуватися цієї Угоди. 4Service не несе відповідальності за зобов'язання, пов'язані зі спотворенням інформації про вік.",
  terms_10: 'Інтелектуальна власність',
  terms_11:
    'Ви погоджуєтесь з тим, що всі матеріали, продукти та послуги, представлені на цьому веб-сайті, є власністю 4Service, її афілійованих осіб, директорів, посадових осіб, співробітників, агентів, постачальників або ліцензіарів, включаючи всі авторські права, комерційні таємниці, товарні знаки, патенти та іншу інтелектуальну власність. Ви також погоджуєтесь з тим, що ви не відтворюватимете або поширюватимете інтелектуальну власність 4Service, включаючи електронну, цифрову реєстрацію або реєстрацію нових товарних знаків.',
  terms_12: 'Доступ до нашого веб-сайту',
  terms_13:
    "Доступ до нашого сайту дозволено на тимчасовій основі, і ми залишаємо за собою право відкликати або змінити послуги, які ми надаємо на нашому сайті, без попередження. Ми не несемо відповідальності, якщо з будь-якої причини наш сайт недоступний у будь-який час або протягом будь-якого періоду. Час від часу ми можемо обмежувати доступ до деяких частин нашого сайту або до всього нашого сайту користувачам, які зареєструвалися у нас. Ви несете відповідальність за вжиття всіх заходів, необхідних для доступу до нашого сайту, включаючи використання обладнання, сумісного з нашим сайтом. Ви також несете відповідальність за те, щоб усі особи, які отримують доступ до нашого сайту через ваше інтернет-з'єднання, знали про ці умови та дотримувалися їх.",
  terms_14: 'Застосоване право',
  terms_15:
    'Відвідуючи цей веб-сайт, ви погоджуєтесь з тим, що закони Австрійської Республіки, без урахування принципів колізійного права, регулюватимуть ці умови та будь-які суперечки будь-якого роду, які можуть виникнути між 4Service та вами.',
  terms_16: 'Суперечки',
  terms_17:
    "Будь-яка суперечка, пов'язана з відвідуванням вами даного веб-сайту, підлягає розгляду в арбітражі Комерційним судом Відня.",
  terms_18: 'Компенсація',
  terms_19:
    'Ви погоджуєтесь відшкодувати збитки 4Service та його афілійованим особам та захистити 4Service від юридичних претензій та вимог, які можуть виникнути внаслідок вашого використання або неправильного використання нашого веб-сайту. Ми залишаємо за собою право обирати нашого власного юрисконсульта.',
  terms_20: 'Терміни зберігання',
  terms_21:
    'З огляду на цілі обробки термін зберігання персональних даних Користувачів (термін зберігання) становить 24 місяці з дати отримання від вас в установленому порядку згоди на обробку даних.',
  terms_22: 'Обмеження відповідальності',
  terms_23:
    '4Service не несе відповідальності за будь-які збитки, які можуть бути завдані вам внаслідок неправильного використання вами нашого веб-сайту. 4Service залишає за собою право без попереднього повідомлення редагувати, модифікувати та змінювати цю Угоду в будь-який час шляхом оновлення цієї публікації. Ваше подальше використання веб-сайту зараз або після публікації будь-яких змін або модифікацій означатиме прийняття вами таких змін або модифікацій. Якщо якусь частину цієї угоди буде оголошено незаконною, недійсною або такою, що не має законної сили, ця частина буде вважатися відокремленою і не вплине на дійсність і застосовність будь-яких інших положень. Можливі докази використання сайту з незаконною метою будуть передані до правоохоронних органів. Ця Угода є домовленістю між 4Service та користувачем.',
  terms_24:
    "Будь ласка, надсилайте всі питання та проблеми, пов'язані з конфіденційністю/використанням, за наступною адресою:",
  terms_25: '4Service Holdings GmbH',
  terms_26: 'Тегетхофштрассе 7',
  terms_27: '1010 Відень',
  terms_28: 'Австрія',
  terms_29: 'Огляд',
  terms_30:
    'Якщо персональні дані, які ви надаєте нам, є неточними, ви маєте право попросити нас негайно виправити їх (стаття 16 GDPR).',
  thank_you_1: 'Дякуємо вам!',
  thank_you_2: "Ми зв'яжемося з вами протягом 24 годин",
  thank_you_3:
    'P.S. Наші менеджери вже розглядають ваш запит, щоб скласти найкращу пропозицію',
  thank_you_4: 'Меню',
  thank_you_5: 'Жінка друкує',
  privacy_policy_1:
    "Форма сповіщення про конфіденційність для суб'єктів даних (користувачів веб-сайту)",
  privacy_policy_2:
    "Ця Форма сповіщення про конфіденційність призначена для фізичних осіб (суб'єктів даних), чиї персональні дані збираються 4Service Holdings GmbH відповідно до вимог GDPR у рамках відвідування веб-сайту.",
  privacy_policy_3:
    '(тут після Веб-сайт та Користувачі Веб-сайту/Користувачі відповідно).',
  privacy_policy_4:
    "Форма сповіщення про конфіденційність для суб'єктів даних (користувачів веб-сайту) 4Service Holdings GmbH застосовується у всіх компаніях групи 4Service, які на законних підставах знаходяться під наглядом або контролем 4Service Holdings GmbH.",
  privacy_policy_5: 'Контакти',
  privacy_policy_6: '4Service Holdings GmbH',
  privacy_policy_7: '(Компанія/ми/нас),',
  privacy_policy_8: 'Адреса:',
  privacy_policy_9: 'Tegetthoffstraße 7, 1010 Відень, Австрія.',
  privacy_policy_10: 'Електронна пошта:',
  privacy_policy_12: 'Ким ви є?',
  privacy_policy_13:
    'Фізична особа (ви), яка відвідує веб-сайт з будь-якою метою. Для цілей цього Документу ви повинні також бути вказані як Користувач.',
  privacy_policy_14: 'Персональні дані, які ми отримуємо від вас',
  privacy_policy_15:
    'Коли ви заходите на наш сайт, ми надсилаємо вам повідомлення про конфіденційність, в якому пропонуємо вам надати згоду на обробку наступної інформації:',
  privacy_policy_16:
    "IP-адреса, ім'я користувача, прізвище, адреса, номер телефону (стаціонарного або мобільного), адреса електронної пошти, адреса, назва компанії, країна, адреса електронної пошти, ім'я, прізвище, номер телефону, провінція, штат і поштовий індекс , дані про використання, дані про взаємодію із зовнішніми соціальними мережами або платформами, інформація про реєстрацію та авторизацію на сайті",
  privacy_policy_17: 'географічне положення.',
  privacy_policy_18:
    'Якщо ви висловлюєте свою волю і даєте нам таку згоду, ми починаємо обробляти таку інформацію від вас.',
  privacy_policy_19: 'Законність (згода)',
  privacy_policy_20:
    'Коли ви заходите на наш сайт, ми надсилаємо вам повідомлення про конфіденційність, в якому пропонуємо вам надати згоду на обробку наступної інформації:',
  privacy_policy_21:
    'Правовим підґрунтям для обробки персональних даних є згода, яку ми отримаємо від вас, заповнивши форму згоди за наступним посиланням:',
  privacy_policy_22:
    'Якщо ви заповнюєте форму згоди, це означає, що ви розумієте та приймаєте всі умови, зазначені в цьому повідомленні про конфіденційність.',
  privacy_policy_23: 'Відгук згоди',
  privacy_policy_24:
    'Ви маєте право відкликати згоду, яку ви надали раніше, у будь-який час. Відкликання згоди не впливає на законність обробки, що ґрунтується на згоді до її відкликання. Ви можете відкликати свою згоду, надіславши нам відповідний запит на наступну електронну адресу remove@4service-group.com, при цьому форма запиту доступна за наступним посиланням:',
  privacy_policy_25: 'Форма відкликання згоди користувача',
  privacy_policy_26: 'Цілі обробки',
  privacy_policy_27: 'Ми обробляємо ваші персональні дані для наступних цілей:',
  privacy_policy_28:
    "– покращити обслуговування клієнтів (дозволяє ефективніше реагувати на запити клієнтів); - Персоналізація досвіду Користувачів. Дозволяє визначити, які послуги цікавіші); – покращити Сайт. (Дозволяє підвищувати якість продуктів та послуг, зручність їх використання, розробляти нові Сервіси, покращувати наші продукти та послуги); - зв'язуватися з Користувачем за допомогою інформаційних бюлетенів, маркетингових або рекламних матеріалів та іншої інформації, яка включає наші новини, оновлення, інформацію про послуги з позначкою про інструкцію, як відмовитися від отримання наступних електронних листів. – проводити статистичні та інші види досліджень та аналізів на основі знеособлених даних. — надавати персоналізовані послуги Користувачеві та виконувати угоди та договори; – участь Користувача у різних проєктах, що реалізуються нами через Сайт, відповіді на запити, адресовані Користувачем через Сайт, дослідження, ведення облікових записів та просування послуг.",
  privacy_policy_29: "Суб'єкти, яким можуть бути передані персональні дані",
  privacy_policy_30:
    "При обробці ваших персональних даних ми передаємо ваші персональні дані суб'єктам, які діють як обробники даних Компанії. Обробники компанії діють виключно на підставі інструкцій компанії. З особливостями дій, які здійснюють обробники Компанії, та зі списком таких обробників можна ознайомитись у Політиці конфіденційності та захисту даних за наступним посиланням: Політика конфіденційності та захисту даних",
  privacy_policy_31: 'Країни, до яких можуть бути передані персональні дані',
  privacy_policy_32:
    'Компанія передає ваші персональні дані на підставі рішення про адекватність, як це передбачено GDPR та Комісією ЄС. Додаткову інформацію про передачу персональних даних у США можна знайти в Політиці конфіденційності та захисту даних за наступним посиланням: Політика конфіденційності та захисту даних.',
  privacy_policy_33: 'Термін зберігання',
  privacy_policy_34:
    'З огляду на цілі обробки термін зберігання персональних даних Користувачів (термін зберігання) становить 24 місяці з дати отримання від вас в установленому порядку згоди на обробку даних.',
  privacy_policy_35: 'Право доступу',
  privacy_policy_36:
    'Ви маєте право знати, чи обробляються персональні дані стосовно вас, і якщо так, отримати доступ до таких даних з безліччю додаткових умов, зазначених у статті 15 GDPR.',
  privacy_policy_37: 'Право на виправлення',
  privacy_policy_38:
    'Якщо персональні дані, які ви надаєте нам, є неточними, ви маєте право попросити нас виправити їх (стаття 16 GDPR).',
  privacy_policy_39: 'Право на стирання (право на забуття)',
  privacy_policy_40:
    "Ви маєте право отримати від нас видалення ваших особистих даних без невиправданої затримки, і ми будемо зобов'язані видалити ваші особисті дані без невиправданої затримки, якщо застосовні підстави, зазначені у статті 17 GDPR.",
  privacy_policy_41: 'Право на обмеження обробки',
  privacy_policy_42:
    'Ви маєте право обмежити обробку ваших персональних даних за кількома винятками в рамках GDPR, зокрема, зазначеними у статті 18 GDPR.',
  privacy_policy_43:
    "Ми зобов'язані повідомити вам, які дані збираються, як вони використовуються, як довго вони зберігатимуться і чи будуть передані третім особам. Ця інформація повинна повідомлятись коротко і в доступному формулюванні.",
  privacy_policy_44: 'Право на переносимість даних',
  privacy_policy_45:
    'Вам дозволено отримувати та повторно використовувати ваші особисті дані у своїх цілях у різних службах.',
  privacy_policy_46: 'Право на заперечення',
  privacy_policy_47:
    'Ви маєте право заперечувати обробку персональних даних, які обробляються Компанією. Ми повинні припинити обробку персональних даних, якщо ми не продемонструємо вагомих законних підстав для обробки, які переважають інтереси, права і свободи людини, або якщо обробка призначена для встановлення або захисту юридичних вимог.',
  privacy_policy_48:
    'Право не піддаватися рішенню, заснованому виключно на автоматизованій обробці',
  privacy_policy_49:
    'Ви маєте право заперечувати будь-яке автоматичне профілювання, яке відбувається без вашої згоди. При цьому ви маєте право обробляти Ваші особисті дані за участю людини.',
  privacy_policy_50: 'Скарги',
  privacy_policy_51:
    'Якщо ви хочете подати скаргу на те, як ваші персональні дані обробляються Компанією (або обробниками, описаними вище), або на те, як було опрацьовано вашу скаргу, ви маєте право подати скаргу безпосередньо до наглядового органу авторизованої Компанії.',
  privacy_policy_52: 'Деталі для кожного з цих контактів:',
  privacy_policy_53: 'Орган нагляду:',
  privacy_policy_54: 'Австрійський орган захисту даних',
  privacy_policy_55: 'Österreichische Datenschutzbehörde',
  privacy_policy_56: 'Вікенбурггасе 8',
  privacy_policy_57: '1080 Відень',
  privacy_policy_58: 'Австрія / Європа',
  privacy_policy_59: 'Компанія:',
  privacy_policy_60: '4Service Holdings GmbH (Компанія/ми/нас),',
  privacy_policy_61: 'Адреса: Tegetthoffstraße 7, 1010 Відень, Австрія.',
  privacy_policy_62: 'Електронна пошта:',
  privacy_policy_63: 'Політика конфіденційності та захисту даних',
  privacy_policy_64:
    'Докладніше про те, як і чому ми використовуємо ваші дані, читайте тут: Політика конфіденційності та захисту даних',
  privacy_policy_65: 'Схвалення',
  privacy_policy_66:
    'Компанія розробила всі внутрішні документи для визначення ролей серед персоналу щодо обробки персональних даних у Компанії, зокрема відповідальність за затвердження та перевірку законності цього документа несе Керуючий директор.',
  contacts_1: 'Для клієнтів',
  contacts_2:
    'Для всіх видів бізнесу, агентств з дослідження ринку та великих компаній, ділових партнерів та ЗМІ.',
  contacts_3: 'вул. Чигоріна, 61, Київ, Україна',
  contacts_4: 'Для покупців',
  contacts_5:
    'Для місцевих жителів та мандрівників, водіїв та студентів, професійних таємних покупців та домосідів.',
  contacts_6: "Зв'яжіться з нами з вашої країни:",
  contacts_7: 'Італія',
  contacts_8: 'США',
  contacts_9: 'Великобританія',
  contacts_10: 'Швейцарія',
  contacts_11: 'Нідерланди',
  contacts_12: 'Словаччина',
  contacts_13: 'Словенія',
  contacts_14: 'Румунія',
  contacts_15: 'Азербайджан',
  contacts_16: 'Україна',
  contacts_17: 'Казахстан',
  contacts_18: 'Інші країни',
  menu_1: 'CJM',
  menu_2: 'NPS',
  menu_3: 'CSI',
  menu_4: 'BHT',
  menu_5: 'UX-тестування',
  menu_6: 'HR-дослідження',
  menu_7: 'Якісні дослідження',
  menu_8: 'Кількісні дослідження',
  menu_9: 'Таємний покупець',
  menu_10: 'Voicer',
  menu_11: 'Play4Sales',
  menu_12: 'Wantent',
  menu_13: 'Моніторинг цін',
  menu_14: 'Про нас',
  menu_15: 'Контакти',
  menu_16: 'Блог',
  menu_17: 'Методи',
  menu_18: 'Продукти',
  menu_19: 'Shoppers \n platform',
  menu_20: 'CSR',
  nps_passives: 'Пасивні',
  nps_detractors: 'Недоброзичливці',
  nps_promoters: 'Промоутери',
  nps_a_score: 'оцінка',
  area: 'Область',
  products_tabs_1:
    'Для досягнення максимальної ефективності ми також проводимо у комплексі:',
  products_tabs_2: 'Продукти',
  products_tabs_3:
    'Якісне дослідження - це збір та аналіз нечислових даних для розуміння концепцій, думок або досвіду.',
  products_tabs_4:
    "Кількісні методи дослідження - це об'єктивний вимір та числовий аналіз даних, зібраних за допомогою інтерв'ю, анкет та опитувань.",
  our_clients_1: 'Наші клієнти',
  preview_cases_section_1: 'Кейси',
  preview_cases_section_2:
    'Переходьте до кейсів, щоб читати більше цікавих постів',
  blog_1: 'Блог із цікавим контентом',
  blog_2: 'Немає постів, що відповідають вашому запиту.',
  about: 'Про',
  why_us: 'Чому ми',
  about_us: 'Про нас',
  key_features: 'Ключова особливість',
  solutions: 'Рішення',
  monitoring: 'Моніторинг',
  how: 'Як',
  how_it_works: 'Як це працює',
  when_to_use: 'Коли використовувати',
  mystery_shopping: 'Таємний покупець',
  voicer: 'Голос клієнта',
  wantent: 'Тестування відео-контенту',
  play4sales: 'Play4Sales',
  price_monitoring: 'Моніторинг цін',
  service_design: 'Сервіс-дизайн',
  qualitative_research: 'Якісні дослідження',
  quantitative_research: 'Кількісні дослідження',
  cawi: 'CAWI',
  f2f: 'F2F',
  cati: 'CATI',
  hashtag_automotive: '#Автомобілебудування',
  hashtag_retayl: '#Роздрібна торгівля',
  hashtag_all_posts: '#Всі_пости',
  hashtag_feedback: "#Зворотній зв'язок",
  hashtag_4Service_csr: '#4Service_CSR',
  hashtag_cases: '#Кейси',
  hashtag_market_research: '#дослідження ринку',
  more: 'Більше',
  speak_to_an_expert: 'Поговоріть із експертом',
  book_consultancy: 'Замовити консультацію',
  read_all: 'Читати все',
  find_out_more: 'Дізнатися більше',
  address: 'Адреса',
  mon_fri: 'пн-пт',
  phone_number: 'Номер телефону',
  flag: 'прапор',
  scroll_to_left: 'прокрутити вліво',
  online: 'В мережі',
  tel: 'Тел.',
  email: 'Електронна пошта',
  light_it_up: 'Запалюй',
  about_us_60: 'Аліна Андрєєва',
  about_us_61: 'Директор з розвитку бізнесу',
  about_us_61_female: 'Директорка з розвитку бізнесу',
  about_us_62: 'Юлія Кравченко',
  about_us_63: 'Старший менеджер проєкту',
  about_us_63_female: 'Старша менеджерка проєкту',
  about_us_64: 'Ольга Аксонова',
  about_us_65: 'Менеджер з розвитку бізнесу',
  about_us_65_female: 'Менеджерка з розвитку бізнесу',
  about_us_66: 'Желєвський Дмитро',
  about_us_67: 'Операційний директор',
  about_us_roles_1: 'Управління',
  about_us_roles_2: 'Розвиток бізнесу',
  about_us_roles_3: 'Власники продукту',
  ad_testing_1: 'Тестування реклами',
  ad_testing_2:
    'Рекламна кампанія – це величезні інвестиції. Переконайтеся, що ваші оголошення ефективні та забезпечують максимальну рентабельність інвестицій',
  ad_testing_3: 'замок',
  ad_testing_4: 'Галузі, які ми обслуговуємо',
  ad_testing_5:
    '4Service - глобальний холдинг з дослідження клієнтського досвіду',
  ad_testing_6:
    '4Service – глобальний холдинг маркетингових досліджень та CX. Ми пропонуємо експертні послуги зі збору даних для різних підприємств',
  ad_testing_7: 'Магазини самообслуговування',
  ad_testing_8: 'Роздрібна торгівля',
  ad_testing_9: 'Електроніка',
  ad_testing_10: 'Люкс/Преміум',
  ad_testing_11: 'Ресторани та готелі',
  ad_testing_12: 'Автомобільний бізнес',
  ad_testing_13: 'Подорожі та відпочинок',
  ad_testing_14: 'Мода',
  ad_testing_15: 'Банки',
  ad_testing_16: 'Годинники та  ювелірні вироби',
  ad_testing_17: 'Побутові прилади',
  ad_testing_18: 'Член Езомар',
  ad_testing_19:
    'Ми провели дослідження з тестування реклами для різних підприємств у різних галузях',
  ad_testing_20: 'Вам потрібно тестування реклами, якщо ви',
  ad_testing_21: 'Просуваєте новий товар чи послугу',
  ad_testing_22: 'Торкаєтесь делікатної теми',
  ad_testing_23: 'Намагаєтесь охопити нову цільову аудиторію чи ринок',
  ad_testing_24: 'Обговорюєте кілька різних варіантів дизайну',
  ad_testing_25:
    'Потребуєте перевірки концепції для керуючих команд або інвесторів',
  ad_testing_26: 'Ви отримаєте відповіді на запитання',
  ad_testing_27: 'Які рекламні канали найкраще підходять для цих оголошень?',
  ad_testing_28:
    'Ви використовуєте правильні візуальні ефекти щоб донести повідомлення?',
  ad_testing_29: 'Чи резонують Ваші рекламні тексти та повідомлення?',
  ad_testing_30: 'Які рекламні канали найкраще підходять для цих оголошень?',
  ad_testing_31: 'Що привертає їхню увагу в першу чергу?',
  ad_testing_32: 'Чи легко зрозуміти розміщення та макет оголошення?',
  ad_testing_33: 'Ми оцінюємо',
  ad_testing_34: 'Брендинг',
  ad_testing_35:
    "як ваш бренд пов’язаний із рекламою та наскільки він запам'ятовується",
  ad_testing_36: 'Ключові показники',
  ad_testing_37: 'Інтеграція',
  ad_testing_38: 'Визнання',
  ad_testing_39: 'Відповідність',
  ad_testing_40: 'Креативність',
  ad_testing_41: 'Як ваша реклама виділяється та привертає увагу людей',
  ad_testing_42: 'Ключові показники',
  ad_testing_43: 'ВИЗНАННЯ',
  ad_testing_44: 'ВПОДОБАННЯ',
  ad_testing_45: 'МТО',
  ad_testing_46: 'МОТИВАЦІЯ',
  ad_testing_47: 'Клієнський досвід, емоційну залученість',
  ad_testing_48:
    'За допомогою технологій штучного інтелекту та експертів-аналітиків ми аналізуємо реальні емоції людей та отримуємо уявлення про їх сприйняття реклами',
  ad_testing_49: 'Рейтинг',
  ad_testing_50: 'Чоловічий',
  ad_testing_51: 'Жіночий',
  ad_testing_52: 'Високий рівень залучення',
  ad_testing_53: 'Увага',
  ad_testing_54: "Що сподобалося і запам'яталося глядачам?",
  ad_testing_55: 'Актриса',
  ad_testing_56: 'Рівність',
  ad_testing_57: 'Музичний фон',
  ad_testing_58: 'Позитивні емоції',
  ad_testing_59: 'Люди різних рас та зовнішності',
  ad_testing_60: 'Зйомки на відкритій місцевості',
  ad_testing_61: "Що глядачам не сподобалося і не запам'яталося?",
  ad_testing_62: 'Голос диктора не відповідав тому, що відбувалося на екрані',
  ad_testing_63: 'Низький рівень уваги до продукту',
  ad_testing_64: 'Занадто динамічно',
  ad_testing_65: 'Важко зрозуміти',
  ad_testing_66: 'TRP',
  ad_testing_67: 'Враження',
  ad_testing_68: 'Шоу',
  ad_testing_69: 'Перетворення',
  ad_testing_70: 'ЗМІ',
  ad_testing_71:
    "Ми вимірюємо ефективність рекламної кампанії у взаємозв'язку з активністю ЗМІ, розраховуючи вплив інвестицій у ЗМІ в різних каналах на результати, що відслідковуються.",
  ad_testing_72:
    'Ми проводимо рекламні дослідження в будь-якому форматі, на будь-якій платформі чи каналі',
  ad_testing_73: 'Відеоконтент',
  ad_testing_74: 'Зовнішня реклама / Аутдор',
  ad_testing_75: 'Телевізійна реклама',
  ad_testing_76: 'Поліграфія',
  ad_testing_77: 'Цифрові',
  ad_testing_78: 'рекламні кампанії',
  ad_testing_79: 'Реклама на POS-дисплеях',
  ad_testing_80: 'Аніматика',
  ad_testing_81:
    'З нашою допомогою ви можете протестувати свою рекламу на будь-якому етапі',
  ad_testing_82: 'Концепція',
  ad_testing_83: 'які ідеї мають сильніший потенціал?',
  ad_testing_84: 'Виробництво на ранній стадії',
  ad_testing_85: 'яка аніматика піде в продакшн?',
  ad_testing_86: 'Виробництво',
  ad_testing_87: 'як відредагувати відео щоб воно утримувало увагу?',
  ad_testing_88: 'Пре-медіа',
  ad_testing_89:
    'які канали комунікації працюватимуть і на який результат можна очікувати?',
  ad_testing_90: 'Пост-тестування реклами',
  ad_testing_91: 'Виробництво',
  ad_testing_92: 'моя реклама приваблює? чи знижується згодом її ефективність?',
  ad_testing_93: 'Пре-медіа',
  ad_testing_94:
    'наскільки ефективна моя реклама у цифрах? як це співвідноситься з конкуренцією?',
  ad_testing_95: 'Попереднє тестування реклами',
  ad_testing_96: 'Щоб надати вам якісні дані, ми використовуємо',
  ad_testing_97: 'методологію кількісних досліджень',
  ad_testing_98: 'CAWI',
  ad_testing_99: 'методологію якісних досліджень',
  ad_testing_100: 'Фокус-групи',
  ad_testing_101: 'Штучний інтелект',
  ad_testing_102:
    'Wantent - аналіз реакції глядача з метою оцінки ефективності переглянутого матеріалу',
  ad_testing_103: 'Аналітичні звіти, які ви отримаєте',
  ad_testing_104:
    'Наша команда аналітиків, медіаекспертів, маркетологів підготує та надасть вам індивідуальний звіт з докладними рекомендаціями',
  ad_testing_age: 'вік',
  ad_testing_ad: 'Оголошення',
  automotive_industry_price_analysis_1: 'Аналіз цін автомобільної галузі',
  automotive_industry_price_analysis_2:
    'Аналіз цін конкурентів може допомогти запобігти втраті прибутків, збільшити частку ринку та контролювати вашу позицію на ринку. Дозвольте нам допомогти вам у виборі успішної конкурентної стратегії!',
  automotive_industry_price_analysis_3: 'Запит пропозиції',
  automotive_industry_price_analysis_4: 'Наша методологія',
  automotive_industry_price_analysis_5:
    'Автомобільний салон для стильних та елегантних людей',
  automotive_industry_price_analysis_6:
    'Метод Mystery Shopping — це єдиний підхід, який точно відображає реальний досвід клієнта та забезпечує достовірне уявлення про нього.',
  automotive_industry_price_analysis_7:
    'Ми пропонуємо точні цифри та надійні всебічні дані',
  automotive_industry_price_analysis_8:
    'Ми володіємо глобальним покриттям, що охоплює кожну країну світу',
  automotive_industry_price_analysis_9:
    'Наші внутрішні ресурси включають таємних покупців і аудиторів',
  automotive_industry_price_analysis_10: 'Ми перевіряємо якість наших даних',
  automotive_industry_price_analysis_11: 'Наші переваги:',
  automotive_industry_price_analysis_12:
    'Моніторинг цін для конкретних конкурентних моделей (B2B та B2C)',
  automotive_industry_price_analysis_13: 'Моніторинг рівня знижки',
  automotive_industry_price_analysis_14:
    'Моніторинг запасів роздрібних продавців для вашого бренду та бренду конкурентів',
  automotive_industry_price_analysis_15:
    'Моніторинг цін на автомобільну електронну комерцію',
  automotive_industry_price_analysis_16: 'Аналіз цінової комунікації',
  automotive_industry_price_analysis_17:
    'Відстеження витрат на доставку у роздрібних продавців',
  automotive_industry_price_analysis_18:
    'Моніторинг акцій і розпродажів у роздрібних продавців',
  automotive_industry_price_analysis_19: 'Визначення суми щомісячних платежів',
  automotive_industry_price_analysis_20: 'Розрахунок лізингових платежів',
  automotive_industry_price_analysis_21:
    'Аналіз конкретних факторів ціноутворення для електромобілів (EV)',
  automotive_industry_price_analysis_22:
    'Порівняння цін між продажами безпосередньо споживачу та продажами роздрібними магазинами',
  automotive_industry_price_analysis_23:
    'Розробка моделей ціноутворення за підпискою',
  automotive_industry_price_analysis_24:
    '4Service — це міжнародна холдингова компанія, що вже більше 20 років спеціалізується на створенні та поліпшенні клієнтського досвіду (CX)',
  automotive_industry_price_analysis_25:
    'Ви зможете використовувати дані про готовність споживачів платити за різні функції, щоб оптимізувати свою пропозицію та визначити найважливіші характеристики для вашої цільової аудиторії.',
  automotive_industry_price_analysis_26:
    'Аналіз цін в автомобільній промисловості 4Service включає:',
  automotive_industry_price_analysis_27:
    'Найнижчі ціни не завжди є ключем до конкурентоспроможності автомобільних брендів. Дозвольте нам допомогти вам створити довгострокову стратегію ціноутворення',
  automotive_industry_price_analysis_28:
    'клієнтів розуміють, що ціни зростають для всіх торгівельних марок',
  automotive_industry_price_analysis_29: '29',
  automotive_industry_price_analysis_30:
    'клієнтів сказали, що все одно купуватимуть автомобіль, який їм подобається, навіть якщо бренд повідомить їх про майбутнє підвищення ціни,',
  automotive_industry_price_analysis_31: '31',
  automotive_industry_price_analysis_32:
    'людей вважають лізинг і щомісячні платежі найбільш бажаним способом покупки автомобіля',
  automotive_industry_price_analysis_33: '48',
  automotive_industry_price_analysis_34:
    'дилерів згадали про можливість підвищення цін під час комунікації/процесу продажу',
  automotive_industry_price_analysis_35: '47,6',
  automotive_industry_price_analysis_36: 'Ключові показники',
  automotive_industry_price_analysis_37:
    'Ви зрозумієте, як оцінити свій продукт відносно інших транспортних засобів у лінійці.',
  automotive_industry_price_analysis_38:
    'Ви будете використовувати дані про готовність споживачів платити за різні функції, щоб оптимізувати свою пропозицію та визначити найважливіші функції для ваших цільових клієнтів.',
  automotive_industry_price_analysis_39:
    'Ви зможете більш детально зрозуміти свої переваги відносно конкурентів',
  automotive_industry_price_analysis_40:
    'Ви зможете визначити відповідний щомісячний платіж і лізингові ставки.',
  automotive_industry_price_analysis_41:
    'Які переваги ви отримаєте для свого бренду чи дилера:',
  automotive_industry_price_analysis_42: 'Ви подаєте заявку',
  automotive_industry_price_analysis_43:
    "Наш менеджер зв'яжеться з вами, щоб визначити основні цілі",
  automotive_industry_price_analysis_44:
    'Ми проводимо маркетингове дослідження',
  automotive_industry_price_analysis_45:
    'Ми регулярно надаємо вам звіти та рекомендації в онлайн-форматі',
  automotive_industry_price_analysis_46:
    'Автомобіль із наклейкою про розпродаж',
  automotive_industry_price_analysis_47: 'Запускаємо проєкт за 1 день!',
  automotive_industry_price_analysis_48:
    'Ми пропонуємо повний набір звітів, включаючи фотозвіти, звіти PPT і звіти Excel, сегментовані за типом місцевості',
  retail_audit_1: 'Польовий маркетинговий аудит / Аудит роздрібної торгівлі',
  retail_audit_2:
    'Збирайте детальні дані про ваш магазин або бренд, аналізуйте перешкоди зростання та створюйте ефективні стратегії для їх подолання',
  retail_audit_3: 'Створіть запит цінової пропозиції',
  retail_audit_4:
    '4Service проводить аудит магазинів на місці від імені брендів, щоб оцінити ефективність їхніх промоційних стратегій, команд виконавців та маркетингових тактик в досягненні цільового ринку.',
  retail_audit_5:
    'Ми уважно спостерігаємо за виконанням промоційних кампаній і гарантуємо дотримання промоційних принципів.',
  retail_audit_6: 'Top 10',
  retail_audit_7: 'Агентств CX в Європі',
  retail_audit_8: 'років досвіду',
  retail_audit_9: 'покриття країн',
  retail_audit_10: '1,6 мільйона',
  retail_audit_11: 'оцінок щороку',
  retail_audit_12: 'Що ми відстежуємо?',
  retail_audit_13: 'Наявність матеріалів для точок продажу (POS)',
  retail_audit_14:
    'Наявність та активація представників бренду, забезпечення дотримання запланованого графіка',
  retail_audit_15:
    'Оцінка залучення представників бренду до кампаній підтримки',
  retail_audit_16:
    'Оцінка взаємодії зі споживачами, забезпечення дотримання промоційних принципів та сутності бренду',
  retail_audit_17: 'Заходи запобігання шахрайству',
  retail_audit_18:
    'Ваш бізнес може використовувати результати польового маркетингового аудиту для:',
  retail_audit_19: 'Оцінки відповідності бюджету та процесів',
  retail_audit_20: 'Порівняння фактичних результатів із очікуваними цілями',
  retail_audit_21: 'Покращення ефективності маркетингових кампаній ',
  retail_audit_22: 'Стратегічного планування на майбутнє',
  retail_audit_23: 'Виявлення можливостей зниження витрат',
  retail_audit_24: 'Покращення продажів та рівня конверсії',
  retail_audit_25: 'Оптимізації повернення інвестицій (ROI)',
  retail_audit_26:
    'ВОНИ ОБРАЛИ 4SERVICE ЯК СВОГО ТОРГІВЕЛЬНОГО ІНВЕСТИЦІЙНОГО ПАРТНЕРА',
  retail_audit_27:
    'Відомий міжнародний виробник тютюнової продукції уклав партнерство з 4Service для підвищення ефективності своїх брендів та поліпшення роботи в магазинах. Наші команди здійснюють аудит маркетингу на місці та надають звіти про споживчий досвід у реальному часі',
  retail_audit_28: 'підвищення точності планування агентства з філд-маркетингу',
  retail_audit_29: 'посилення передачі змісту бренда',
  retail_audit_30:
    'залучення споживачів та підвищення активності (активізація споживачів)',
  retail_audit_31:
    'підвищення точності збору даних та відповідність вимогам Загального регламенту про захист даних (GDPR)',
  retail_audit_32: 'Які цінні інсайти ми зазвичай отримуємо:',
  retail_audit_33:
    'Відсутність послідовності або навіть конфлікти між умовами SLA , що призводять до значних зайвих витрат для клієнта',
  retail_audit_34:
    'Плутанина з видами сервісів, що надаються, яка призводить до неправильного розрахування вартості послуг',
  retail_audit_35:
    'Польові роботи не проводяться (включно із ситуаціями, коли на заплановану локацію або POS не призначається персонал)',
  retail_audit_36:
    'Розрахунки проводяться на підставі оцінки, а не фактичних показників, і не узгоджуються',
  retail_audit_37:
    'Промо-інструкція використовується неправильно, із наданням некоректних даних',
  retail_audit_38: 'Як ми працюємо?',
  retail_audit_39:
    'Наші аудитори відвідують визначені точки продажу (POS) на основі плану агентства.',
  retail_audit_40:
    'Вони оцінюють ключові показники ефективності (KPI), які зазначені в технічному завданні, такі як наявність POS, залучення представника бренду, дотримання промоційних вказівок та заходів запобігання шахрайству.',
  retail_audit_41: 'Валідація:',
  retail_audit_42: 'Ми збираємо та узагальнюємо отримані дані.',
  retail_audit_43: 'Складається комплексний звіт.',
  retail_audit_44:
    'Наша команда перевіряє всі опитування на достовірність, включаючи відповідність технічному завданню проєкта та будь-які додаткові коментарі.',
  retail_audit_45: 'Онлайн-система звітності:',
  retail_audit_46:
    'Заповнені та перевірені анкети завантажуються на наш онлайн-портал протягом 36 годин.',
  retail_audit_47:
    'Клієнти мають доступ до особистого облікового запису на порталі, що дає змогу в будь-який час моніторити хід виконання проєкту.',
  retail_audit_48: 'Надається заключний звіт з результатами та рекомендаціями.',
  retail_audit_49: 'Зверніться до експерта',
  retail_audit_50: 'Наші переваги:',
  facility_check_audit_1: 'Заклад',
  facility_check_audit_2: 'Аудит закладу',
  facility_check_audit_3:
    'Підвищуйте рівень брендового досвіду в кожній деталі',
  facility_check_audit_4: 'Дізнайтеся про наш підхід',
  facility_check_audit_5: 'Про нас',
  facility_check_audit_6: 'Хто ми такі',
  facility_check_audit_7:
    'Як глобальний лідер у сфері досвіду споживача, 4Service надає комплекс послуг для допомоги брендам створювати та надавати виняткові враження своїм клієнтам.',
  facility_check_audit_8: 'Наша послуга перевірки закладів',
  facility_check_audit_9:
    'призначена для того щоб допомогти вам переконатися, що ваші локації та брендова присутність у роздрібних магазинах відповідають вашим брендовим стандартам і створюють позитивне враження для клієнтів.',
  facility_check_audit_10: 'Рішення 1',
  facility_check_audit_11: 'Аудит закладу',
  facility_check_audit_12:
    ' - це ретельне дослідження, яке оцінює відповідність ваших роздрібних закладів або стендів стандартам вашого бренду. Ми не тільки перевіряємо фізичні атрибути, але також визначаємо, як ці елементи впливають на сприйняття вашого бренду вашими цінними клієнтами.',
  facility_check_audit_13: 'Два рішення',
  facility_check_audit_14: 'Два методи виконання',
  facility_check_audit_15: 'Метод',
  facility_check_audit_16: 'Перевірка закладу експертними аудиторами',
  facility_check_audit_17: 'Валідація аудиту закладу',
  facility_check_audit_18: 'Звіти',
  facility_check_audit_19:
    'Ви отримаєте звіти, що містять візуальні докази і статистику, доступні впродовж 48 годин',
  facility_check_audit_20:
    'Якісно виконаний аудит закладу може принести низку переваг для вашого бренду, включаючи:',
  facility_check_audit_21: 'Покращення конверсії клієнтів:',
  facility_check_audit_22:
    'Позитивний досвід клієнтів може призвести до збільшення продажів та повторних покупок. Наші аудити можуть допомогти виявити та вирішити будь-які проблемні місця, які мають вплив на задоволення клієнтів.',
  facility_check_audit_23: 'Покращення іміджу бренду:',
  facility_check_audit_24:
    'Ваші фізичні місця розташування є ключовою точкою контакту для клієнтів, і вони можуть відігравати значну роль у формуванні іміджу вашого бренду. Наші аудити можуть допомогти вам переконатися, що ваші локації відповідають цінностям вашого бренду і створюють позитивне враження для клієнтів.',
  facility_check_audit_25: 'Чому ми?',
  facility_check_audit_26: 'Чому аудит закладу важливий?',
  facility_check_audit_27:
    'Якісно виконаний аудит закладу може принести ряд переваг для вашого бренду, включаючи:',
  facility_check_audit_28: 'Покращення іміджу бренду:',
  facility_check_audit_29:
    'Ваші фізичні локації є ключовою точкою контакту для клієнтів, і вони можуть відігравати значну роль у формуванні іміджу вашого бренду. Наші аудити можуть допомогти вам переконатися, що ваші локації відповідають цінностям вашого бренду і створюють позитивне враження для клієнтів.',
  facility_check_audit_30: 'Покращення конверсії клієнтів:',
  facility_check_audit_31:
    'Позитивний досвід клієнтів може призвести до збільшення продажів та повторних покупок. Наші аудити можуть допомогти виявити та вирішити будь-які проблемні місця, які мають вплив на задоволення клієнтів.',
  facility_check_audit_32: 'Хто цього потребує',
  facility_check_audit_33: 'Кому потрібна перевірка закладів?',
  facility_check_audit_34:
    'Аудит закладу - це цінна послуга для будь-якого бренду з фізичними локаціями та фізичною присутністю у реальному світі. Це особливо важливо для брендів, які хочуть:',
  facility_check_audit_35: 'Забезпечити відповідність брендовим стандартам:',
  facility_check_audit_36:
    'Наші аудити можуть допомогти виявити будь-які місця, де ваші заклади можуть відхилятися від ваших брендових стандартів.',
  facility_check_audit_37: 'Покращити досвід клієнтів:',
  facility_check_audit_38:
    'Наші аудити можуть допомогти виявити можливості покращення досвіду клієнтів у ваших закладах.',
  facility_check_audit_39: 'Побудувати міцніший бренд:',
  facility_check_audit_40:
    'Наші аудити можуть допомогти вам переконатися, що ваші фізичні локації створюють позитивне враження для клієнтів.',
  facility_check_audit_41: 'Таємний покупець робить фотографію локації.',
  facility_check_audit_42: 'Потім він заповнює анкету та додає фото.',
  facility_check_audit_43:
    'Валідатор отримує фото та перевіряє їх на відповідність стандартам.',
  facility_check_audit_44: 'Перевірка закладу експертними аудиторами',
  facility_check_audit_45: 'Етап підготовки:',
  facility_check_audit_46: 'Спільне затвердження графіку аудиту',
  facility_check_audit_47:
    'Дилери готуються та повідомляються про майбутній аудит',
  facility_check_audit_48: 'Навчання аудиторів:',
  facility_check_audit_49:
    'Наші досвідчені тренери 4Service надають аудиторам рекомендації, відповідно до вимог бренду',
  facility_check_audit_50: 'Етап аудиту закладу:',
  facility_check_audit_51:
    'Аудитори, забезпечені спеціальними листами дозволу, відвідують локації',
  facility_check_audit_52:
    'Ретельне вивчення кожного пункту, дотримуючись рекомендацій',
  facility_check_audit_53:
    'Візуальна документація, разом з оцінками відповідності вимогам головного офісу',
  facility_check_audit_54: 'Заключний звіт у форматі PPT',
  facility_check_audit_55:
    "Всебічний звіт у форматі PowerPoint, який роз'яснює результати дослідження",
  facility_check_audit_56:
    'Захоплююча презентація результатів аудиту онлайн/офлайн',
  facility_check_audit_57: 'Валідація аудиту закладу',
  facility_check_audit_58: 'Кроки:',
  facility_check_audit_59: 'Етап аудиту закладу:',
  facility_check_audit_60:
    'Представники бренду використовують додаток 4Service для фотозйомки в локації',
  facility_check_audit_61:
    'Дотримання суворих візуальних та описових критеріїв',
  facility_check_audit_62: 'Етап валідації:',
  facility_check_audit_63:
    'Компетентні валідатори ретельно переглядають кожен звіт',
  facility_check_audit_64:
    'Всебічне порівняння фотографій зі стандартами бренду',
  facility_check_audit_65:
    'Анотації та висновки включаються до звіту для зрозумілості',
  facility_check_audit_66:
    'Пряма комунікація з аудиторами для уточнень, якщо це потрібно',
  facility_check_audit_67: 'Фаза звітування:',
  facility_check_audit_68:
    'Звіти, що містять візуальні докази і статистику, доступні протягом двох днів',
  facility_check_audit_69: "Зв'яжіться з нами сьогодні",
  cx_solution_1: 'Ми не просто знаходимо інсайти,',
  cx_solution_2: 'Ми підносимо ваш бізнес до нових вершин',
  cx_solution_3:
    'Експерти з понад 20-річним досвідом роботи з клієнтами виявляють складні рішення та надають вам конкретний план дій, який сприяє вашому зростанню',
  cx_solution_4:
    'Відкрийте унікальний комплекс послуг у майбутньому-стійкому рішенні',
  cx_solution_5: 'Все-в-одному рішенні CX',
  cx_solution_6: 'Тестування реклами',
  cx_solution_7: 'Штучний інтелект та нейромаркетинг',
  cx_solution_8: 'Голос клієнта',
  cx_solution_9: 'Деск-дослідження',
  cx_solution_10: 'Карта подорожі клієнта',
  cx_solution_11: 'Таємний шопінг',
  cx_solution_12: 'Консалтинг CX',
  cx_solution_13: 'Слухання в соціальних мережах',
  cx_solution_14: 'Кількісні та якісні дослідження',
  cx_solution_15: 'Дизайн послуг',
  cx_solution_16: 'Маркетингова стратегія',
  cx_solution_17: 'Тестування UX/UI',
  cx_solution_18: 'Інсайти про аудиторію',
  cx_solution_19: 'Ми вдячні за можливість сприяти вашому успіху',
  cx_solution_20:
    'Ми налаштовуємо та поєднуємо інструменти та послуги, базуючись на цілях і завданнях вашого бізнесу, щоб отримати певні результати',
  cx_solution_21: 'Всебічні дослідження',
  cx_solution_22:
    'Підсильте ваше прийняття рішень, забезпечуючи конкурентну перевагу на ринку, орієнтованому на наявні дані.',
  cx_solution_23:
    'Якісні дослідження - це збір та аналіз нецифрових даних для розуміння концепцій, думок або досвіду',
  cx_solution_24:
    "Методи кількісних досліджень - це об'єктивне вимірювання та числовий аналіз даних, отриманих за допомогою інтерв'ю, анкет і опитувань",
  cx_solution_25:
    'Ми використовуємо особливий набір інструментів на основі ваших конкретних вимог',
  cx_solution_26: 'Інструменти',
  cx_solution_27: 'CAWI',
  cx_solution_28:
    "Комп'ютерне веб-опитування - це важливий інструмент у нашому арсеналі, який дозволяє проводити опитування та збирати дані через онлайн-платформи за допомогою спеціалізованого програмного забезпечення, забезпечуючи ефективний та точний збір даних для потреб наших клієнтів в маркетингових дослідженнях",
  cx_solution_29: 'CATI',
  cx_solution_30:
    "Комп'ютерне телефонне опитування - це метод, який ми використовуємо для проведення опитувань та збору цінних даних по телефону, використовуючи спеціалізоване програмне забезпечення для оптимізації процесу та забезпечення точності у наших маркетингових дослідженнях",
  cx_solution_31: 'F2F',
  cx_solution_32:
    "Особистий контакт - це невід'ємна частина нашого підходу, який дозволяє прямі взаємодії та персоналізовані контакти з клієнтами та споживачами, сприяючи довірі, розумінню та ефективній комунікації, які є ключовими для побудови тривалих відносин та забезпечення успіху бізнесу",
  cx_solution_33: 'Деск-дослідження',
  cx_solution_34:
    'Деск-дослідження включає всебічний збір та аналіз даних, що є фундаментальним кроком у нашому дослідницькому процесі для збору інсайтів та визначення стратегічних рішень для наших клієнтів',
  cx_solution_35: 'SWOT-аналіз',
  cx_solution_36:
    'SWOT-аналіз - це стратегічний інструмент оцінки, який допомагає нашим клієнтам ідентифікувати внутрішні сильні сторони та слабкі місця, а також зовнішні можливості та загрози, надаючи цінні інсайти для прийняття рішень та розробки ефективних стратегій бізнесу',
  cx_solution_37: 'Етнографічні дослідження',
  cx_solution_38:
    'Вивчення людей і культур шляхом поглибленого занурення та спостереження в їх природному середовищі, що часто призводить до глибоких інсайтів в розумінні поведінки, переконань та соціальної динаміки',
  cx_solution_39: "Глибинні інтерв'ю",
  cx_solution_40:
    "Глибинні інтерв'ю - це якісний метод дослідження, який ми використовуємо, включаючи ретельні персональні обговорення з учасниками для того, щоб глибоко підійти до їхніх поглядів, досвіду та думок, що надає багаті інсайти та розуміння для досягнення цілей дослідження та процесів прийняття рішень нашими клієнтами",
  cx_solution_41: 'Сесії генерації ідей',
  cx_solution_42:
    "Сесії генерації ідей - це спільні сесії мозкового штурму, які ми проводимо, об'єднуючи різноманітні перспективи та експертизу для генерації творчих ідей та рішень для вирішення викликів чи можливостей наших клієнтів, сприяючи інноваціям та просуванню стратегічних ініціатив вперед",
  cx_solution_43: 'Профілі покупців',
  cx_solution_44:
    'Профілі покупців - це детальні профілі, які ми розробляємо, уявляючи цільових замовників наших клієнтів на основі досліджень та аналізу даних, що дозволяє нам краще розуміти їхні потреби, вподобання та поведінку, та налаштовувати наші стратегії та комунікації для ефективного залучення та спілкування з ними, що в кінцевому підсумку призводить до успіху бізнесу',
  cx_solution_45: 'Таємний шопінг',
  cx_solution_46:
    'Таємний шопінг - це стратегічна послуга, яку ми пропонуємо, для вимірювання та контролю взаємодій між клієнтом та компанією під час попередньо визначеного сценарію',
  cx_solution_47: 'Супровідна перевірка',
  cx_solution_48:
    'Супровідна перевірка - це спеціалізована форма таємного шопінгу, де наші оцінювачі супроводжують клієнтів протягом всього їхнього сервісного досвіду, забезпечуючи всебічні інсайти в кожному аспекті подорожі клієнта, що дозволяє нашим клієнтам глибше розуміти їхнє обслуговування та робити обґрунтовані покращення для поліпшення загального рівня лояльності клієнтів',
  cx_solution_49: 'Аналіз збору відгуків',
  cx_solution_50:
    'Аналіз збору відгуків - це важливий процес, який ми проводимо, включаючи систематичний збір та аналіз відгуків клієнтів з різних джерел, таких як опитування, відгуки та соціальні медіа, що дозволяють нам видобувати цінні інсайти, виявляти тенденції та робити рекомендації на основі даних для наших клієнтів для покращення їхніх продуктів, послуг та загального досвіду клієнтів',
  cx_solution_51: 'Фокус-групи',
  cx_solution_52:
    "Фокус-групи - це динамічні дослідницькі сесії, які ми проводимо, об'єднуючи різноманітну групу учасників для обговорення конкретних тем або продуктів у глибину, що дозволяє нам збирати якісні інсайти, виявляти взаємозв'язки, вподобання та сприйняття, та досліджувати ідеї та концепції, що в кінцевому підсумку інформують наших клієнтів про розробку стратегій подальших дій.",
  cx_solution_53: '20 років створення виняткових клієнтських досвідів',
  cx_solution_54:
    'Переконайтеся, що ваше повідомлення захоплює та резонує з вашою аудиторією.',
  cx_solution_55:
    'Ми присвячені досягненню результатів, які перевищують ваші очікування',
  cx_solution_56:
    'Ми знаходимо комплексні рішення, що сприяють вашому зростанню',
  cx_solution_57:
    'Підвищуйте свій клієнтський досвід за допомогою нашої методології ',
  cx_solution_58:
    'Карта подорожі клієнта - це визначення цільової аудиторії, розуміння їхніх потреб та оточення, і як результат - створення плану для надання привабливого досвіду',
  cx_solution_59: 'BHT',
  cx_solution_60:
    'Дослідження стану бренду може вимірювати, як відбувається взаємодія бренду з аудиторією, його використання, позиціонування та результативність',
  cx_solution_61: 'CSI',
  cx_solution_62:
    'Індекс задоволеності клієнта - це оцінка, що вказує, наскільки задоволені ваші клієнти продуктами/послугами компанії.',
  cx_solution_63:
    'Моніторинг цін - це процес збору, обробки та аналізу цін конкурентів',
  cx_solution_64: 'NPS',
  cx_solution_65:
    'Net Promoter Score - це золотий стандарт метрики клієнтського досвіду, заснований на одному питанні',
  cx_solution_66: 'HR-дослідження',
  cx_solution_67:
    'Дослідження у галузі людських ресурсів використовується для оцінки практик та результативності у галузі HR та надає детальний аналіз поточного розвитку та управління',
  cx_solution_68:
    'Тестування зручності використання - це спостереження за тим, як реальні люди взаємодіють з веб-сайтом, додатком або іншим продуктом та спостереження їхньої поведінки та реакціями на нього',
  cx_solution_69: 'Інше',
  cx_solution_70: 'Експерти обирають найкращу методологію для вашого бізнесу',
  cx_solution_71: 'Давайте розпочнемо',
  cx_solution_72: '1 - ПЕРШОЧЕРГОВА ЗУСТРІЧ',
  cx_solution_73:
    'Під час першої зустрічі ми фокусуємося на ключових задачах та передбачуємо результат',
  cx_solution_74: '2 - РОЗРОБКА КОНЦЕПЦІЇ',
  cx_solution_75:
    'Аналітичний відділ 4Service агрегує дані, координує структуру звіту. Потім ми представляємо результати клієнту, доповнені інсайтами та рекомендаціями для впровадження.',
  cx_solution_76: '3 - ПОЛЬОВІ РОБОТИ',
  cx_solution_77:
    'Для проведення дослідження ми залучаємо учасників, проводимо інтерв’ю та інші основні етапи дослідження. Клієнт отримує транскрибовані аудіо-відео інтерв’ю, а також стислі підсумки.',
  cx_solution_78: '4 - АНАЛІТИКА',
  cx_solution_79:
    'Аналітичний відділ 4Service агрегує дані, координує структуру звіту. Потім ми представляємо результати клієнту, доповнені інсайтами та рекомендаціями для впровадження.',
  cx_solution_80: '5 - МАЙСТЕРНЯ',
  cx_solution_81:
    'Проведення обговорення з клієнтом стосовно плану впровадження',
  cx_solution_82: 'Дізнайтеся більше про наші найкращі практики',
  cx_solution_83:
    'Карта подорожі клієнта дозволяє швидко дослідити всі комунікаційні канали, виявити можливі недоліки на важливих точках дотику та глибоко розібратися у сферах, які можуть відхилятися від запланованого курсу.',
  cx_solution_84: 'Коли це варто використовувати?',
  cx_solution_85:
    'Створення персоналізованої рекламної кампанії на основі реальних даних про аудиторію',
  cx_solution_86:
    'Виявлення спонукань різних сегментів цільової аудиторії та задоволення їхніх потреб',
  cx_solution_87:
    'Точне фокусування на кожній групі та отримання максимальної конверсії',
  cx_solution_88:
    'Техніка супроводу дозволяє оцінити можливості оптимальних способів досягнення ваших цілей.',
  cx_solution_89: 'Тестування нового веб-сайту чи продукту перед запуском',
  cx_solution_90: 'Перевірка розроблених процесів перед впровадженням змін',
  cx_solution_91:
    'Оцінка веб-сайту чи продукту в умовах зниження рівня продажів',
  cx_solution_92: 'Запуск нового продукту, відповідного вимогам споживачів',
  cx_solution_93:
    'Вивчення конкуруючих компаній для забезпечення провідної позиції на ринку',
  cx_solution_94:
    'Ми пропонуємо комплексний підхід 360° до створення моделі обслуговування, орієнтованої на клієнтів.',
  cx_solution_95: 'Навчання та розвиток персоналу',
  cx_solution_96: 'Покращення стандартів обслуговування',
  cx_solution_97: 'Збільшення потоку клієнтів та продажів',
  cx_solution_98: 'Встановлення KPI та системи мотивації для персоналу',
  cx_solution_99: 'Дізнайтеся про наші успішні історії',
  cx_solution_100: 'Автомобільна галузь',
  cx_solution_101: 'Тютюнова галузь',
  cx_solution_102: 'Роздрібна торгівля',
  cx_solution_103: 'Якісна фаза',
  cx_solution_104: 'Кількісна фаза (сегментація)',
  cx_solution_105: 'Фокус-групи',
  cx_solution_106: 'Охоплення аудиторії онлайн по всьому світу',
  cx_solution_107: 'Сполучення технік під час інтерв’ю',
  cx_solution_108: 'Лише відкриті питання',
  cx_solution_109: 'Підтримка респондентів цілодобово',
  cx_solution_110: 'Збереження важливих деталей та реальної карти шляху',
  cx_solution_111: 'Дослідження ринку',
  cx_solution_112: 'Діагностика компанії та конкурентів',
  cx_solution_113: 'Оновлення моделей обслуговування та нових бізнес-процесів',
  cx_solution_114: 'Просування клієнтоспрямованого обслуговування',
  cx_solution_115:
    'Впровадження змін та контроль за розвитком стратегій обслуговування',
  usage_attitude_1: "Використання та взаємозв'язок (U&A)",
  usage_attitude_2:
    'Отримайте всебічне уявлення про те, як цільова аудиторія сприймає, використовує та взаємодіє з вашим брендом.',
  usage_attitude_3: 'Зверніться до експерта',
  usage_attitude_4: 'Про',
  usage_attitude_5:
    '<b>Дослідіть звички споживачів</b>, щоб розкрити їхні вподобання та глибинні впливи',
  usage_attitude_6:
    "<b>Відкрийте 'чому'</b> за вибором споживачів за допомогою нашого детального аналізу",
  usage_attitude_7:
    '<b>Розумійте, як</b> клієнти взаємодіють з вашими продуктами для стратегічних ідей',
  usage_attitude_8:
    '<b>Отримайте уявлення про</b> поведінку, звички та вподобання споживачів для обґрунтованих рішень',
  usage_attitude_9: 'Досвід',
  usage_attitude_10:
    'Експерти 4Service з понад 20-річним досвідом у обслуговуванні клієнтів розробляють унікальні рішення, які сприяють росту вашого бізнесу',
  usage_attitude_11: 'Зв’яжіться з нами',
  usage_attitude_12: 'Для кого',
  usage_attitude_13: "Хто виграє від використання бренду та зв'язку з ним?",
  usage_attitude_14: 'Різні галузі та сектори обслуговування зацікавлені в:',
  usage_attitude_15: '<b>Визнанні</b> потреб та больвих точок клієнтів',
  usage_attitude_16:
    '<b>Виявленні</b> можливостей для збільшення споживчої активності клієнтів',
  usage_attitude_17:
    '<b>Розробці</b> нових категорій продуктів чи маркетингових стратегій',
  usage_attitude_18:
    '<b>Отриманні</b> глибокого розуміння конкурентного середовища',
  usage_attitude_19:
    'Методологія обирається індивідуально, в залежності від запиту клієнта',
  usage_attitude_20:
    '<b>Якісне дослідження</b> передбачає збір та аналіз нечислових даних для усвідомлення концепцій, думок або досвіду.',
  usage_attitude_21:
    "<b>Кількісні дослідження</b> передбачають об’єктивне вимірювання та числовий аналіз даних, отриманих за допомогою опитувань, анкет або інтерв'ю.",
  usage_attitude_22:
    '<b>Після збору даних</b> експерти аналізують інформацію та роблять висновки, щоб надати вам найкращі рекомендації.',
  usage_attitude_23: 'Хто цього потребує',
  usage_attitude_24: 'Ваша історія успіху починається тут',
  usage_attitude_25:
    '<b>Ви отримаєте дослідження</b> з цінними уявленнями, які забезпечують стратегічне прийняття рішень, забезпечуючи дії на основі глибокого розуміння споживацької поведінки.',
  usage_attitude_26:
    '<b>Дослідження надає міцну основу для вдосконалення</b> та оптимізації стратегій бренду, наближуючи їх до вподобань та ставлення споживачів.',
  usage_attitude_27: 'Рішення',
  usage_attitude_28: 'Дослідіть безліч можливостей з нашими рішеннями',
  usage_attitude_29: 'Переваги продукту у межах категорії',
  usage_attitude_30:
    'Визначте найпопулярніші вподобання споживачів у різних категоріях, включаючи харчування, напої, одяг, електроніку та інше.',
  usage_attitude_31: 'Споживання / використання',
  usage_attitude_32:
    'Проаналізуйте частоту споживання чи використання конкретних продуктів / послуг та визначте випадки та моменти, пов’язані з їх використанням.',
  usage_attitude_33: 'Поведінка під час покупки',
  usage_attitude_34:
    'Дослідіть канали, якими користуються клієнти для своїх покупок, та визначте джерела інформації, на які вони покладаються.',
  usage_attitude_35: 'Мотивації та потреби споживачів',
  usage_attitude_36:
    'Вивчіть мотивації та перешкоди, які впливають на споживання. Оцініть фактори, що впливають на покупки споживачів. Дослідіть сприйняття категорії продуктів та проаналізуйте, як сприймається ціна.',
  usage_attitude_37: 'Наш підхід',
  usage_attitude_38: 'Дослідження використання та ставлення до бренду',
  usage_attitude_39: 'Інформація',
  usage_attitude_40:
    'Проведення опитувань за допомогою як кількісних, так і якісних методів',
  usage_attitude_41: 'Почнемо',
  usage_attitude_42:
    'Встановлення цілей та визначення методів на основі індивідуальних запитів',
  usage_attitude_43: 'Аналіз',
  usage_attitude_44: 'Використання складних технік нашими експертами',
  usage_attitude_45:
    'Ми використовуємо окремий набір інструментів з урахуванням ваших конкретних вимог',
  usage_attitude_46: 'СATI',
  usage_attitude_47:
    'Комп’ютерно-підтримане телефонне опитування - це метод, який ми використовуємо для проведення опитувань та збору цінних даних по телефону, за допомогою спеціалізованого програмного забезпечення для оптимізації процесу та забезпечення точності в наших дослідженнях ринку.',
  usage_attitude_48: 'CAWI',
  usage_attitude_49:
    'Комп’ютерно-підтримане веб-опитування є важливим інструментом у нашому арсеналі, що дозволяє проводити опитування та збирати дані через Інтернет за допомогою спеціалізованого програмного забезпечення, забезпечуючи ефективний та точний збір даних для потреб наших клієнтів у дослідженнях ринку.',
  usage_attitude_50: 'САРІ',
  usage_attitude_51:
    'Комп’ютерно-підтримане особисте опитування - це метод, який ми використовуємо для оптимізації процесів збору даних, роблячи інтерв’ю більш плавними та забезпечуючи точні інсайти для вашого бізнесу.',
  usage_attitude_52: 'Фокус-групи',
  usage_attitude_53:
    'Фокус-групи — це динамічні дослідницькі сесії, які ми проводимо, об’єднуючи різноманітну групу учасників для поглибленого обговорення конкретних тем або продуктів, що дозволяє нам збирати якісну інформацію, розкривати ставлення, уподобання та сприйняття, а також досліджувати ідеї та концепції, зрештою, інформувати наших клієнтів про процеси прийняття рішень і розробки стратегії.',
  usage_attitude_54: 'F2F',
  usage_attitude_55:
    'Особисте спілкування є невід’ємною частиною нашого підходу, полегшуючи безпосередню взаємодію та персоналізоване спілкування з клієнтами та замовниками, тим самим зміцнюючи довіру, розуміння та ефективну комунікацію, необхідну для побудови тривалих стосунків і стимулювання фокус-груп успіху бізнесу',
  usage_attitude_56: 'Етнографічне дослідження',
  usage_attitude_57:
    'Вивчення людей і культур через занурення та спостереження в їхньому природному середовищі, що часто веде до глибокого розуміння поведінки, переконань і соціальної динаміки.',
  parthership: 'Партнерство',
  minimum_number_characters: 'мінімальна кількість 2 символи',
  request_details: 'Деталі запиту',
  footer_7: 'Дослідження ринку',
  main_43: 'Зосередженість на якості',
  main_44: 'Ми контролюємо якість',
  main_45:
    'коли йдеться про дані досліджень. Ми робимо всі набори даних доступними для клієнтів',
  main_46: 'Економічно ефективний',
  main_47: 'Наш досвід, ресурси та оптимізація',
  main_48: 'бізнес-процеси дозволяють нам економити ваші гроші та час',
  main_49: 'Розумні рішення',
  main_50: 'Ми використовуємо різноманітні методи',
  main_51: 'і набори інструментів для вирішення ваших конкретних завдань',
  menu_22: "Контрольний аудит об'єкта",
  menu_23: 'Сервіс-дизайн',
  menu_24: 'CX',
  thank_you_page:
    "Дякуємо, що приділили нам час! Наш менеджер зв'яжеться з вами найближчим часом.",
  back_main: 'Повернутися на головну',
  opportunities_1: 'Дізнайтеся більше про наші кейси',
  opportunities_2: 'Відкрийте',
  opportunities_3: 'для себе',
  opportunities_4: 'можливості',
  opportunities_5:
    'міжнародного бізнесу спільно з дослідницьким холдингом 4Service',
  opportunities_6:
    'Що <span>products</span> чи можемо ми посилити ваш бізнес за допомогою?',
  opportunities_7: 'CX',
  opportunities_8: 'Комплексні індивідуальні рішення для бізнесу',
  opportunities_9: 'Всі види таємних покупців',
  opportunities_10:
    'Ми реалізуємо програми «Таємний працівник», «Таємний дзвінок», «Таємний покупець предметів розкоші», «Автомобільний таємний покупець» та інші',
  opportunities_11: 'Індивідуальні ІТ-рішення для бізнесу',
  opportunities_12:
    "Власні ІТ-рішення зі штучним інтелектом, платформи для аналітики зворотного зв'язку та транскрипції дзвінків",
  opportunities_13: 'Дослідження ринку',
  opportunities_14:
    "Для будь-яких бізнес-запитів (відстеження здоров'я бренду, сегментація, NPS, eNPS, CJM, тестування продуктів та реклами тощо)",
  opportunities_15: 'Аудити',
  opportunities_16:
    'Ми проводимо різноманітні сервісні аудити, а також моніторинг цін (офлайн, для конкретних ніш тощо)',
  opportunities_17: 'Сервіс-дизайн',
  opportunities_18:
    '4Service - сертифіковані партнери DesignThinkers Academy, що проводять корпоративне навчання для команд та регулярно проводять воркшопи для бізнесу',
  opportunities_19:
    'Ми ділимося своїм досвідом, але <span>поважаємо і не порушуємо</span> ваш бізнес-процес.',
  opportunities_20:
    "Ми знаємо, з якими труднощами стикається бізнес на шляху розвитку, і знаємо, як подолати ці бар'єри завдяки успішному міжнародному досвіду. Ви отримуєте не лише досвід, а й ресурси.",
  opportunities_21: 'Запрошуємо до співпраці!',
  opportunities_22:
    'Ми пропонуємо <span>партнерську програму</span> для вашого бізнесу',
  opportunities_23:
    'Під всесвітньо відомим брендом 4Service ви можете позитивно впливати на залучення нових клієнтів.',
  opportunities_24:
    'Наші партнери отримують комплексну підтримку, включаючи навчання, маркетингові матеріали та оперативну допомогу.',
  opportunities_25:
    'Співпраця з нами дозволить реалізувати ваші плани, не порушуючи поточний бізнес-процес.',
  opportunities_26: 'Наші <span>офіси</span>',
  opportunities_27: 'Наші клієнти',
  opportunities_28: 'Ми маємо понад 20 років досвіду у сфері CX',
  opportunities_29: 'Охоплені країни',
  opportunities_30: 'тисяча',
  opportunities_31: 'Унікальні проєкти',
  opportunities_32: 'мільйон',
  opportunities_33: 'Візити таємних покупців',
  opportunities_34: 'входить до ТОП-10 європейських CX-агенцій',
  opportunities_35: 'Ваші можливості з 4Service',
  opportunities_36: 'Збільшення прибутку',
  opportunities_37: 'Ви отримаєте доступ до міжнародних замовлень',
  opportunities_38: 'Унікальний підхід до CX',
  opportunities_39: 'Підтримка експертів з 20-річним досвідом',
  opportunities_40: 'Підтримка',
  opportunities_41: 'Наша команда підтримає вас у досягненні результатів',
  opportunities_42: 'Продажі',
  opportunities_43: 'Ми допоможемо вам налаштувати систему продажів',
  opportunities_44: 'Доступ до програмного забезпечення',
  opportunities_45:
    'Ви отримаєте доступ до всіх сертифікованих ІТ-інструментів',
  opportunities_46: 'Навчання',
  opportunities_47:
    'Ми не тільки навчимо ваш персонал, але й продовжимо надавати консультаційні послуги',
  opportunities_48: 'Хто підходить для партнерства?',
  opportunities_49: "Чи пов'язаний ваш бізнес з маркетинговими дослідженнями?",
  opportunities_50: 'Або ви плануєте спробувати себе в новій сфері',
  opportunities_51: 'Чи плануєте ви:',
  opportunities_52: 'Впроваджувати інновації',
  opportunities_53: 'Робота з міжнародними брендами',
  opportunities_54: 'Розширюйте свій асортимент продукції',
  opportunities_55: 'Зміцніть свою позицію на ринку?',
  opportunities_56: 'Навчіть своїх співробітників',
  opportunities_57: 'Масштабуйте свій бізнес',
  opportunities_58: 'Наші ресурси = Ваші ресурси',
  opportunities_59:
    'Враховуючи наш великий досвід, ми готові поділитися ним з вами на першому етапі співпраці',
  opportunities_60: 'Аналіз даних:',
  opportunities_61: 'Міжнародна команда аналітиків',
  opportunities_62: 'Методи обробки даних - SPSS, Power BI',
  opportunities_63: 'Інноваційні методології та широкий спектр методик',
  opportunities_64: 'Звітність у будь-якому форматі',
  opportunities_65: 'Програмне забезпечення:',
  opportunities_66: 'Власні індивідуальні ІТ-розробки',
  opportunities_67: 'DigSee Sure для CAPI',
  opportunities_68: 'SurveyMonkey для CAWI',
  opportunities_69: 'Shopmetrics',
  opportunities_70: 'VoIPTime (кол-центр)',
  opportunities_71: 'Маркетингова підтримка:',
  opportunities_72: 'PR-підтримка',
  opportunities_73: 'Підтримка в просуванні та створенні контенту',
  opportunities_74: 'Налаштування процесів генерації лідів',
  opportunities_75: 'ІТ-підтримка:',
  opportunities_76:
    'Технічна підтримка при встановленні, налаштуванні та використанні нашого програмного забезпечення.',
  opportunities_77: 'Віддалена підтримка',
  opportunities_78: 'Контроль безпеки',
  opportunities_79: 'Операційна підтримка:',
  opportunities_80: 'Підтримка в налагодженні польових процесів',
  opportunities_81: 'Система протидії шахрайству',
  opportunities_82: 'Рекрутинг будь-якої складності',
  opportunities_83:
    'Оперативне управління командою проєкту та польових підрозділів',
  opportunities_84: 'Контроль якості рекрутингу',
  opportunities_85: 'UpWork',
  opportunities_86: 'Які бонуси ви отримаєте?',
  opportunities_87: 'Розширення клієнтської бази',
  opportunities_88: 'Зміцнення іміджу на ринку',
  opportunities_89: 'Диверсифікація ризиків втрати клієнтів',
  opportunities_90: 'Оптимізація технологічних процесів',
  opportunities_91: 'Міжнародний досвід',
  opportunities_92: 'Більше продажів поточним клієнтам',
  main_67:
    'Аналіз конкурентних цін оптимізує пропозиції, підвищує прибуток і збільшує частку ринку, використовуючи дані про готовність споживачів платити, щоб визначити ключові характеристики',
  main_68:
    'Забезпечує відповідність ваших торгових точок стандартам бренду, оцінюючи фізичні атрибути та їхній вплив на сприйняття клієнтами',
  main_69: 'Рішення CX',
  main_70:
    'Відкрийте для себе наше унікальне 360-градусне CX-рішення: ми налаштовуємо інструменти та послуги відповідно до ваших цілей для розвитку вашого бізнесу',
  main_71: 'Мовна аналітика',
  main_72:
    'Наш вдосконалений інструмент аналізу мовлення перетворює розмовну мову на текст, надаючи компаніям безцінну інформацію для покращення взаємодії з клієнтами, моніторингу продуктивності, забезпечення дотримання нормативних вимог та стимулювання зростання',
  brand_awareness_1: 'Впізнаваність бренду',
  brand_awareness_2: 'та використання',
  brand_awareness_3:
    'Розкрийте потенціал впізнаваності та використання вашого бренду для досягнення успіху в бізнесі',
  brand_awareness_4: 'Поговоріть з експертом',
  brand_awareness_5: 'Про',
  brand_awareness_6:
    '<b>Оцініть ступінь</b> впізнаваність і знайомство з вашим брендом',
  brand_awareness_7:
    '<b>Визначте рівень бренду </b> обізнаність на вашому цільовому ринку',
  brand_awareness_8:
    '<b>Слідкуйте уважно</b> про показники утримання та лояльності',
  brand_awareness_9:
    '<b>Виберіть оптимальну стратегію</b> для покращення необхідних показників',
  brand_awareness_10:
    'Відкрийте для себе численні можливості з нашими рішеннями',
  brand_awareness_11: 'Впізнаваність бренду',
  brand_awareness_12: 'Використання бренду',
  brand_awareness_13:
    '<b>Відкрийте для себе</b> впізнаваність вашого бренду серед споживчих сегментів',
  brand_awareness_14: '<b>Відстежуйте</b> рівень обізнаності вашої аудиторії',
  brand_awareness_15:
    '<b>Вивчайте</b> понтанні думки споживачів, які збираються зробити покупку',
  brand_awareness_16:
    '<b>Зрозумійте</b> основні джерела, що сприяють підвищенню впізнаваності вашого бренду',
  brand_awareness_17:
    '<b>Враховуйте</b> кількість споживачів, які стикалися з продукцією або послугами вашого бренду',
  brand_awareness_18:
    '<b>Визначте</b> споживачів, які залишилися з вашим брендом після першої спроби',
  brand_awareness_19:
    '<b>Розблокуйте</b> ймовірність того, що ваш бренд буде рекомендований',
  brand_awareness_20:
    '<b>Розкрийте</b> відсоток споживачів, які розглядають ваш бренд для подальшого використання',
  brand_awareness_21:
    'Хто отримує вигоду від впізнаваності та використання бренду?',
  brand_awareness_22: 'Підприємства, що виводять на ринок новий продукт',
  brand_awareness_23: 'Підприємства, що оновлюють вже існуючий бренд',
  brand_awareness_24:
    'Будь-який бізнес, який прагне зрозуміти та покращити те, як його бренд впізнають та використовують споживачі',
  brand_awareness_25:
    'Наші методології надають цінну інформацію для оптимізації впізнаваності та використання вашого бренду',
  brand_awareness_26:
    'Методологія підбирається індивідуально, виходячи із запиту клієнта',
  brand_awareness_27: 'Методологія воронки використання бренду',
  brand_awareness_28:
    'Коефіцієнт проб показує частку аудиторії, яка знає про бренд і спробувала його продукти або послуги.',
  brand_awareness_29:
    'Коефіцієнт повторних звернень показує відсоток аудиторії, яка знайома з брендом і спробувала його продукти або послуги, та продовжила здійснювати покупки або користуватися пропозиціями протягом останніх 12 місяців.',
  brand_awareness_30:
    'Коефіцієнт утримання показує частку осіб, які постійно користувалися продуктами або послугами бренду, вказуючи на відсоток тих, хто користувався цими пропозиціями протягом останніх 6 місяців.',
  brand_awareness_31:
    'Коефіцієнт утримання ілюструє відсоток клієнтів, які, скориставшись продуктами/послугами бренду протягом останніх 6 місяців, продовжують обирати і демонструвати лояльність до бренду протягом тривалого часу.',
  brand_awareness_32: 'Методологія впізнаваності бренду',
  brand_awareness_33:
    'Top of mind (TOM) - це перший бренд, який спонтанно згадав респондент.',
  brand_awareness_34:
    'Загальна спонтанна поінформованість - це загальна кількість спонтанних згадок, включаючи ТОМ.',
  brand_awareness_35:
    'Допоміжна обізнаність показує, наскільки добре користувачі впізнають ваш бренд, коли отримують відповідні підказки.',
  brand_awareness_36: 'Brand attribute performance map',
  brand_awareness_37:
    'Цей інструмент оцінює та візуально представляє важливість різних атрибутів бренду, надаючи уявлення про те, як кожен з них впливає на загальну ефективність вашого бренду та його сприйняття споживачами.',
  brand_awareness_38:
    'Розкрийте ключі до посилення впливу вашого бренду за допомогою нашого комплексного аналізу.',
  brand_awareness_39: 'Карта сприйняття брендів',
  brand_awareness_40:
    'Цей інструмент пропонує візуальне представлення споживчого сприйняття в умовах конкурентного ринкового ландшафту. Цей підхід має вирішальне значення для чіткого окреслення відмінностей між брендами, підкреслюючи ключові ринкові атрибути.',
  brand_awareness_41:
    "Він спрощує спостереження та інтерпретацію взаємозв'язків між брендами та цими визначальними характеристиками.",
  brand_awareness_42:
    'Близькість конкретного бренду до певних атрибутів означає його сильну асоціацію з цими характеристиками. Аналогічно, близькість між двома брендами вказує на їхню схожість, демонструючи спільні образи і, отже, приналежність до одного сегменту ринку.',
  consumer_journey_map_1: 'Карта подорожі споживача',
  consumer_journey_map_2:
    "Виявляйте та долайте бар'єри у вашому бізнесі за допомогою нашої Карти подорожі споживача",
  consumer_journey_map_3: 'ДОВІРЯЮТЬ',
  consumer_journey_map_4: '4Service',
  consumer_journey_map_5:
    'Розкрийте потенціал CJM для оптимізації взаємодії між вашими споживачами та брендом',
  consumer_journey_map_6:
    'Визначте, які кроки робить споживач, перш ніж купити товар',
  consumer_journey_map_7:
    'Отримуйте інсайти від реальних, а не уявних клієнтів',
  consumer_journey_map_8: 'Отримайте повне розуміння досвіду ваших клієнтів',
  consumer_journey_map_9:
    'Визначте втрачені можливості та порівняйте з вашими конкурентами',
  consumer_journey_map_10: 'Відкрийте для себе наш підхід CJM',
  consumer_journey_map_11:
    'Ми створюємо шлях взаємодії споживача з продуктом, послугою, компанією чи брендом через різні канали та протягом певного періоду часу.',
  consumer_journey_map_12:
    'Ми описуємо етапи, які проходять ваші споживачі, перш ніж здійснити покупку, очікування, емоції та мотивації клієнтів на кожному з них.',
  consumer_journey_map_13: 'Отримуйте вигоду разом з нами',
  consumer_journey_map_14:
    'Ми надаємо команду досвідчених професіоналів, які розуміють тонкощі успіху глобального бізнесу',
  consumer_journey_map_15:
    'Ми прагнемо досягти результатів, які перевершують ваші очікування',
  consumer_journey_map_16:
    'Ми знаходимо комплексні рішення, які сприяють вашому зростанню',
  consumer_journey_map_17:
    'Ми спеціалізуємося на наданні індивідуальних рішень CJM',
  consumer_journey_map_18: 'Замовити консультацію',
  consumer_journey_map_19: 'Рішення на основі даних',
  consumer_journey_map_20:
    'Базуйте свої рішення на реальних даних, а не на припущеннях',
  consumer_journey_map_21: 'Оптимізуйте свій CJM',
  consumer_journey_map_22:
    'Побудуйте міцніші стосунки з клієнтами, задовольняючи їхні потреби та очікування на кожному етапі їхньої подорожі',
  consumer_journey_map_23: 'Ведіть за собою',
  consumer_journey_map_24:
    'Порівнюйте себе з конкурентами та випереджайте ринок',
  consumer_journey_map_25: 'Рекомендації',
  consumer_journey_map_26:
    'Впроваджуйте наші рекомендації CJM, щоб досягти успіху, підвищити лояльність клієнтів, збільшити продажі та максимізувати свій бізнес-потенціал',
  consumer_journey_map_27:
    'Відкрийте для себе справжній шлях споживача за допомогою нашої карти подорожі споживача',
  consumer_journey_map_28:
    'Експерти з більш ніж 20-річним досвідом роботи проводять комплексне дослідження',
  consumer_journey_map_29:
    'Якісне дослідження передбачає збір та аналіз нечислових даних для розуміння концепцій, думок чи досвіду.',
  consumer_journey_map_30:
    "Кількісні методи дослідження передбачають об'єктивне вимірювання та числовий аналіз даних, отриманих за допомогою опитувань, анкетування та досліджень.",
  consumer_journey_map_31:
    'Після збору даних експерти аналізують інформацію та роблять висновки, щоб надати вам найкращі рекомендації.',
  boarding_1: 'Карта подорожі клієнта',
  boarding_2:
    'Customer Journey Mapping (CJM) - це визначення цільової аудиторії, розуміння її потреб і середовища, а потім створення плану для забезпечення цікавого досвіду',
  boarding_3: 'Чистий показник просування',
  boarding_4:
    'Індекс лояльності клієнтів (NPS) - це золотий стандарт оцінки клієнтського досвіду, що базується на одному питанні: Наскільки ймовірно, що ви порекомендуєте організацію/продукт/послугу?',
  boarding_5: "Відстеження здоров'я бренду",
  boarding_6:
    'Дослідження Brand Health Tracking (BHT) може виміряти, як ваш бренд працює з точки зору впізнаваності та використання, позиціонування та ефективності бренду',
  boarding_7: 'Дослідження кадрового потенціалу',
  boarding_8:
    'Дослідження кадрового потенціалу використовується для оцінки практики та ефективності роботи з персоналом і пропонує детальний аналіз поточного розвитку та управління',
  boarding_9: 'UX-тестування',
  boarding_10:
    'Тестування зручності викопистання (UX) - це спостереження за тим, як реальні люди взаємодіють з веб-сайтом, додатком чи іншим продуктом, а також за їхньою поведінкою та реакцією на нього',
  boarding_11: 'Індекс задоволеності клієнтів',
  boarding_12:
    'Задоволеність клієнтів вимірює ступінь задоволення очікувань клієнтів щодо придбання продукту або послуги, наданих вашою компанією',
  boarding_13: 'Тестування реклами',
  boarding_14:
    'Рекламна кампанія - це величезна інвестиція. Переконайтеся, що ваша реклама є потужною та забезпечує максимальну рентабельність інвестицій',
  boarding_15: 'Польовий аудит/Роздрібний аудит',
  boarding_16:
    "Збирайте детальні дані про ваш магазин або бренд, аналізуйте бар'єри зростання та створюйте ефективні стратегії для їх подолання",
  boarding_17: 'Play4Sales',
  boarding_18:
    'Програмне забезпечення для управління ефективністю. Гейміфікований додаток для збільшення продажів',
  boarding_19: 'Voicer',
  boarding_20:
    "Система управління зворотним зв'язком. Голос вашого клієнта в одній системі. Система управління зворотним зв'язком з клієнтами",
  boarding_21: 'Якісні дослідження',
  boarding_22:
    'Якісне дослідження - це збір та аналіз нечислових даних для розуміння концепцій, думок або досвіду',
  boarding_23: 'Кількісні дослідження',
  boarding_24:
    "Кількісні методи дослідження - це об'єктивне вимірювання та числовий аналіз даних, зібраних за допомогою опитувань, анкетування та досліджень",
  boarding_25: 'Таємний покупець',
  boarding_26:
    'Таємний покупець - це метод маркетингового дослідження для вимірювання та моніторингу взаємодії між клієнтом та компанією або організацією за заздалегідь визначеним сценарієм',
  boarding_27: 'Wantent',
  boarding_28:
    'Платформа для аналізу контенту на основі штучного інтелекту, що надає інсайти від аудиторії',
  boarding_29: 'Моніторинг цін ',
  boarding_30:
    'Ціновий моніторинг - це процес збору, обробки та аналізу цін конкурентів',
  boarding_31: 'Сервіс-дизайн',
  boarding_32:
    'Сервіс-дизайн - це спосіб зрозуміти потреби та бажання людей, які будуть користуватися продуктом або послугою, проводячи з ними час',
  boarding_33: 'Аналіз цін у автомобільній промисловості',
  boarding_34:
    'Аналіз конкурентних цін оптимізує пропозиції, підвищує прибуток і збільшує частку ринку, використовуючи дані про готовність споживачів платити, щоб визначити ключові характеристики',
  boarding_35: "Контрольний аудит об'єкта",
  boarding_36:
    'Забезпечує відповідність ваших торгових точок стандартам бренду, оцінюючи фізичні атрибути та їхній вплив на сприйняття клієнтами',
  boarding_37: 'Рішення CX',
  boarding_38:
    'Відкрийте для себе наше унікальне 360-градусне CX-рішення: ми налаштовуємо інструменти та послуги відповідно до ваших цілей для розвитку вашого бізнесу',
  boarding_39: 'Мовна аналітика',
  boarding_40:
    'Наш вдосконалений інструмент аналізу мовлення перетворює розмовну мову на текст, надаючи компаніям безцінну інформацію для покращення взаємодії з клієнтами, моніторингу продуктивності, забезпечення дотримання нормативних вимог та стимулювання зростання',
  boarding_41: 'Використання та ставлення',
  boarding_42:
    'Використання та ставлення - це наш підхід до розуміння того, як аудиторія взаємодіє з вашим брендом, шляхом аналізу практичних моделей використання та психологічного ставлення, що дозволяє розробляти індивідуальні маркетингові та продуктові стратегії',
  boarding_43: 'Впізнаваність та використання бренду',
  boarding_44:
    'Впізнаваність бренду та його використання передбачає оцінку впізнаваності, знайомства, частоти взаємодії та утримання на вашому цільовому ринку для підвищення видимості та залученості бренду',
  boarding_45: 'Ідентифікація та позиціонування бренду',
  boarding_46:
    'Ідентифікація та позиціонування бренду - це комплексний аналіз, що розкриває споживче сприйняття, різноманітні погляди аудиторії та розуміння того, як сприймається ваш бренд',
  boarding_47: 'Профілювання та сегментація клієнтів',
  boarding_48:
    'Профілювання та сегментація клієнтів передбачає поділ вашого цільового ринку на окремі групи на основі спільних характеристик, що дозволяє створювати персоналізовані стратегії, які резонують з конкретними сегментами аудиторії',
  boarding_49: 'Карта подорожі споживача',
  boarding_50:
    'Карта подорожі споживача - це візуальне представлення етапів, точок дотику, емоцій та мотивацій, з якими стикається споживач під час взаємодії з вашим продуктом або брендом',
  boarding_51:
    'Дізнайтеся про інноваційні методи, які лежать в основі нашого прагнення надавати дієві ідеї та досягати вражаючих результатів для вашого бізнесу',
  boarding_52:
    'Ознайомтеся з нашим набором продуктів з великою кількістю аналітики на основі даних, адаптованої для вашої галузі, що дозволить вам приймати обґрунтовані рішення та залишатися на крок попереду',
  boarding_53:
    'Пориньте вглиб ринкових тенденцій, споживчої поведінки та конкурентного середовища за допомогою наших комплексних рішень для маркетингових досліджень',
  boarding_54: 'Спеціальні дослідження',
  boarding_55:
    'Спеціальне дослідження - це індивідуальний підхід, розроблений для вирішення конкретної проблеми, з використанням поєднання існуючих методологій або нового підходу для створення індивідуального рішення.',
  boarding_56: 'Соціологічні дослідження',
  boarding_57:
    "Соціологічні дослідження розкривають закономірності людської поведінки та соціальної взаємодії, надаючи цінну інформацію для розробки інноваційних стратегій та створення значущих зв'язків з цільовими аудиторіями.",
  not_found_1: 'Упс! Ми не можемо знайти цю сторінку.',
  not_found_2: 'Чому б вам не ознайомитися з нашою продукцією?',
  not_found_3: 'Консультація з питань замовлення',
  customer_profiling_segmentation_1: 'Профілювання та сегментація клієнтів',
  customer_profiling_segmentation_2:
    'Ми допоможемо вам визначити вашу цільову аудиторію, зробивши вашу стратегію більш ефективною',
  customer_profiling_segmentation_3:
    'Використовуйте можливості сегментації ринку, щоб гарантувати, що ваші повідомлення завжди ефективно досягають цільових груп споживачів',
  customer_profiling_segmentation_4: 'Глибше розумійте споживачів',
  customer_profiling_segmentation_5:
    'Знайдіть найкращу цільову аудиторію для вашого продукту',
  customer_profiling_segmentation_6: 'Досліджуйте нові сегменти для залучення',
  customer_profiling_segmentation_7: "Відкрийте для себе бар'єри для купівлі",
  customer_profiling_segmentation_8:
    'Ми спеціалізуємося на наданні індивідуальних рішень для сегментації',
  customer_profiling_segmentation_9: 'Унікальний підхід',
  customer_profiling_segmentation_10:
    'Спробуйте унікальне, індивідуальне рішення для сегментації, створене спеціально для ваших потреб',
  customer_profiling_segmentation_11: 'Цільові персони',
  customer_profiling_segmentation_12:
    'Створення цільових персон на основі глибокого аналізу ринку та всебічних досліджень',
  customer_profiling_segmentation_13: 'Нові сегменти',
  customer_profiling_segmentation_14:
    'Визначте найцінніші сегменти аудиторії та скористайтеся перевагами персоналізованого таргетингу',
  customer_profiling_segmentation_15: 'Рекомендації',
  customer_profiling_segmentation_16:
    'Впроваджуйте наші рекомендації та досягайте успіху, підвищуйте лояльність клієнтів, збільшуйте продажі та максимізуйте свій бізнес-потенціал',
  customer_profiling_segmentation_17:
    'Експерти з більш ніж 20-річним досвідом роботи проводять комплексне дослідження',
  customer_profiling_segmentation_18:
    'Якісне дослідження передбачає збір та аналіз нечислових даних для розуміння концепцій, думок чи досвіду.',
  customer_profiling_segmentation_19:
    "Кількісні методи дослідження передбачають об'єктивне вимірювання та числовий аналіз даних, отриманих за допомогою опитувань, анкетування та досліджень.",
  customer_profiling_segmentation_20:
    'Після збору даних експерти аналізують інформацію та роблять висновки, щоб надати вам найкращі рекомендації.',
  customer_profiling_segmentation_21:
    'Ми не просто проводимо вимірювання, ми також надаємо вам план дій з чіткими покроковими інструкціями',
  customer_profiling_segmentation_22: 'Наша методологія',
  customer_profiling_segmentation_23: 'Індекс Жаккара',
  customer_profiling_segmentation_24:
    "При прямому опитуванні респондентів їхні відповіді про переваги компанії можуть не збігатися з їхньою реальною поведінкою. Замість прямого опитування ми використовуємо статистичний аналіз Жаккара, щоб зрозуміти взаємозв'язок між атрибутами компанії та споживчим вибором.",
  customer_profiling_segmentation_25: 'Драйвери',
  customer_profiling_segmentation_26:
    'Ми визначаємо драйвери (фактори, що впливають на певний результат) і класифікуємо їх на ключові, середні та несуттєві, виходячи з їхньої значущості.',
  customer_profiling_segmentation_27: 'Сегментація',
  customer_profiling_segmentation_28:
    'Ви отримуєте сегментацію з чіткими характеристиками вибору клієнтів.',
  customer_profiling_segmentation_29: 'Наш підхід до сегментації',
  customer_profiling_segmentation_30: 'Демографія',
  customer_profiling_segmentation_31: 'вік',
  customer_profiling_segmentation_32: 'стать',
  customer_profiling_segmentation_33: 'дохід',
  customer_profiling_segmentation_34:
    'Це допомагає адаптувати продукти, послуги та маркетинг до конкретних груп, підвищуючи релевантність та ефективність',
  customer_profiling_segmentation_35: 'Психографічний',
  customer_profiling_segmentation_36: 'стиль життя',
  customer_profiling_segmentation_37: 'цінності',
  customer_profiling_segmentation_38: 'поведінка',
  customer_profiling_segmentation_39:
    "Це забезпечує персоналізований маркетинг, глибші зв'язки з клієнтами, а також підвищує лояльність і продажі компанії",
  customer_profiling_segmentation_40: 'Поведінкові',
  customer_profiling_segmentation_41: 'дії',
  customer_profiling_segmentation_42: 'мотивація',
  customer_profiling_segmentation_43: 'частота покупок',
  customer_profiling_segmentation_44:
    'Це дозволяє здійснювати цілеспрямований маркетинг, підвищити рівень задоволеності клієнтів, збільшити утримання клієнтів і підвищити прибутковість компанії',
  customer_profiling_segmentation_45: 'Географія',
  customer_profiling_segmentation_46: 'місцезнаходження',
  customer_profiling_segmentation_47:
    ' Це забезпечує цільовий маркетинг, адаптовані продукти, ефективну дистрибуцію та збільшення проникнення на ринок для компанії.',
  brands_identity_1: 'Ідентифікація та позиціонування бренду',
  brands_identity_2:
    'Трансформуйте свій бренд за допомогою нашого поглибленого аналізу, розкриваючи уявлення про споживче сприйняття та вдосконалюючи свою стратегію для досягнення успіху',
  brands_identity_3: 'Поговоріть з експертом',
  brands_identity_4:
    '<b>Відкрийте для себе всеосяжний</b> аналіз ідентичності бренду',
  brands_identity_5:
    '<b>Дослідити інсайти</b> зібрані на основі споживчого сприйняття',
  brands_identity_6:
    '<b>Розуміння різноманітних</b> переглядів цільової аудиторії',
  brands_identity_7:
    '<b>Отримайте уявлення про</b> поведінку, звички та вподобання споживачів для прийняття обґрунтованих рішень',
  brands_identity_8:
    'Експерти 4Service з більш ніж 20-річним досвідом роботи з клієнтами створюють унікальні рішення, які допомагають розвивати ваш бізнес',
  brands_identity_9:
    '<b>Ви отримаєте доступ до дослідницького пакету з усіма необхідними даними.</b> Вони слугують діагностичним інструментом, висвітлюючи сильні сторони та сфери, які можуть потребувати уваги',
  brands_identity_10:
    '<b>Наші послуги надають бізнесу необхідні інструменти</b> щоб приймати обґрунтовані рішення, вдосконалювати стратегії та досягати успіху у своїх галузях.',
  brands_identity_11: 'Посилення позицій вашого бренду на конкурентному ринку',
  brands_identity_12:
    'Підприємства, які прагнуть розширити свою присутність на ринку',
  brands_identity_13: 'Компанії, які прагнуть вдосконалити свою стратегію',
  brands_identity_14: 'Ідентифікація бренду',
  brands_identity_15: 'Позиціонування бренду',
  brands_identity_16:
    '<b>Розкрийте сприйняття вашого бренду</b> в різних споживчих сегментах.',
  brands_identity_17:
    '<b>Визначте якості, які приписують</b> вашому бренду та конкурентам.',
  brands_identity_18:
    '<b>Оцініть сильні</b> та слабкі сторони для стратегічного вдосконалення.',
  brands_identity_19:
    '<b>Оцініть відповідність </b> іміджу вашого бренду цінностям, що просуваються.',
  brands_identity_20: '<b>Оцініть сприйняту </b> надійність вашого бренду.',
  brands_identity_21:
    '<b>Дослідіть архетипне</b> та символічне сприйняття вашого бренду.',
  brands_identity_22:
    '<b>Визначте для свого бренду</b> унікальні торгові пропозиції та диференціатори порівняно з конкурентами.',
  brands_identity_23:
    '<b>Визначте ключові атрибути, що впливають на</b> ймовірність того, що споживач придбає ваші товари/послуги.',
  brands_identity_24:
    '<b>Визначте, як ваш бренд наразі позиціонується у свідомості споживачів, та визначте аспекти комунікації для залучення бажаних сегментів ринку.',
  brands_identity_25:
    'Методологія підбирається індивідуально, виходячи із запиту клієнта',
  brands_identity_26: 'Поінформованість про бренд та його використання',
  brands_identity_27:
    'Оцінка рівня бренду передбачає вимірювання рівня обізнаності та споживання, що слугує фундаментальним компонентом в оцінці.',
  brands_identity_28: 'Просування бренду та довіра до нього',
  brands_identity_29:
    'Цей показник вимірює прихильність клієнтів (рекомендації) та послідовну здатність бренду виконувати обіцянки, створювати позитивний клієнтський досвід та будувати довіру.',
  brands_identity_30: 'Ефективність іміджу бренду',
  brands_identity_31:
    "Оцінка ефективності бренду включає оцінку його зв'язку з шістьма ключовими цінностями (3 раціональними та 3 емоційними), його відмінності в межах категорії та здатності викликати гордість у споживачів.",
  brands_identity_32: "Показник здоров'я бренду",
  brands_identity_33:
    "Індекс здоров'я бренду дає вам повне уявлення про те, як ваш бренд працює в конкурентному середовищі.",
  sociological_research_1: 'Соціологічні дослідження',
  sociological_research_2:
    'Ми проводимо глибокий аналіз суспільних явищ і процесів, який допомагає ухвалювати усвідомлені рішення за допомогою унікальних аналітичних висновків та рекомендацій',
  sociological_research_3: 'Зв`язатись з нами',
  sociological_research_4: 'Наші клієнти',
  sociological_research_5: 'CAWI',
  sociological_research_6:
    'CATI - це метод збору даних, в якому використовується комп`ютер для проведення опитувань по телефону. Програмне забезпечення CATI дозволяє автоматизувати процеси збору даних, введення відповідей та керування опитуванням. Використання CATI полегшує контроль над опитуванням, дозволяє швидко аналізувати та обробляти отримані дані, а також забезпечує високий рівень стандартизації опитування.',
  sociological_research_7: 'СATI',
  sociological_research_8:
    'CAWI - це метод збору даних, в якому респонденти заповнюють опитувальні анкети або відповідають на питання через веб-інтерфейс, а дані автоматично збираються і обробляються за допомогою спеціалізованого програмного забезпечення. Метод CAWI дозволяє проводити опитування великих масштабів, швидко збирати та аналізувати дані.',
  sociological_research_9: 'CAPI',
  sociological_research_10:
    'CAPI - це метод збору даних для опитування обличчям до обличчя, у якомуі дослідник використовує планшет або мобільний телефон для введення відповідей респондентів на питання, що дозволяє автоматизувати збір даних, знижуючи помилки введення та спрощуючи аналіз результатів.',
  sociological_research_11:
    'Наші експерти використовують різноманітні інструменти',
  sociological_research_12: 'Вибірка',
  sociological_research_13:
    'Ми розраховуємо та застосовуємо вибірки будь-якої складності',
  sociological_research_14:
    'В роботі ми використовуємо прості, систематичні, стратифіковані та кластерні вибірки',
  sociological_research_15:
    'Для національних репрезентативних досліджень face-to-face ми застосовуємо процедури стратифікованого багатоступеневого відбору',
  sociological_research_16:
    'Відбір населених пунктів по регіонах здійснюється відповідно до розміру та типу населеного пункту, їх кількість визначається пропорційно їх обсягу в розрізі кожної області',
  sociological_research_17: 'Ми проводимо',
  sociological_research_18: 'Дослідження громадської думки',
  sociological_research_19: 'Соціально-економічні дослідження',
  sociological_research_20: 'Кабінетне дослідження',
  sociological_research_21: 'Оцінка якості соціальних послуг',
  sociological_research_22: 'Вивчення окремого випадку',
  sociological_research_23: 'Дослідження персоналу',
  sociological_research_24:
    'Дослідження специфічних аудиторій (уразливі групи, в т.ч. ВПО, військові, молодь, тощо)',
  sociological_research_25: 'Ми працюємо з кожним клієнтом індивідуально',
  sociological_research_26: 'Наші клієнти:',
  sociological_research_27: 'Благодійні Фонди',
  sociological_research_28: 'Громадські організації та спілки',
  sociological_research_29: 'Державні структури',
  sociological_research_30: 'Наш підхід до роботи',
  sociological_research_31:
    'Ми використовуємо науковий підхід у роботі та глибинне розуміння поточної реальності, а також різноманітні методи дослідження для збору даних.',
  sociological_research_32:
    'Ми залучаємо вузькопрофільних експертів з потрібним досвідом для вирішення конкретного запиту та маємо пул з експертами з різних напрямків.',
  sociological_research_33:
    'Наш власний аналітичний відділ аналізує зібрані дані, використовуючи унікальний набір інструментів залежно від специфіки завдання.',
  sociological_research_34:
    'Ми генеруємо звіти з детальними висновками та рекомендаціями, враховуючи потреби та очікування вашої цільової аудиторії.',
  sociological_research_35:
    'Ми надаємо консультації та підтримку під час впровадження рекомендацій, допомагаючи вам здійснити позитивні зміни.',
  sociological_research_36: 'Наші дослідження на ТВ',
  sociological_research_37: 'Ознайомтесь із нашими звітами',
  sociological_research_38:
    'Наш науковий підхід у роботі гарантує нашим замовникам точні та достовірні соціологічні дані по всій Україні та Європі з мінімальною похибкою',
  sociological_research_39:
    'Ми працюємо з дотриманням усіх принципів і стандартів етики та правил проведення соціологічних досліджень.',
  sociological_research_40:
    'Ми дотримуємося кодексу професійної етики соціолога Соціологічної Асоціації України та Гельсінської декларації етичних принципів для проведення досліджень. Члени ESOMAR, Української Асоціації Маркетингу, Всеукраїнської Асоціації Контакт-Центрів України.',
  sociological_research_41: 'Поле',
  sociological_research_42: 'онлайн-панель',
  sociological_research_43: 'постійних інтерв`юерів',
  sociological_research_44: 'супервайзерів',
  sociological_research_45: 'Контроль якості поля',
  sociological_research_46: '100% фіксація GPS координатів (CAPI)',
  sociological_research_47: 'Валідація анкет (CAWI, CAPI, CATI, PAPI)',
  sociological_research_48: '100% запису інтерв’ю (CAPI, CATI)',
  sociological_research_49:
    'Ми використовуємо ліцензоване програмне забезпечення:',
  sociological_research_50:
    'SPSS  для аналізу статистичних даних, обробка масивів даних',
  sociological_research_51:
    'DigSee Sure для CAPI: логіка анкети, геолокація, аудіозапис інтерв’ю, демонстрація відео-матеріалів',
  sociological_research_52:
    'SurveyMonkey для CAWI: логіка анкети, зручний формат, візуалізація',
  sociological_research_53:
    'VoIPTime (контакт-центр): програмування анкет будь-якої складності, передача аудіозаписи замовнику',
  sociological_research_54: 'Вибірка',
  sociological_research_55:
    'Ми розраховуємо та застосовуємо вибірки будь-якої складності',
  sociological_research_56:
    'В роботі ми використовуємо прості, систематичні, стратифіковані та кластерні вибірки',
  sociological_research_57:
    'Для національних репрезентативних досліджень face-to-face ми застосовуємо процедури стратифікованого багатоступеневого відбору',
  sociological_research_58:
    'Відбір населених пунктів по регіонах здійснюється відповідно до розміру та типу населеного пункту, їх кількість визначається пропорційно їх обсягу в розрізі кожної області',
  sociological_research_59: 'Команда',
  sociological_research_60:
    'Наші соціологи входять до Асоціації Соціологів України',
  sociological_research_61:
    'Ми маємо 12 штатних аналітиків з досвідом виконання як кількісних, так і якісних проєктів в різних галузях.',
  sociological_research_62:
    'Наша потужна аналітична команда фахових спеціалістів із досвідом дослідницької роботи більше 10 років. Ми маємо у штаті науковців, кандидатів наук, MBI.',
  sociological_research_63: 'Ірина Максим`юк',
  sociological_research_64:
    'Керівниця Департаменту досліджень. Понад 15 років досвіду в маркетингових дослідженнях, управління дослідженнями на всіх стадіях, підготовку аналітичних звітів, проведення презентацій',
  sociological_research_65: 'Андрій Білоскурський',
  sociological_research_66:
    'Керівник відділу програмінгу та обробки даних. 20 років досвіду в маркетинговых, соціологічних та маретингови дослідженнях',
  sociological_research_67: 'Олена Сомова',
  sociological_research_68:
    'Спеціаліст з програмінгу Аспірант з соціології в ДонДУУ. 5 роки роботи в маркетингових дослідженнях Членкиня правління Демократичного Альянсу Жінок, керівниця відділу фандрайзингу.',
  sociological_research_69: 'Ганна Падалка',
  sociological_research_70:
    'Керівниця відділу соціологічних досліджень департаменту досліджень, має 13 річний досвід роботи з соціологічними та соціальними проєктами міжнародного та національного рівня.  Кандидат соціологічних наук,  Керівник Дослідницького Комітету Всеукраїнської асоціації контакт-центрів України.',
  ad_hoc_1: 'Спеціально',
  ad_hoc_2:
    'Відкрийте для себе наші альтернативні дослідження для унікальних випадків',
  ad_hoc_3: 'Звяжіться з нами',
  ad_hoc_4: 'Наш підхід',
  ad_hoc_5:
    'Наша команда експертів ретельно аналізує зібрані дані, пропонуючи вам комплексні рішення, які сприяють вашому зростанню.',
  ad_hoc_6:
    "Ми розробляємо унікальне дослідження, адаптоване до ваших конкретних вимог, спираючись на широкий спектр методологій, включаючи опитування, інтерв'ю, фокус-групи, аналіз даних і багато іншого.",
  ad_hoc_7: 'Чому ми обираємо спеціальні дослідження?',
  ad_hoc_8: 'Гнучкість',
  ad_hoc_9:
    'Немає двох однакових дослідницьких проєктів. Завдяки Ad Hoc Research ми можемо гнучко адаптувати та розвивати унікальний підхід за потреби, гарантуючи, що ваші ідеї залишатимуться актуальними та дієвими.',
  ad_hoc_10: 'Точність',
  ad_hoc_11:
    'Пристосовуючи наші методології до ваших унікальних вимог, ми отримуємо точні та цілеспрямовані результати, які надають цінну інформацію.',
  ad_hoc_12: 'Інновації',
  ad_hoc_13:
    'Ми застосовуємо інновації та креативність у нашому дослідницькому процесі, досліджуючи нові методи та підходи, щоб виявити приховані можливості та виклики.',
  ad_hoc_14: 'Своєчасність',
  ad_hoc_15:
    'Спеціальні дослідження дозволяють швидко впроваджувати рішення, надаючи вам доступ до критично важливої інформації вчасно, що дає змогу випереджати конкурентів у вашій галузі.',
  ad_hoc_16: 'Наш досвід',
  ad_hoc_17:
    'Експерти 4Service з більш ніж 20-річним досвідом дослідження ринку та роботи з клієнтами створюють унікальні рішення, які допомагають розвивати ваш бізнес',
  ad_hoc_18:
    'Ми використовуємо певний набір інструментів, заснований на ваших конкретних вимогах',
  ad_hoc_19: 'Жодна методологія не працює для вас?',
  ad_hoc_20: 'Замовити спеціальне дослідження',
  ad_hoc_21: 'Підлаштовано під конкретну проблему',
  ad_hoc_22:
    'Ми використовуємо як поєднання різних частин методологій, так і абсолютно новий підхід',
  ad_hoc_23: 'Індивідуальний підхід',
  ad_hoc_24: 'Ми ретельно адаптуємо рішення безпосередньо до вашої проблеми',
  ad_hoc_25: 'Вийти за рамки',
  ad_hoc_26:
    'Ми надаємо план дій з рекомендаціями, які допоможуть вашому бізнесу досягти поставлених цілей',
  ad_hoc_27: 'У вас нестандартна проблема',
  ad_hoc_28: 'Жодна з методологій не підходить для вашої проблеми',
  ad_hoc_29: 'Ви хотіли б запросити різні методології або їх частини одночасно',
  ad_hoc_30: 'Хто отримує користь від спеціальних досліджень?',
};
